import React from 'react';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import {
  ColorValue,
  StyleProp,
  TouchableOpacity,
  ViewStyle,
  Text,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useTheme } from 'styled-components/native';

import { BadgeContainer, BadgeCountText } from './index.style';

interface IProps {
  containerStyle?: StyleProp<ViewStyle>;
  icon?: {
    name: string;
    size?: number;
    color?: ColorValue | number;
  };
  onPress?: () => void;
  showBadge?: boolean;
  badgeCount?: number;
}

const NavHeaderButton: React.FC<IProps> = ({
  containerStyle,
  icon,
  onPress,
  showBadge,
  badgeCount = 0,
}) => {
  const navigation = useNavigation();
  const theme = useTheme();

  return (
    <TouchableOpacity
      onPress={() =>
        onPress ? onPress() : navigation.dispatch(DrawerActions.openDrawer())
      }
      style={containerStyle}>
      <Icon
        name={icon?.name || 'menu'}
        size={icon?.size || 25}
        color={icon?.color || theme.color.text}
      />
      {showBadge && (
        <BadgeContainer>
          <BadgeCountText>{badgeCount}</BadgeCountText>
        </BadgeContainer>
      )}
    </TouchableOpacity>
  );
};

export default NavHeaderButton;

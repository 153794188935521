import {
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
  isSaturday,
  isSunday,
  parseISO,
} from 'date-fns';

export const formatDate = (date?: Date, dateFormat?: string): string => {
  if (date) {
    return format(date, dateFormat || 'MM/dd/yyyy');
  }

  return format(new Date(), dateFormat || 'MM/dd/yyyy');
};

export const formatDateRange = (startDate: Date, endDate: Date) => {
  if (isSameDay(startDate, endDate)) {
    return formatDate(startDate, 'dd MMM yyyy');
  } else if (
    isSameYear(startDate, endDate) &&
    isSameMonth(startDate, endDate)
  ) {
    return `${formatDate(startDate, 'dd')} - ${formatDate(
      endDate,
      'dd',
    )} ${formatDate(startDate, 'MMM yyyy')}`;
  } else if (
    isSameYear(startDate, endDate) &&
    !isSameMonth(startDate, endDate)
  ) {
    return `${formatDate(startDate, 'dd MMM')} - ${formatDate(
      endDate,
      'dd MMM',
    )} ${formatDate(startDate, 'yyyy')}`;
  }

  return `${formatDate(startDate, 'dd MMM yyyy')} - ${formatDate(
    endDate,
    'dd MMM yyyy',
  )}`;
};

export const getDatesBetweenDays = (
  start: Date,
  end: Date,
  format?: string,
) => {
  for (
    var dates = [], dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    if (format) {
      dates.push(formatDate(new Date(dt), format));
    } else {
      dates.push(new Date(dt));
    }
  }
  return dates;
};

export const getMonthName = (month: number, nameFormat: string) => {
  const date = new Date();

  date.setDate(1);
  date.setMonth(month);

  return format(new Date(date), nameFormat ?? 'MMMM');
};

export const isDateInWeekend = (date: Date) => {
  return isSaturday(date) || isSunday(date);
};

export const parseDate = (date: string) => {
  return parseISO(date);
};

import { Dimensions, Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

interface ISideNavProps {
  visible?: boolean;
}

export const Container = styled.View`
  height: 100%;
  width: 100%;
  padding: 0 20px;
  justify-content: center;
`;

export const TopNav = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Hamburger = styled.TouchableOpacity.attrs({
  activeOpacity: 1,
})``;

export const PageTitle = styled.Text`
  font-size: 17px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const HamburgerIcon = styled.Text``;

export const Overlay = styled.TouchableOpacity`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props: ISideNavProps) =>
    props.visible ? 'rgba(0, 0, 0, 0.05)' : 'transparent'};
  display: ${(props: ISideNavProps) => (props.visible ? 'block' : 'none')};
  height: ${Dimensions.get('window').height}px;
`;

export const SideMenu = styled.View`
  position: fixed;
  width: 300px;
  background-color: ${props => props.theme.color.white};
  top: 0;
  bottom: 0;
  left: ${(props: ISideNavProps) => (props.visible ? '0' : '-300px')};
  height: ${Dimensions.get('window').height}px;

  ${Platform.OS === 'web' &&
  css`
    transition: all 0.5s ease 0s;
  `}
`;

import { useNavigation, useRoute } from '@react-navigation/native';
import type { Loan, LoanRequestConfig } from 'core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, SafeAreaView } from 'react-native';

import { Heading1 } from '@commons/styles';
import { KeyboardAvoidingScrollView, Loading } from '@components';
import PageMain from '@components/Page';
import ROUTES from '@navigation/routes';
import { useLazyGetLoanRequestConfigQuery } from '@redux/loans/api';
import { User } from '@types';

import LoanForm from './components/LoanForm';
import {
  FormWrapper,
  LoanRequestContainer,
  NativeContainer,
} from './index.style';

const LOAN_CUT_OFF_DAYS = 7;

interface IProps {
  salary: number;
  joinedAt?: string | null | undefined;
  user: User | null;
  loanRequestConfig?: LoanRequestConfig;
  setLoanRequestConfig: (config: LoanRequestConfig) => void;
  myLoans: Loan[];
}

const RequestLoan: React.FC<IProps> = props => {
  const { salary, setLoanRequestConfig, loanRequestConfig, myLoans } = props;
  const { t } = useTranslation();
  const { navigate } = useNavigation<any>();
  const [loanToEdit, setLoanToEdit] = useState<Loan>();

  const route = useRoute<any>();
  const mode = route.params?.mode;
  const loanIdToEdit = route.params?.loanId;

  const [
    loanRequestConfigTrigger,
    { data: loanRequestConfigData, isFetching: isFetchingConfig },
  ] = useLazyGetLoanRequestConfigQuery();

  useEffect(() => {
    loanRequestConfigTrigger({});
  }, []);

  useEffect(() => {
    if (mode === 'edit') {
      const loanToEdit = myLoans.find(loan => loan.id === loanIdToEdit);

      if (loanIdToEdit && myLoans.length > 0 && loanToEdit) {
        setLoanToEdit(loanToEdit);
      } else {
        navigate(ROUTES.APP_LOANS);
      }
    }

    if (loanRequestConfigData) {
      setLoanRequestConfig(loanRequestConfigData);
    }
  }, [mode, myLoans, loanRequestConfigData]);

  if (Platform.OS !== 'web') {
    return isFetchingConfig ? (
      <Loading />
    ) : (
      <SafeAreaView>
        <KeyboardAvoidingScrollView>
          {loanRequestConfig && (
            <NativeContainer>
              <LoanForm
                salary={salary}
                cutoffDays={LOAN_CUT_OFF_DAYS}
                config={loanRequestConfig}
                mode={mode}
                loanToEdit={loanToEdit}
              />
            </NativeContainer>
          )}
        </KeyboardAvoidingScrollView>
      </SafeAreaView>
    );
  }

  return (
    <PageMain loading={isFetchingConfig}>
      <LoanRequestContainer>
        <Heading1>
          {mode === 'edit'
            ? t('app.screens.edit-loan')
            : t('app.screens.request-loan')}
        </Heading1>
        {loanRequestConfig && (
          <FormWrapper>
            <LoanForm
              salary={salary}
              cutoffDays={LOAN_CUT_OFF_DAYS}
              config={loanRequestConfig}
              mode={mode}
              loanToEdit={loanToEdit}
            />
          </FormWrapper>
        )}
      </LoanRequestContainer>
    </PageMain>
  );
};

export default RequestLoan;

import { createApi } from '@reduxjs/toolkit/query/react';

import config from '@config';
import { PaySlipDetail } from '@types';

import axiosBaseQuery from './axiosBaseQuery';

enum PayslipsEnum {
  ReducerPath = 'payslipsApi',
  TagType = 'payslips',
}

const payslipsApi = createApi({
  reducerPath: PayslipsEnum.ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiBaseUrl,
  }),
  keepUnusedDataFor: 30,
  tagTypes: [PayslipsEnum.TagType],
  endpoints: build => ({
    getPayslips: build.query<PaySlipDetail, { year: number }>({
      query: args => ({
        url: `/payslips?year=${args.year}`,
        method: 'GET',
      }),
      transformResponse: (response: PaySlipDetail) => response,
      providesTags: [PayslipsEnum.TagType],
    }),
  }),
});

export const { useLazyGetPayslipsQuery } = payslipsApi;
export default payslipsApi;

import React, { createContext, useEffect, useState } from 'react';
import { Platform, Appearance } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { ThemeProvider as StyledThemeProvider } from 'styled-components/native';

import { get, remove, save } from '@utils/storage';
import { ThemeType } from '@types';

import { getDarkTheme } from './darkTheme';
import { getLightTheme } from './lightTheme';

export const ThemeContext = createContext({
  theme: ThemeType.LIGHT,
  isDefaultTheme: false,
  changeAppTheme: (theme: ThemeType | 'DEFAULT') => {},
});

const screenBreakPoints = {
  sm: 425,
  md: 1024,
};

const fonts = {
  MontserratRegular:
    Platform.OS === 'android' ? 'Montserrat-Regular' : 'Montserrat',
  MontserratLight:
    Platform.OS === 'android' ? 'Montserrat-Light' : 'Montserrat',
  MontserratBold: Platform.OS === 'android' ? 'Montserrat-Bold' : 'Montserrat',
  MontserratExtraBold:
    Platform.OS === 'android' ? 'Montserrat-ExtraBold' : 'Montserrat',
};

const ThemeProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [theme, setTheme] = useState(ThemeType.LIGHT);
  const [isDefaultTheme, setIsDefaultTheme] = useState(false);

  useEffect(() => {
    loadTheme();
  }, []);

  const size = {
    isSmall: useMediaQuery({ maxWidth: screenBreakPoints.sm }),
    isMedium: useMediaQuery({
      minWidth: screenBreakPoints.sm,
      maxWidth: screenBreakPoints.md,
    }),
    isLarge: useMediaQuery({ minWidth: screenBreakPoints.md }),
  };

  const themes = {
    [ThemeType.DARK]: getDarkTheme(size, fonts),
    [ThemeType.LIGHT]: getLightTheme(size, fonts),
  };

  const loadTheme = async () => {
    const savedTheme = (await get('theme')) as ThemeType.DARK | ThemeType.LIGHT;

    if (savedTheme) {
      setTheme(savedTheme);
    } else {
      const deviceColorScheme = Appearance.getColorScheme();

      if (deviceColorScheme === 'dark') {
        setTheme(ThemeType.DARK);
      } else {
        setTheme(ThemeType.LIGHT);
      }

      setIsDefaultTheme(true);
    }
  };

  const changeAppTheme = (theme: ThemeType | 'DEFAULT') => {
    if (theme === 'DEFAULT') {
      const deviceColorScheme = Appearance.getColorScheme();

      if (deviceColorScheme === 'dark') {
        setTheme(ThemeType.DARK);
      } else {
        setTheme(ThemeType.LIGHT);
      }

      setIsDefaultTheme(true);
      remove('theme');
    } else {
      let newTheme;

      if (theme === ThemeType.LIGHT) {
        newTheme = ThemeType.LIGHT;
      } else {
        newTheme = ThemeType.DARK;
      }

      save('theme', newTheme);
      setIsDefaultTheme(false);
      setTheme(newTheme);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, changeAppTheme, isDefaultTheme }}>
      <StyledThemeProvider theme={themes[theme]}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

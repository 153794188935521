import styled from 'styled-components/native';

interface ColorProps {
  color: string;
}

export const ModalContainer = styled.View`
  background-color: ${props => props.theme.color.white};
  border-radius: 10px;
  width: 80%;
  max-width: 350px;
`;

export const ModalHeader = styled.View`
  background-color: ${(props: ColorProps) => props.color};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  min-height: 50px;
  justify-content: center;
`;

export const Title = styled.Text`
  color: ${props => props.theme.color.alwaysWhite};
  font-size: 18px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const ModalBody = styled.View``;

export const ModalAction = styled.View`
  padding: 10px;
  flex-direction: row;
  justify-content: center;
`;

export const ActionButton = styled.TouchableOpacity`
  background-color: ${(props: ColorProps) => props.color};
  padding: 10px;
  flex: 1;
  margin: 0 5px;
  border-radius: 10px;
`;

export const ActionButtonText = styled.Text`
  text-align: center;
  font-size: 15px;
  color: ${props => props.theme.color.alwaysWhite};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

import styled from 'styled-components/native';
import FeatherIcons from 'react-native-vector-icons/Feather';

interface ColorProps {
  color: string;
}

export const Button = styled.TouchableOpacity<{
  disabled?: boolean;
}>`
  padding: 12px 15px;
  border-radius: 5px;
  background-color: ${props =>
    props.disabled ? props.theme.color.grey : props.theme.color.primary};
`;

export const Text = styled.Text`
  color: ${props => props.theme.color.alwaysWhite};
  text-align: center;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const OutlinedButton = styled.TouchableOpacity`
  border: 1px solid ${(props: ColorProps) => props.color};
  padding: 8px 8px;
  border-radius: 5px;
  margin-left: 10px;
`;

export const ButtonIcon = styled(FeatherIcons)`
  font-size: 14px;
  color: ${(props: ColorProps) => props.color};
`;

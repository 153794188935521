import React from 'react';
import { useTheme } from 'styled-components/native';

import { DesktopNav, MobileNav } from './components';
import { Container } from './index.style';

const Navbar = () => {
  const theme = useTheme();

  return (
    <Container>{theme.size.isLarge ? <DesktopNav /> : <MobileNav />}</Container>
  );
};

export default Navbar;

import { Platform } from 'react-native';
import styled, { DefaultTheme } from 'styled-components/native';
import FeatherIcons from 'react-native-vector-icons/Feather';

import { AttendanceEventType } from '@types';
import { getAttendanceEventColor } from '@utils/attendance';

interface AttendanceTypeProps {
  type: AttendanceEventType;
  theme: DefaultTheme;
}

interface ColorProps {
  color: string;
}

export const Container = styled.View`
  padding: 15px 10px;
  padding-bottom: ${Platform.OS === 'web' ? '10px' : 0};
`;

export const Event = styled.View``;

export const EventWrapper = styled.View`
  padding: 8px 0;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

export const AttendanceTypeIndicator = styled.View`
  height: 35px;
  width: 35px;
  background-color: ${(props: AttendanceTypeProps) =>
    getAttendanceEventColor(props.type)};
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  margin: 3px 10px 0 0;
`;

export const AttendanceCode = styled.Text`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.color.alwaysWhite};
  font-family: ${props => props.theme.fonts.MontserratRegular};
  text-transform: uppercase;
`;

export const AttendanceDetailsWrapper = styled.View`
  flex: 1;
`;

export const AttendanceHeader = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const AttendanceDetails = styled.View`
  flex: 1;
`;

export const AttendanceActions = styled.View`
  flex-direction: row;
`;

export const ActionButton = styled.TouchableOpacity`
  border: 1px solid ${(props: ColorProps) => props.color};
  padding: 8px 8px;
  border-radius: 5px;
  margin-left: 10px;
`;

export const ActionIcons = styled(FeatherIcons)`
  font-size: 14px;
  color: ${(props: ColorProps) => props.color};
`;

export const AttendanceLabelWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const StatusBadge = styled.View`
  background-color: ${(props: ColorProps) => props.color};
  padding: 3px 8px;
  border-radius: 10px;
  margin-top: -2px;
  height: 22px;
`;

export const StatusText = styled.Text`
  color: ${props => props.theme.color.white};
  font-size: 12px;
  flex: 1;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const EventDate = styled.Text`
  font-size: 13px;
  color: ${props => props.theme.color.text};
  margin-bottom: 3px;
  font-family: ${props => props.theme.fonts.MontserratBold};
  font-weight: 600;
`;

export const EventType = styled.Text`
  font-size: 14px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
  margin-right: 10px;
`;

export const DescriptionSection = styled.View`
  margin: 12px 0 0 0;
`;

export const DescriptionHeader = styled.Text`
  font-family: ${props => props.theme.fonts.MontserratBold};
  color: ${props => props.theme.color.primary};
  font-weight: 600;
`;

export const DescriptionText = styled.Text`
  font-size: 14px;
  color: ${props => props.theme.color.grey};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

import React from 'react';
import {
  ActivityIndicator,
  StyleProp,
  ViewProps,
  ViewStyle,
} from 'react-native';

import { ButtonIcon, OutlinedButton } from './index.style';

interface IProps extends ViewProps {
  color: string;
  featherIconName?: string;
  onPress: () => void;
  loading?: boolean;
  buttonStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
}

const IconButton: React.FC<IProps> = ({
  color,
  buttonStyle,
  children,
  featherIconName,
  loading,
  onPress,
  disabled,
}) => {
  return (
    <OutlinedButton
      color={color}
      disabled={disabled}
      style={buttonStyle}
      onPress={onPress}>
      {loading ? (
        <ActivityIndicator color={color} size={'small'} />
      ) : featherIconName ? (
        <ButtonIcon name={featherIconName} color={color} />
      ) : (
        children
      )}
    </OutlinedButton>
  );
};

export default IconButton;

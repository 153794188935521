import { useTranslation } from 'react-i18next';
import React from 'react';
import { useTheme } from 'styled-components/native';

import { ATTENDANCE_STATUS, ATTENDANCE_TYPES } from '@constants';
import {
  getAttendanceEventCode,
  getAttendanceEventLabel,
  getAttendanceStatusColor,
  getAttendanceStatusLabel,
} from '@utils/attendance';
import { formatDateRange, parseDate } from '@utils/dateTime';
import { Attendance } from '@types';

import {
  AttendanceCode,
  AttendanceLabelWrapper,
  AttendanceDetailsWrapper,
  AttendanceTypeIndicator,
  Container,
  EventDate,
  DescriptionText,
  EventType,
  EventWrapper,
  StatusBadge,
  StatusText,
  AttendanceActions,
  ActionButton,
  ActionIcons,
  Event,
  AttendanceHeader,
  AttendanceDetails,
  DescriptionSection,
  DescriptionHeader,
} from './index.style';

interface IProps {
  attendances?: Attendance[];
  onAttendanceDelete: (attendance: Attendance) => void;
  onAttendanceUpdate: (attendance: Attendance) => void;
}

const AttendanceEventDetail: React.FC<IProps> = props => {
  const { attendances, onAttendanceDelete, onAttendanceUpdate } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  if (attendances) {
    return (
      <Container>
        {attendances.map(attendance => {
          const startDate = parseDate(attendance.start);
          const endDate = parseDate(attendance.end);

          return (
            <Event key={`attendance-detail-${attendance.id}`}>
              <EventWrapper>
                <AttendanceTypeIndicator type={attendance.eventType}>
                  <AttendanceCode>
                    {getAttendanceEventCode(attendance)}
                  </AttendanceCode>
                </AttendanceTypeIndicator>
                <AttendanceDetailsWrapper>
                  <AttendanceHeader>
                    <AttendanceDetails>
                      <EventDate>
                        {formatDateRange(startDate, endDate)}
                      </EventDate>
                      <AttendanceLabelWrapper>
                        <EventType>
                          {getAttendanceEventLabel(attendance)}
                        </EventType>
                        <StatusBadge
                          color={getAttendanceStatusColor(attendance.status)}>
                          <StatusText>
                            {getAttendanceStatusLabel(attendance.status)}
                          </StatusText>
                        </StatusBadge>
                      </AttendanceLabelWrapper>
                    </AttendanceDetails>
                    {attendance.eventType === ATTENDANCE_TYPES.LEAVE.key && (
                      <AttendanceActions>
                        {attendance.status === ATTENDANCE_STATUS.PROPOSED && (
                          <ActionButton
                            color={theme.color.green}
                            onPress={() => onAttendanceUpdate(attendance)}>
                            <ActionIcons
                              name="edit"
                              color={theme.color.green}
                            />
                          </ActionButton>
                        )}
                        {(attendance.status === ATTENDANCE_STATUS.PROPOSED ||
                          attendance.status === ATTENDANCE_STATUS.REJECTED) && (
                          <ActionButton
                            color={theme.color.red}
                            onPress={() => onAttendanceDelete(attendance)}>
                            <ActionIcons name="trash" color={theme.color.red} />
                          </ActionButton>
                        )}
                      </AttendanceActions>
                    )}
                  </AttendanceHeader>

                  {!!attendance.description && (
                    <DescriptionSection>
                      <DescriptionHeader>
                        {t('app.attendance.reason')}:
                      </DescriptionHeader>
                      <DescriptionText numberOfLines={5} ellipsizeMode={'tail'}>
                        {attendance.description}
                      </DescriptionText>
                    </DescriptionSection>
                  )}

                  {!!attendance.rejectionReason && (
                    <DescriptionSection>
                      <DescriptionHeader>
                        {t('app.attendance.rejection-reason')}:
                      </DescriptionHeader>
                      <DescriptionText numberOfLines={5} ellipsizeMode={'tail'}>
                        {attendance.rejectionReason}
                      </DescriptionText>
                    </DescriptionSection>
                  )}
                </AttendanceDetailsWrapper>
              </EventWrapper>
            </Event>
          );
        })}
      </Container>
    );
  }

  return <></>;
};

export default AttendanceEventDetail;

import _ from 'lodash';
import React from 'react';
import { Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import PageMain from '@components/Page';
import { Loading, Timeline } from '@components';
import { useGetEmployeeEventsQuery } from '@redux/apis/events';
import { TimelineData } from '@components/Timeline';
import { formatCurrency } from '@utils/NumberFormat';

import EventDetail from './component/EventDetail';
import {
  Container,
  EmptyEventContainer,
  EmptyTitleText,
  HeaderDateText,
  HeaderText,
  TimelineContainer,
  TitleContainer,
} from './index.style';

const TimelineScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const screenSize = useTheme();
  const { data, isLoading, isFetching } = useGetEmployeeEventsQuery();

  const timelineComponent = () => {
    const timelines: TimelineData[] = _.flatMap(data, event => ({
      headerContent: {
        component: (
          <View>
            {(Platform.OS === 'android' ||
              Platform.OS === 'ios' ||
              !screenSize.size.isLarge) && (
              <HeaderDateText>
                {format(new Date(event.date), 'dd MMMM yyyy')}
              </HeaderDateText>
            )}
            <TitleContainer>
              <HeaderText>{event.typeName}</HeaderText>
              {typeof event.salary !== 'undefined' && event.salary > 0 && (
                <HeaderText style={{ fontSize: 15, fontWeight: '500' }}>
                  {formatCurrency(
                    event.salary + event.dearnessAllowance,
                    event.currency,
                  )}
                </HeaderText>
              )}
              {event.bonus && (
                <HeaderText style={{ fontSize: 15, fontWeight: '500' }}>
                  {formatCurrency(event.bonus, event.currency)}
                </HeaderText>
              )}
            </TitleContainer>
          </View>
        ),
      },
      time: {
        timeText: format(new Date(event.date), 'dd MMM yyyy'),
      },
      mainContent: {
        component: <EventDetail event={event} />,
      },
      icon: {
        name: event.status ? 'check' : 'clock-o',
        style: event.status
          ? { backgroundColor: theme.color.lightGreen2 }
          : { backgroundColor: theme.color.lightYellow },
      },
    }));

    return data?.length === 0 ? (
      <EmptyEventContainer>
        <EmptyTitleText style={{ fontSize: 16 }}>
          {t('app.timeline.empty-event-message')}
        </EmptyTitleText>
      </EmptyEventContainer>
    ) : (
      <TimelineContainer
        style={{
          marginLeft:
            Platform.OS === 'web' && screenSize.size.isLarge ? '10%' : 0,
        }}>
        <Timeline
          data={timelines}
          timeContainerStyle={{
            display:
              Platform.OS === 'web' && screenSize.size.isLarge
                ? 'flex'
                : 'none',
            flexBasis: Platform.OS === 'web' ? '10%' : '25%',
            marginLeft: screenSize.size.isLarge ? 70 : 0,
          }}
          contentContainerStyle={{
            flexBasis:
              Platform.OS === 'web' && screenSize.size.isLarge ? '50%' : '80%',
          }}
          eventIconStyle={{
            flexBasis: Platform.OS === 'web' ? 'auto' : '6%',
          }}
        />
      </TimelineContainer>
    );
  };

  if (Platform.OS === 'web') {
    return (
      <PageMain loading={isLoading || isFetching}>
        {timelineComponent()}
      </PageMain>
    );
  }

  return (
    <Container>
      {isLoading || isFetching ? <Loading /> : timelineComponent()}
    </Container>
  );
};

export default TimelineScreen;

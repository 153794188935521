import styled from 'styled-components/native';

export const SelectedValue = styled.Text`
  font-family: ${props => props.theme.fonts.MontserratBold};
  color: ${props => props.theme.color.primary};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin: 10px 0 0 0;
`;

export const SliderWrapper = styled.View``;

import { createApi } from '@reduxjs/toolkit/query/react';

import config from '@config';

import axiosBaseQuery from './axiosBaseQuery';
import type { EmployeeDocument } from 'core';

enum DocumentsEnum {
  ReducerPath = 'documentsApi',
  TagType = 'documents',
}

const documentsApi = createApi({
  reducerPath: DocumentsEnum.ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiBaseUrl,
  }),
  keepUnusedDataFor: 30,
  tagTypes: [DocumentsEnum.TagType],
  endpoints: build => ({
    getDocuments: build.query<EmployeeDocument[], void>({
      query: () => ({
        url: `/documents`,
        method: 'GET',
      }),
      transformResponse: (response: EmployeeDocument[]) => response,
      providesTags: [DocumentsEnum.TagType],
    }),
  }),
});

export const { useLazyGetDocumentsQuery } = documentsApi;
export default documentsApi;

import { ReactElement, useEffect } from 'react';

import { SUPERTOKEN } from '@constants';
import { setUser } from '@redux/auth/slice';
import { useAppDispatch } from '@redux/hook';
import { resetMyAttendance } from '@redux/myAttendance/slice';
import { resetGeneralAttendance } from '@redux/generalAttendance/slice';

import { axiosInstance } from '../axiosHelper';
import { removeSuperToken } from '../storage';

interface IProps {
  children: ReactElement | null;
  sessionRevoke: Function;
  isNative?: boolean;
}

const AxiosInterceptor: React.FC<IProps> = ({
  children,
  sessionRevoke,
  isNative,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        if (error?.response?.status === 401) {
          if (error.message !== SUPERTOKEN.ERROR.TRY_REFRESH_TOKEN) {
            await sessionRevoke();

            if (isNative) await removeSuperToken();

            dispatch(resetGeneralAttendance());
            dispatch(resetMyAttendance());
            dispatch(setUser(null));
          }
        }
        return Promise.reject(error);
      },
    );
  }, [setUser]);

  return children;
};

export default AxiosInterceptor;

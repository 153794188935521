import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const BadgeContainer = styled.View`
  background-color: ${props => props.theme.color.white};
  border-radius: 50px;
  width: 15px;
  height: 15px;
  right: -5px;
  position: absolute;
  top: -5px;
  border-color: ${props => props.theme.color.accent};
  border-width: 2px;
`;

export const BadgeCountText = styled.Text`
  color: ${props => props.theme.color.accent};
  font-size: 10px;
  align-self: center;
  margin-top: ${Platform.OS === 'android' ? '-1.5px' : '-1px'};
  font-weight: bold;
  font-family: ${props => props.theme.fonts.MontserratBold};
`;

import { createApi } from '@reduxjs/toolkit/query/react';

import config from '@config';
import { LeaveRule } from '@types';

import axiosBaseQuery from './axiosBaseQuery';

enum LeaveRulesEnum {
  ReducerPath = 'leaveRulesApi',
  TagType = 'leaveRules',
}

const leaveRulesApi = createApi({
  reducerPath: LeaveRulesEnum.ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiBaseUrl,
  }),
  keepUnusedDataFor: 30,
  tagTypes: [LeaveRulesEnum.TagType],
  endpoints: build => ({
    getLeaveRules: build.query<LeaveRule[], void>({
      query: () => ({
        url: `/leave-rules`,
        method: 'GET',
      }),
      transformResponse: (response: LeaveRule[]) => response,
      providesTags: [LeaveRulesEnum.TagType],
    }),
  }),
});

export const { useGetLeaveRulesQuery } = leaveRulesApi;
export default leaveRulesApi;

import React from 'react';

import { Container, Message } from './index.style';

interface IProps {
  message: string;
}

const CenteredMessage: React.FC<IProps> = props => {
  const { message } = props;

  return (
    <Container>
      <Message>{message}</Message>
    </Container>
  );
};

export default CenteredMessage;

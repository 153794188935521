import { ThemeType } from '@types';
import { Dimensions, Platform } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import styled, { DefaultTheme, css } from 'styled-components/native';

interface ActiveProps {
  active?: boolean;
  theme: DefaultTheme;
}

export const Container = styled.View`
  flex-direction: row;
  justify-content: space-around;
  min-height: 50px;
  background-color: ${props => props.theme.color.white};
  position: fixed;
  width: 100%;
  z-index: -1;
  margin-top: ${Dimensions.get('window').height - 50}px;

  ${props =>
    Platform.OS === 'web' &&
    css`
      box-shadow: 0 0 10px
        ${props.theme.name === ThemeType.LIGHT
          ? props.theme.color.lightGrey
          : props.theme.color.background};
      transition: 0.5s;
    `};
`;

export const MenuItem = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const MaterialMenuIcons = styled(MaterialCommunityIcons)`
  font-size: 20px;
  color: ${(props: ActiveProps) =>
    props.active ? props.theme.color.primary : props.theme.color.lightGrey6};
`;

export const MenuLabel = styled.Text`
  font-family: ${props => props.theme.fonts.MontserratRegular};
  margin-bottom: -3px;
  color: ${(props: ActiveProps) =>
    props.active ? props.theme.color.primary : props.theme.color.lightGrey6};
  font-size: 11px;
  text-align: center;
`;

import styled from 'styled-components/native';

export const Title = styled.Text`
  color: ${props => props.theme.color.grey};
  font-size: 11px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const SubTitle = styled.Text`
  color: ${props => props.theme.color.grey};
  font-size: 11px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

type NumberFormatProps = {
  value: number;
  thousandSeparator?: string;
  decimalSeparator?: string;
  significantDigits?: number;
  showTrailingZeros?: boolean;
  symbol?: string;
  showSymbol?: boolean;
  symbolPosition?: 'before' | 'after';
  showSymbolSpace?: boolean;
};

export const getFormattedNumber = ({
  value,
  thousandSeparator = ',',
  decimalSeparator = '.',
  significantDigits = 0,
  showTrailingZeros = false,
  symbol = '$',
  showSymbol = true,
  symbolPosition = 'after',
  showSymbolSpace = true,
}: NumberFormatProps) => {
  const significantDigitsExponent = 10 ** significantDigits;
  const valueWithSignificantDigits = showTrailingZeros
    ? value.toFixed(significantDigits)
    : `${
        Math.round((value + Number.EPSILON) * significantDigitsExponent) /
        significantDigitsExponent
      }`;

  const [integerPart, fractionalPart] = valueWithSignificantDigits
    .toString()
    .split('.');

  const formattedIntegerPart = `${integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    thousandSeparator,
  )}`;

  const formattedValue = fractionalPart
    ? `${formattedIntegerPart}${decimalSeparator}${fractionalPart}`
    : formattedIntegerPart;

  if (showSymbol && Boolean(symbol)) {
    const formattedValueWithSymbol =
      symbolPosition === 'after'
        ? `${formattedValue} ${symbol}`
        : `${symbol} ${formattedValue}`;
    return showSymbolSpace
      ? formattedValueWithSymbol
      : formattedValueWithSymbol.replace(' ', '');
  }

  return formattedValue;
};

export const formatCurrency = (value: number, currency: string) => {
  return getFormattedNumber({
    value,
    thousandSeparator: ',',
    symbol: currency === 'USD' ? '$' : currency,
    symbolPosition: 'before',
  });
};

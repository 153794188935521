import React from 'react';
import { ColorValue, Platform, StyleProp, ViewStyle } from 'react-native';
import { useTheme } from 'styled-components/native';

import { EventContainer } from './index.style';

interface EventProps {
  children: React.ReactNode;
  eventStyle?: StyleProp<ViewStyle>;
  shadowColor?: ColorValue;
}
const EventCard: React.FC<EventProps> = ({
  children,
  eventStyle,
  shadowColor,
}) => {
  const theme = useTheme();

  return (
    <EventContainer
      style={[
        eventStyle,
        {
          shadowOffset: {
            width: 0,
            height: Platform.OS === 'web' ? 1 : 5,
          },
          shadowColor: shadowColor
            ? shadowColor
            : theme.color.transparentBlack2,
          shadowOpacity: 0.2,
        },
      ]}>
      {children}
    </EventContainer>
  );
};

export default EventCard;

import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/Feather';

export const ModelContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const CalenderContainer = styled.View`
  background-color: ${props => props.theme.color.white};
  padding: 8px;
  border-color: ${props => props.theme.color.lightGrey};
  border-width: 1px;
  border-radius: 5px;
`;

export const CloseIcon = styled(Icon)`
  align-self: flex-end;
  margin-bottom: 5px;
  color: ${props => props.theme.color.text};
`;

export const BottomContainer = styled.View`
  height: 30px;
  background-color: ${props => props.theme.color.white};
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
  border-top-width: 1px;
  border-color: ${props => props.theme.color.lightGrey3};
  padding-top: 10px;
`;

export const ModelButton = styled.TouchableOpacity`
  margin-left: 10px;
  padding-left: 10px;
`;

export const ModelButtonText = styled.Text`
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

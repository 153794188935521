import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';

import { LoginScreen, AuthGoogleCallback, PrivacyPolicyScreen } from '@screens';
import i18n from '@config/i18n';

import ROUTES from '../routes';

const Stack = createNativeStackNavigator();

const AuthNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        component={LoginScreen}
        name={ROUTES.APP_LOGIN}
        options={{
          title: i18n.t('app.screens.login') ?? ROUTES.APP_LOGIN,
          headerShown: false,
        }}
      />
      <Stack.Screen
        component={AuthGoogleCallback}
        name={ROUTES.APP_GOOGLE_AUTH}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        component={PrivacyPolicyScreen}
        name={ROUTES.APP_PRIVACY_POLICY}
        options={{
          title: 'Privacy policy' ?? ROUTES.APP_PRIVACY_POLICY,
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default AuthNavigator;

import config from '@config';
import { User } from '@types';
import { axiosInstance } from '@utils/axiosHelper';
import { get, save } from '@utils/storage';
import firebaseConfig from './firebase.config';
import { initializeApp } from 'firebase/app';
import {
  Messaging,
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';

const ST_DEVICE_TOKEN = 'device_token';

const NotificationService = {
  init: async (user: User | null) => {
    let serviceWorkerRegistration;

    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);

    await NotificationService.registerToken(
      messaging,
      user,
      serviceWorkerRegistration,
    );
    await NotificationService.onMessageReceived(messaging);
  },
  displayNotification: () => {},
  registerToken: async (
    messaging: Messaging,
    user: User | null,
    serviceWorkerRegistration?: ServiceWorkerRegistration,
  ) => {
    const permission = await NotificationService.requestPermission();

    try {
      if (permission === 'granted') {
        const storedToken = await get(ST_DEVICE_TOKEN);
        let deviceToken;

        if (storedToken) {
          deviceToken = storedToken;
        } else {
          deviceToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_WEB_FCM_KEY as string,
            serviceWorkerRegistration: serviceWorkerRegistration,
          });

          await save(ST_DEVICE_TOKEN, deviceToken);
        }

        if (deviceToken && user) {
          await axiosInstance.post(`${config.apiBaseUrl}/device-token`, {
            deviceToken,
          });

          console.log('Token registered successfully.');
        }
      }
    } catch (error) {
      console.log('Token registration error:', error);
    }
  },
  clearNotifications: () => {},
  clearNotificationStateOnLogout: async () => {},
  registerAppWithFCM: () => {},
  requestPermission: async () => {
    const permission = await Notification.requestPermission();

    return permission;
  },
  registerNotificationListeners: () => {},
  onMessageReceived: async (messaging: Messaging) => {
    onMessage(messaging, payload => {
      if ('Notification' in window && payload.notification) {
        const { title, body } = payload.notification;

        new Notification(title!, {
          body: body,
        });
      }
    });
  },
  onNotificationOpened: () => {},
};

export default NotificationService;

import { useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import SuperToken from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import { useTheme } from 'styled-components/native';

import i18n from '@config/i18n';
import AppDrawer from '@components/AppDrawer';
import { BasicButton } from '@components/Button';
import LocaleSwitcher from '@components/LocaleSwitcher';
import { getRouteTitleByName } from '@navigation/utils';
import ROUTES from '@navigation/routes';
import { useAppDispatch } from '@redux/hook';
import { logoutUser } from '@redux/auth/slice';

import {
  Container,
  Hamburger,
  Overlay,
  PageTitle,
  SideMenu,
  TopNav,
} from './index.style';

const MobileNav = () => {
  const dispatch = useAppDispatch();
  const route = useRoute();
  const theme = useTheme();

  const [sideNavVisible, setSideNavVisible] = useState(false);

  const toggleSideNav = () => {
    setSideNavVisible(!sideNavVisible);
  };

  const signOut = async () => {
    await SuperToken.signOut();
    dispatch(logoutUser());
  };

  const signOutComponent = (
    <View style={{ marginBottom: 20 }}>
      <BasicButton onPress={signOut} label={i18n.t('app.sign-out')} />
    </View>
  );

  return (
    <Container>
      <TopNav>
        <Hamburger onPress={toggleSideNav}>
          <Icon name="menu" size={25} color={theme.color.text} />
        </Hamburger>
        <PageTitle>{getRouteTitleByName(route.name)}</PageTitle>
        <View>{route.name === ROUTES.APP_PROFILE && <LocaleSwitcher />}</View>
      </TopNav>
      <Overlay visible={sideNavVisible} onPress={toggleSideNav} />
      <TouchableWithoutFeedback>
        <SideMenu visible={sideNavVisible}>
          <AppDrawer
            closeDrawer={toggleSideNav}
            signOutComponent={signOutComponent}
          />
        </SideMenu>
      </TouchableWithoutFeedback>
    </Container>
  );
};

export default MobileNav;

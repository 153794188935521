import { isAfter, isSameDay } from 'date-fns';
import _ from 'lodash';

import { Contract } from '@types';

const getActiveContract = (contracts: Contract[]) => {
  const activeContract = _.find(contracts, contract => {
    const isContractActive =
      contract.end === null ||
      isAfter(new Date(contract.end), new Date()) ||
      isSameDay(new Date(contract.end), new Date());

    return isContractActive;
  });

  return activeContract;
};

const getLatestContract = (contracts: Contract[]) => {
  if (contracts.length > 0) {
    return contracts.reduce((acc, curr) => {
      if (curr.end > acc.end || curr.end === null) {
        return curr;
      } else {
        return acc;
      }
    });
  }
};

const getOldestContract = (contracts: Contract[]) => {
  if (contracts.length > 0) {
    return contracts.reduce((acc, curr) => {
      if (curr.start < acc.start) {
        return curr;
      } else {
        return acc;
      }
    });
  }
};

export { getActiveContract, getLatestContract, getOldestContract };

import AsyncStorage from '@react-native-async-storage/async-storage';
import { add, format } from 'date-fns';

const ID_REFRESH_TOKEN_KEY = 'supertokens-rn-idrefreshtoken-key';
const FRONT_TOKEN_KEY = 'supertokens-rn-front-token-key';

const setSuperToken = async (refreshToken?: string, frontToken?: string) => {
  const expireDate = format(
    add(new Date(), { months: 3 }),
    'EEE, dd MMM yyyy hh:mm::ss',
  );

  await AsyncStorage.multiSet([
    [
      ID_REFRESH_TOKEN_KEY,
      `sIRTFrontend=${refreshToken}; Path=/; Expires=${expireDate} GMT; HttpOnly; SameSite=Lax`,
    ],
    [
      FRONT_TOKEN_KEY,
      `sFrontToken=${frontToken}; Path=/; Expires=${expireDate} GMT; HttpOnly; SameSite=Lax`,
    ],
  ]);
};

const removeSuperToken = async () => {
  try {
    await AsyncStorage.multiRemove([ID_REFRESH_TOKEN_KEY, FRONT_TOKEN_KEY]);
  } catch (error) {
    console.log(error);
  }
};

const save = async (key: string, value: string) => {
  try {
    await AsyncStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    return null;
  }
};

const get = async (key: string) => {
  try {
    const itemString = await AsyncStorage.getItem(key);

    if (itemString) {
      return JSON.parse(itemString);
    }

    return null;
  } catch (error) {
    return null;
  }
};

const remove = async (key: string) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (error) {}
};

export { get, removeSuperToken, save, setSuperToken, remove };

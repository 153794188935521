import { useNavigation } from '@react-navigation/native';
import {
  add,
  endOfMonth,
  endOfWeek,
  isAfter,
  isBefore,
  isSameYear,
  isToday,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { DataTable } from 'react-native-paper';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { useTheme } from 'styled-components/native';

import { Attendance, Employee, User } from '@types';
import {
  formatDate,
  getDatesBetweenDays,
  isDateInWeekend,
  parseDate,
} from '@utils/dateTime';
import ROUTES from '@navigation/routes';
import { Holiday } from '@types';
import { getLatestContract, getOldestContract } from '@utils/employee';

import {
  ATTENDANCE_STATUS,
  ATTENDANCE_TABLE_VIEWS,
  ATTENDANCE_TYPES,
} from '@constants';

import {
  AttendanceBadge,
  CustomCalendarMarking,
  CustomDayMarking,
} from '../../types/attendance';
import {
  Container,
  DayCellText,
  DayCellWrapper,
  EmployeeHeading,
  EmployeeName,
  MonthDay,
  TableHeader,
  TableTitle,
  Navigation,
  NavigationButton,
  NavigationButtonText,
  TableWrapper,
  DayBadge,
  DataRows,
  RowWrapper,
  HeaderRow,
  SearchWrapper,
  SearchTextField,
  TitleWrapper,
  SearchBox,
  LoaderWrapper,
  RejectedDot,
  RejectedDotWrapper,
  WDHeading,
  WorkingDayCell,
  MonthDayContainer,
} from './index.style';
import { Popable } from '@components';
import config from '@config';

interface IProps {
  currentMonth: string;
  enableScrolling?: boolean;
  attendanceMarkings: { [key: string]: CustomCalendarMarking };
  employees: Employee[];
  fetchAttendance: (monthsToFetch: string[]) => void;
  holidays: Holiday[];
  tableHeight?: number;
  user?: User;
  loading?: boolean;
}

const AttendanceTable: React.FC<IProps> = props => {
  const {
    currentMonth,
    enableScrolling,
    employees,
    attendanceMarkings,
    fetchAttendance,
    holidays,
    tableHeight,
    loading,
    user,
  } = props;
  const attendanceView =
    Platform.OS === 'web'
      ? ATTENDANCE_TABLE_VIEWS.MONTHLY
      : ATTENDANCE_TABLE_VIEWS.WEEKLY;
  const [visibleDays, setVisibleDays] = useState<Date[]>([]);
  const [holidayMarkings, setHolidayMarkings] = useState<{
    [key: string]: any;
  }>({});
  const [filteredEmployees, setFilteredEmployees] = useState<any[]>([]);
  const [visibleMonths, setVisibleMonths] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>();
  const [dataTableWidth, setDataTableWidth] = useState(0);
  const [highlightedRow, setHighlightedRow] = useState();
  const theme = useTheme();
  const { t } = useTranslation();
  const { navigate } = useNavigation<any>();

  useEffect(() => {
    let daysToDisplay: Date[] = [];
    if (attendanceView === ATTENDANCE_TABLE_VIEWS.MONTHLY) {
      daysToDisplay = getDatesBetweenDays(
        startOfMonth(parseDate(currentMonth)),
        endOfMonth(parseDate(currentMonth)),
      ) as Date[];
    } else {
      const weekStart = startOfWeek(parseDate(currentMonth));
      const weekEnd = endOfWeek(parseDate(currentMonth));

      daysToDisplay = getDatesBetweenDays(weekStart, weekEnd) as Date[];
    }

    setVisibleDays(daysToDisplay);
  }, [currentMonth]);

  useEffect(() => {
    if (!searchText) {
      setFilteredEmployees(employees);
    } else {
      setFilteredEmployees(
        employees.filter(employee => {
          return employee.givenName
            .toLowerCase()
            .startsWith(searchText.toLowerCase());
        }),
      );
    }
  }, [employees, searchText]);

  useEffect(() => {
    const markings: { [key: string]: any } = {};

    holidays.forEach(holiday => {
      const { start, end } = holiday;
      const startDate = parseDate(start);
      const endDate = parseDate(end);
      const daysInBetween = getDatesBetweenDays(startDate, endDate) as Date[];

      daysInBetween.forEach(day => {
        markings[formatDate(day, 'yyyy-MM-dd')] = { name: holiday.name };
      });
    });

    setHolidayMarkings(markings);
  }, [holidays]);

  useEffect(() => {
    if (visibleDays.length > 0) {
      const monthsInVisibleSection: string[] = [];

      visibleDays.forEach(day => {
        if (
          monthsInVisibleSection.indexOf(formatDate(day, 'yyyy-MM-01')) === -1
        ) {
          monthsInVisibleSection.push(formatDate(day, 'yyyy-MM-01'));
        }
      });

      setVisibleMonths(monthsInVisibleSection);
      fetchAttendance(monthsInVisibleSection);
    }
  }, [visibleDays]);

  const getDayMarkings = (
    date: Date,
    employeeId: number,
    contractEndDate?: Date,
    contractStartDate?: Date,
  ) => {
    let invalidContract = false;
    const isWeekend = isDateInWeekend(date);
    const holidayMarking = holidayMarkings[formatDate(date, 'yyyy-MM-dd')];
    const employeeAttendanceMarking = attendanceMarkings[employeeId];

    const currentDateMarking = isToday(date);

    const marking = employeeAttendanceMarking
      ? employeeAttendanceMarking[formatDate(date, 'yyyy-MM-dd')]
      : undefined;

    if (contractEndDate) {
      invalidContract = isAfter(date, contractEndDate);
    }

    if (!invalidContract && contractStartDate) {
      invalidContract = isBefore(date, contractStartDate);
    }

    const getMarking = (marking: CustomDayMarking) => {
      let badges: AttendanceBadge[] =
        marking && marking.badges ? marking.badges : [];

      const proposedBadges = badges.filter(
        badge => badge.status === ATTENDANCE_STATUS.PROPOSED,
      );
      const approvedBadges = badges.filter(
        badge => badge.status === ATTENDANCE_STATUS.APPROVED,
      );
      const rejectedBadges = badges.filter(
        badge => badge.status === ATTENDANCE_STATUS.REJECTED,
      );

      const onMarkingClicked = (marking: CustomDayMarking) => {
        if (marking['attendances'] && user?.isManager) {
          const proposedAttendances = marking['attendances'].filter(
            attendance => attendance.status === ATTENDANCE_STATUS.PROPOSED,
          );

          if (
            proposedAttendances.length > 0 &&
            user?.employeeId !== proposedAttendances[0].employeeId &&
            (proposedAttendances[0].eventType === ATTENDANCE_TYPES.LEAVE.key ||
              proposedAttendances[0].eventType ===
                ATTENDANCE_TYPES.COMPENSATION_LEAVE.key)
          ) {
            navigate(ROUTES.APP_CREATE_ATTENDANCE, {
              attendanceId: proposedAttendances[0].id,
              activeMonth: proposedAttendances[0].start,
              type: 'edit',
              isPendingLeave:
                user?.isManager && proposedAttendances[0].status === 0,
            });
          }
        }
      };

      return (
        <DayCellWrapper
          color={marking['backgroundColor']}
          onPress={() => onMarkingClicked(marking)}
          key={`${employeeId}-${formatDate(date, 'yyyy-MM-dd')}`}
          isCurrentDate={currentDateMarking}>
          {rejectedBadges.length > 0 && (
            <RejectedDotWrapper>
              <RejectedDot
                key={`rejected-${employeeId}-${formatDate(date, 'yyyy-MM-dd')}
                }`}
              />
            </RejectedDotWrapper>
          )}

          {approvedBadges.map(badge => {
            if (badge['type'] !== ATTENDANCE_TYPES.WORK_FROM_HOME.key) {
              return (
                <DayBadge
                  key={`${employeeId}-${formatDate(date, 'yyyy-MM-dd')}-${
                    badge['code']
                  }`}
                  color={badge['color']}>
                  <DayCellText>{badge['code']}</DayCellText>
                </DayBadge>
              );
            }
          })}

          {proposedBadges.map(badge => {
            return (
              <DayBadge
                key={`${employeeId}-${formatDate(date, 'yyyy-MM-dd')}-${
                  badge['code']
                }`}
                color={badge['color']}>
                <DayCellText>{badge['code']}</DayCellText>
              </DayBadge>
            );
          })}
        </DayCellWrapper>
      );
    };

    if (holidayMarking && !isWeekend) {
      if (marking && marking['badges']) {
        const overtime = _.find(
          marking['badges'],
          badge => badge['type'] === ATTENDANCE_TYPES.OVERTIME.key,
        );

        if (overtime) {
          return getMarking(marking);
        }
      }

      return (
        <DayCellWrapper
          key={`${employeeId}-${formatDate(date, 'yyyy-MM-dd')}`}
          color={theme.color.darkGrey}
          isCurrentDate={currentDateMarking}
        />
      );
    }

    if (!isWeekend) {
      if (marking) {
        return getMarking(marking);
      } else {
        if (invalidContract) {
          return (
            <DayCellWrapper
              key={`${employeeId}-${formatDate(date, 'yyyy-MM-dd')}`}
              color={theme.color.darkGrey}
              isCurrentDate={currentDateMarking}
            />
          );
        } else {
          return (
            <DayCellWrapper
              key={`${employeeId}-${formatDate(date, 'yyyy-MM-dd')}`}
              isCurrentDate={currentDateMarking}
            />
          );
        }
      }
    } else {
      if (marking && marking['badges']) {
        const overtime = _.find(
          marking['badges'],
          badge => badge['type'] === ATTENDANCE_TYPES.OVERTIME.key,
        );

        if (overtime) {
          return getMarking(marking);
        }
      }

      if (invalidContract) {
        return (
          <DayCellWrapper
            key={`${employeeId}-${formatDate(date, 'yyyy-MM-dd')}`}
            color={theme.color.darkGrey}
            isCurrentDate={currentDateMarking}
          />
        );
      } else {
        return (
          <DayCellWrapper
            key={`${employeeId}-${formatDate(date, 'yyyy-MM-dd')}`}
            weekend
            isCurrentDate={currentDateMarking}
          />
        );
      }
    }
  };

  const isWorkingDay = (date: Date, attendanceData: CustomDayMarking) => {
    const isWeekend = isDateInWeekend(date);
    const holidayData = holidayMarkings[formatDate(date, 'yyyy-MM-dd')];
    const rejectedBadges = attendanceData?.badges?.filter(
      badge => badge.status === ATTENDANCE_STATUS.REJECTED,
    );

    return (
      (!attendanceData || (rejectedBadges && rejectedBadges?.length > 0)) &&
      !holidayData &&
      !isWeekend
    );
  };

  const calculateTotalWorkingDays = (
    dates: Date[],
    employeeId: number,
    contractEndDate?: Date,
    contractStartDate?: Date,
  ) => {
    const employeeAttendanceData = attendanceMarkings[employeeId];

    return dates.reduce((workingDaysCount, date) => {
      let invalidContract = false;
      const attendanceData =
        employeeAttendanceData?.[formatDate(date, 'yyyy-MM-dd')];

      const isFutureDate = isAfter(date, new Date());

      if (contractEndDate) {
        invalidContract = isAfter(date, contractEndDate);
      }

      if (!invalidContract && contractStartDate) {
        invalidContract = isBefore(date, contractStartDate);
      }

      if (
        (isWorkingDay(date, attendanceData) ||
          hasOvertimeWithoutWorkFromHome(attendanceData?.attendances)) &&
        !isFutureDate &&
        !invalidContract
      ) {
        return workingDaysCount + 1;
      }

      return workingDaysCount;
    }, 0);
  };

  const hasOvertimeWithoutWorkFromHome = (
    attendances: Attendance[] | undefined,
  ): boolean => {
    if (attendances) {
      const overtimePresent = attendances.some(
        attendance => attendance.eventType === ATTENDANCE_TYPES.OVERTIME.key,
      );
      const workFromHomePresent = attendances.some(
        attendance =>
          attendance.eventType === ATTENDANCE_TYPES.WORK_FROM_HOME.key,
      );

      return overtimePresent && !workFromHomePresent;
    }

    return false;
  };

  const onPrevPress = () => {
    const firstVisibleDay = visibleDays[0];
    let daysToDisplay: Date[] = [];

    if (attendanceView === ATTENDANCE_TABLE_VIEWS.MONTHLY) {
      daysToDisplay = getDatesBetweenDays(
        startOfMonth(sub(parseDate(visibleMonths[0]), { months: 1 })),
        endOfMonth(sub(parseDate(visibleMonths[0]), { months: 1 })),
      ) as Date[];
    } else {
      daysToDisplay = getDatesBetweenDays(
        sub(firstVisibleDay, { days: 7 }),
        sub(firstVisibleDay, { days: 1 }),
      ) as Date[];
    }

    setVisibleDays(daysToDisplay);
  };

  const onNextPress = () => {
    const lastVisibleDate = _.last(visibleDays);
    let daysToDisplay: Date[] = [];

    if (attendanceView === ATTENDANCE_TABLE_VIEWS.MONTHLY) {
      daysToDisplay = getDatesBetweenDays(
        startOfMonth(add(parseDate(visibleMonths[0]), { months: 1 })),
        endOfMonth(add(parseDate(visibleMonths[0]), { months: 1 })),
      ) as Date[];
    } else {
      if (lastVisibleDate) {
        daysToDisplay = getDatesBetweenDays(
          add(lastVisibleDate, { days: 1 }),
          add(lastVisibleDate, { days: 7 }),
        ) as Date[];
      }
    }

    setVisibleDays(daysToDisplay);
  };

  const gotoToday = () => {
    const today = new Date();
    let daysToDisplay: Date[] = [];

    if (attendanceView === ATTENDANCE_TABLE_VIEWS.MONTHLY) {
      daysToDisplay = getDatesBetweenDays(
        startOfMonth(today),
        endOfMonth(today),
      ) as Date[];
    } else {
      daysToDisplay = getDatesBetweenDays(
        startOfWeek(today),
        endOfWeek(today),
      ) as Date[];
    }

    setVisibleDays(daysToDisplay);
  };

  const displayVisibleMonths = () => {
    let monthName = '';
    let areMonthsInSameYear = false;

    if (visibleMonths.length > 1) {
      visibleMonths.every((month: string, index: number) => {
        if (
          visibleMonths[index + 1] &&
          isSameYear(parseDate(month), parseDate(visibleMonths[index + 1]))
        ) {
          areMonthsInSameYear = true;
          return false;
        }
      });
    } else {
      areMonthsInSameYear = true;
    }

    if (visibleMonths.length > 1) {
      if (areMonthsInSameYear) {
        monthName =
          formatDate(parseDate(visibleMonths[0]), 'MMM') +
          '/' +
          formatDate(parseDate(visibleMonths[1]), 'MMM yyyy');
      } else {
        monthName =
          formatDate(parseDate(visibleMonths[0]), 'MMM yyyy') +
          '/' +
          formatDate(parseDate(visibleMonths[1]), 'MMM yyyy');
      }
    } else if (visibleMonths.length === 1) {
      monthName = formatDate(parseDate(visibleMonths[0]), 'MMMM yyyy');
    }

    return monthName;
  };

  const getAttendanceTable = () => {
    return (
      <DataTable
        style={{
          minWidth: Platform.OS === 'web' ? dataTableWidth : 'auto',
          height: Platform.OS !== 'web' && tableHeight ? tableHeight : 'auto',
          flex: 1,
        }}>
        <DataTable.Row
          style={{
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderBottomColor: theme.color.transparentBlack2,
            borderColor: theme.color.transparentBlack2,
            paddingLeft: 0,
            paddingRight: 0,
            minHeight: 35,
          }}>
          <DataTable.Cell
            style={{
              borderRightWidth: 1,
              borderLeftWidth: 1,
              borderColor: theme.color.transparentBlack2,
              minWidth: 90,
              paddingLeft: 10,
            }}>
            <EmployeeHeading>Employee</EmployeeHeading>
          </DataTable.Cell>
          {visibleDays.map(date => {
            let backgroundColor = 'transparent';
            const isWeekend = isDateInWeekend(date);
            const holidayMarking =
              holidayMarkings[formatDate(date, 'yyyy-MM-dd')];
            const isCurrentDate = isToday(date);

            if (holidayMarking && !isWeekend) {
              backgroundColor = theme.color.darkGrey;
            } else if (isWeekend) {
              backgroundColor = theme.color.lightGrey3;
            }

            return (
              <DataTable.Cell
                key={`${formatDate(date, 'yyyy-MM-dd')}`}
                style={{
                  borderRightWidth: 1,
                  borderColor: theme.color.transparentBlack2,
                  backgroundColor,
                  minWidth: Platform.OS === 'web' ? 35 : 10,
                  justifyContent: 'center',
                  ...(isCurrentDate && {
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    borderColor: theme.color.primary,
                  }),
                }}>
                <MonthDayContainer isCurrentDate={isCurrentDate}>
                  <MonthDay
                    weekend={isWeekend || holidayMarking}
                    isCurrentDate={isCurrentDate}>
                    {formatDate(date, 'dd')}
                  </MonthDay>
                </MonthDayContainer>
              </DataTable.Cell>
            );
          })}
          {Platform.OS === 'web' && config.features.showWFO && (
            <WorkingDayCell>
              <WDHeading>WFO</WDHeading>
            </WorkingDayCell>
          )}
        </DataTable.Row>

        {!loading ? (
          <DataRows scrollEnabled={enableScrolling}>
            {filteredEmployees.map(employee => {
              let contractEndDate: Date | undefined;
              let contractStartDate: Date | undefined;

              const latestContract = getLatestContract(employee.contracts);
              const oldestContract = getOldestContract(employee.contracts);

              if (latestContract) {
                contractEndDate = parseDate(latestContract.end);
              }

              if (oldestContract) {
                contractStartDate = parseDate(oldestContract.start);
              }

              return (
                <DataTable.Row
                  key={employee.employeeId}
                  style={{
                    borderBottomWidth: 1,
                    borderBottomColor: theme.color.lightBlack1,
                    borderColor: theme.color.lightBlack1,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    minHeight: 35,
                    backgroundColor:
                      highlightedRow === employee.employeeId
                        ? theme.color.lightGrey
                        : user && user.employeeId === employee.employeeId
                        ? theme.color.lightMint
                        : theme.color.white,
                  }}>
                  <DataTable.Cell
                    style={{
                      borderRightWidth: 1,
                      borderLeftWidth: 1,
                      borderColor: theme.color.transparentBlack2,
                      minWidth: 90,
                      paddingLeft: 10,
                      zIndex: 1000,
                    }}>
                    <Popable
                      content={employee?.fullName}
                      action={theme.size.isLarge ? 'hover' : 'press'}
                      position="right"
                      strictPosition
                      onAction={() => setHighlightedRow(employee.employeeId)}
                      style={{
                        width: Platform.OS === 'web' ? 'max-content' : 150,
                      }}>
                      <EmployeeName>{employee.givenName}</EmployeeName>
                    </Popable>
                  </DataTable.Cell>
                  {visibleDays.map(day => {
                    return getDayMarkings(
                      day,
                      employee.employeeId,
                      contractEndDate,
                      contractStartDate,
                    );
                  })}
                  {Platform.OS === 'web' && config.features.showWFO && (
                    <WorkingDayCell>
                      <MonthDay>
                        {calculateTotalWorkingDays(
                          visibleDays,
                          employee.employeeId,
                          contractEndDate,
                          contractStartDate,
                        )}
                      </MonthDay>
                    </WorkingDayCell>
                  )}
                </DataTable.Row>
              );
            })}
          </DataRows>
        ) : (
          <LoaderWrapper>
            <ActivityIndicator color={theme.color.primary} size={'large'} />
          </LoaderWrapper>
        )}
      </DataTable>
    );
  };

  const getTableHeader = () => {
    return (
      <TableHeader>
        <HeaderRow>
          <TitleWrapper>
            <TableTitle>{displayVisibleMonths()}</TableTitle>
            <SearchWrapper>
              {theme.size.isLarge && (
                <SearchBox>
                  <SearchTextField
                    onChangeText={setSearchText}
                    placeholder={t('app.attendance.search-by-employee') ?? ''}
                    value={searchText}
                  />
                </SearchBox>
              )}
            </SearchWrapper>
          </TitleWrapper>
          <Navigation>
            <NavigationButton onPress={onPrevPress}>
              <FeatherIcons name={'chevron-left'} size={22} color={'white'} />
            </NavigationButton>
            <NavigationButton onPress={onNextPress}>
              <FeatherIcons name={'chevron-right'} size={22} color={'white'} />
            </NavigationButton>
            <NavigationButton onPress={gotoToday}>
              <NavigationButtonText>
                {t('app.attendance.today')}
              </NavigationButtonText>
            </NavigationButton>
          </Navigation>
        </HeaderRow>
        {!theme.size.isLarge && (
          <SearchBox>
            <SearchTextField
              onChangeText={setSearchText}
              placeholder={t('app.attendance.search-by-employee') ?? ''}
              placeholderTextColor={theme.color.text}
              cursorColor={theme.color.text}
              value={searchText}
            />
          </SearchBox>
        )}
      </TableHeader>
    );
  };

  if (Platform.OS === 'web') {
    return (
      <Container
        onLayout={e => {
          setDataTableWidth(e.nativeEvent.layout.width);
        }}>
        {getTableHeader()}
        <TableWrapper horizontal>
          {visibleDays.length > 0 && getAttendanceTable()}
        </TableWrapper>
      </Container>
    );
  } else {
    return (
      <Container>
        {getTableHeader()}
        {visibleDays.length > 0 && getAttendanceTable()}
      </Container>
    );
  }
};

export default AttendanceTable;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Loan, LoanRequestConfig } from 'core';

interface MyLoanState {
  loans: Loan[];
  loanRequestConfig?: LoanRequestConfig;
}

const initialState: MyLoanState = {
  loans: [],
};

export const myLoanSlice = createSlice({
  name: 'myLoans',
  initialState,
  reducers: {
    setLoans(state, action: PayloadAction<Loan[]>) {
      state.loans = action.payload;
    },
    setLoanRequestConfig(state, action: PayloadAction<LoanRequestConfig>) {
      state.loanRequestConfig = action.payload;
    },
  },
});

export const { setLoans, setLoanRequestConfig } = myLoanSlice.actions;
export default myLoanSlice.reducer;

import styled from 'styled-components/native';

export const LoanRequestContainer = styled.View`
  align-items: center;
`;

export const NativeContainer = styled.View`
  background-color: ${props => props.theme.color.white};
  padding: 15px 10px;
  margin: 15px;
  border-radius: 10px;
`;

export const FormWrapper = styled.View`
  margin-top: 30px;
  background-color: ${props => props.theme.color.white};
  border-width: 1px;
  border-color: ${props => props.theme.color.transparentBlack2};
  padding: 30px;
  min-width: ${props => (props.theme.size.isLarge ? '60%' : '90%')};
  max-width: 300px;
  border-radius: 10;
  shadow-radius: 10;
  shadow-opacity: 0.1;
  shadow-color: ${props => props.theme.color.lightBlack};
  shadow-offset: -2px 4px;
`;

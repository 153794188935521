export enum ThemeType {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export interface User {
  employeeId?: number;
  givenName?: string;
  middleName?: string;
  surname?: string;
  email: string;
  isManager?: boolean;
}

export interface Employee {
  employeeId: number;
  fullName: string;
  givenName: string;
  contracts?: Contract[];
}

type AttendanceType = {
  code: string;
};

export type Menu = {
  key: string;
  title: string;
  route: string;
  children?: Menu[];
};

export type AttendanceEventType =
  | 'OVERTIME'
  | 'WORK_FROM_HOME'
  | 'LEAVE'
  | 'COMPENSATION_LEAVE';

export type Attendance = {
  id: number;
  description: string | null;
  employeeId: number;
  end: string;
  eventType: AttendanceEventType;
  leaveType?: string;
  leaveTypeId: number | null;
  rejectionReason?: string;
  start: string;
  status: number;
  type: AttendanceType;
  typeId: number;
  overtimeId: number;
  name?: string;
  isValid?: boolean;
  createdAt?: string;
};

export type Contract = {
  id: number;
  end: string;
  start: string;
};

export type EmployeeAttendance = {
  employeeId: number;
  fullName: string;
  givenName: string;
  attendances: Attendance[];
  contracts: Contract[];
};

export type Holiday = {
  countryCode: string;
  countryName: string;
  end: string;
  name: string;
  start: string;
  id: number;
};

export type Leave = {
  id: number;
  code: string;
  name: string;
  isAllowance: boolean;
};

export type EmployeeOvertime = {
  id: number;
  start: string;
  end: string;
  status: boolean;
};

interface GroupCompany {
  code: string;
  name: string;
}

export type EmployeeEvent = {
  allDay: boolean;
  bonus: number | null;
  currency: string;
  date: string;
  dearnessAllowance: number;
  description: string;
  employmentEventTypeId: number;
  foodAllowance: number | null;
  gradeId: number;
  gradeName?: string;
  groupCompanyId: number;
  groupCompany: GroupCompany;
  id: number;
  salary?: number;
  status: boolean;
  title: string;
  travelAllowance: number;
  typeName: string;
};

export type EmployeeProfile = {
  bankAccount?: string | null;
  bankBranch?: string | null;
  bankName?: string | null;
  citAmount?: number | null;
  citNumber?: string | null;
  citizenshipIssueDate?: string | null;
  citizenshipNumber?: string | null;
  currentAddress?: string | null;
  dob?: string | null;
  email: string;
  emergencyContactName?: string | null;
  emergencyContactPhone?: string | null;
  emergencyContactRelationshipId?: number | null;
  emergencyContactRelationship?: string | null;
  gender: number;
  givenName: string;
  isSignatory?: boolean | null;
  surname: string;
  maritalStatus?: number | null;
  middleName?: string | null;
  mobile?: string | null;
  panNumber?: string | null;
  personalEmail?: string | null;
  registeredAddress?: string | null;
  ssfExempt?: boolean | null;
  ssfNumber?: string | null;
  useMaxCitAmount?: boolean | null;
  assignable?: boolean | null;
  employeeNumber: number;
  salary?: number | null;
  dearnessAllowance?: number | null;
  joinedAt?: string | null;
  isOnProbation?: boolean;
};

export type PaySlip = {
  id: number;
  documentUrl: string;
  payslipAt: string;
  status: string;
};

export type PaySlipDetail = {
  payslipYears: number[];
  payslips: PaySlip[];
};

interface LeaveSummary {
  start?: number;
  earned?: number;
  used: number;
  end?: number;
  leaveTypeId: number;
  manuallyAdjusted?: number;
}

export interface AttendanceSummaries {
  date: string;
  leaveSummary?: LeaveSummary[];
  nonAllowanceLeaveSummary?: LeaveSummary[];
  stats: {
    totalWorkingDays: number;
    totalWorkedDays: number;
    past_12MonthsAverage: number;
  };
}

type DaysType = 'working' | 'calendar';
export interface LeaveRule {
  id: number;
  leaveType: {
    id: number;
    code: string;
    name: string;
  };
  max: number;
  min: number;
  noticePeriod: number;
  daysType: DaysType;
}

export type FileType = 'pdf' | 'text' | 'image' | 'unsupported';

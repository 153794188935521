import { createApi } from '@reduxjs/toolkit/query/react';

import config from '@config';
import { EmployeeProfile } from '@types';

import axiosBaseQuery from './../apis/axiosBaseQuery';

enum ProfileEnum {
  ReducerPath = 'profileApi',
  TagType = 'profile',
}

const profileApi = createApi({
  reducerPath: ProfileEnum.ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiBaseUrl,
  }),
  keepUnusedDataFor: 30,
  tagTypes: [ProfileEnum.TagType],
  endpoints: build => ({
    getProfile: build.query<EmployeeProfile, {}>({
      query: args => ({
        url: `/employee-profile`,
        method: 'GET',
      }),
      transformResponse: (response: EmployeeProfile) => response,
      providesTags: [ProfileEnum.TagType],
    }),
  }),
});

export const { useLazyGetProfileQuery } = profileApi;
export default profileApi;

import type { LoanRequestConfig } from 'core';
import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

import { getEmployeeSalary, getJoiningDate } from '@redux/profile/selectors';
import { RootState } from '@redux/store';
import { getLoggedInUser } from '@redux/auth/selectors';
import { getLoanRequestConfig, getMyLoans } from '@redux/loans/selectors';
import { setLoanRequestConfig } from '@redux/loans/slice';

import RequestLoanScreen from './index.component';

const mapStateToProps = (state: RootState) => ({
  salary: getEmployeeSalary(state),
  joinedAt: getJoiningDate(state),
  user: getLoggedInUser(state),
  loanRequestConfig: getLoanRequestConfig(state),
  myLoans: getMyLoans(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLoanRequestConfig: (config: LoanRequestConfig) => {
    dispatch(setLoanRequestConfig(config));
  },
});

const ConnectedRequestLoanScreen = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestLoanScreen);

export default ConnectedRequestLoanScreen;

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { TouchableWithoutFeedback } from 'react-native';

import { formatDate, getMonthName } from '@utils/dateTime';

import {
  MonthPickerWrapper,
  MonthPickerHeader,
  HeaderMonth,
  HeaderYear,
  MonthPickerBody,
  MonthPickerScrollableWrapper,
  YearList,
  YearItem,
  MonthList,
  MonthItem,
  MonthText,
  HeaderSection,
  TodayButton,
  TodayLabel,
} from './index.style';
import { isSameYear } from 'date-fns';

interface IProps {
  minDate: Date;
  maxDate: Date;
  onDatePicked?: (date: Date) => void;
  onYearSelect?: (date: any) => void;
  current?: Date;
  allowJumpToToday?: boolean;
}

const MonthPicker: React.FC<IProps> = props => {
  const { allowJumpToToday, current, minDate, maxDate, onDatePicked } = props;
  const [showYearList, setShowYearList] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(current ?? new Date());
  const [months, setMonths] = useState(_.range(0, 12, 1));
  const years = _.range(minDate.getFullYear(), maxDate.getFullYear() + 1, 1);

  const onYearSelected = (year: number) => {
    setSelectedDate(
      new Date(year, selectedDate.getMonth(), selectedDate.getDay()),
    );
    setShowYearList(false);
  };

  const onMonthSelected = (month: number) => {
    setSelectedDate(
      new Date(selectedDate.getFullYear(), month, selectedDate.getDay()),
    );

    if (onDatePicked) {
      onDatePicked(
        new Date(selectedDate.getFullYear(), month, selectedDate.getDay()),
      );
    }
  };

  const onTodaySelected = () => {
    setSelectedDate(new Date());

    if (onDatePicked) {
      onDatePicked(new Date());
    }
  };

  useEffect(() => {
    if (
      isSameYear(selectedDate, minDate) &&
      isSameYear(selectedDate, maxDate)
    ) {
      setMonths(_.range(minDate.getMonth(), maxDate.getMonth(), 1));
    } else if (isSameYear(selectedDate, minDate)) {
      setMonths(_.range(minDate.getMonth(), 12, 1));
    } else if (isSameYear(selectedDate, maxDate)) {
      setMonths(_.range(0, maxDate.getMonth(), 1));
    } else {
      setMonths(_.range(0, 12, 1));
    }
  }, [selectedDate]);

  return (
    <TouchableWithoutFeedback>
      <MonthPickerWrapper>
        <MonthPickerHeader>
          <HeaderSection>
            <HeaderYear onPress={() => setShowYearList(true)}>
              {formatDate(selectedDate, 'yyyy')}
            </HeaderYear>
            <HeaderMonth onPress={() => setShowYearList(false)}>
              {formatDate(selectedDate, 'MMMM')}
            </HeaderMonth>
          </HeaderSection>
          {allowJumpToToday && (
            <HeaderSection>
              <TodayButton onPress={onTodaySelected}>
                <TodayLabel>Today</TodayLabel>
              </TodayButton>
            </HeaderSection>
          )}
        </MonthPickerHeader>
        <MonthPickerBody>
          <MonthPickerScrollableWrapper>
            {showYearList ? (
              <YearList>
                {years.map((year, index) => (
                  <YearItem
                    key={year + index}
                    onPress={() => {
                      onYearSelected(year);
                    }}
                    selected={selectedDate.getFullYear() === year}>
                    {year}
                  </YearItem>
                ))}
              </YearList>
            ) : (
              <MonthList>
                {months.map((month, index) => {
                  return (
                    <MonthItem
                      key={month + index}
                      onPress={() => {
                        onMonthSelected(month);
                      }}>
                      <MonthText selected={selectedDate.getMonth() === month}>
                        {getMonthName(month, 'MMM')}
                      </MonthText>
                    </MonthItem>
                  );
                })}
              </MonthList>
            )}
          </MonthPickerScrollableWrapper>
        </MonthPickerBody>
      </MonthPickerWrapper>
    </TouchableWithoutFeedback>
  );
};

export default MonthPicker;

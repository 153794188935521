import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { NavigationContainer, Theme } from '@react-navigation/native';

import config from '@config';
import Loading from '@components/Loading';
import { RootState } from '@redux/store';
import { ThemeContext } from '@theme';
import { ThemeType } from '@types';
import { darkColor, lightColor } from '@theme/colors';

import AppNavigator from './AppNavigator';
import ROUTES from './routes';
import AuthNavigator from './navigators/Auth';

const linking = {
  prefixes: [`${config.deepLinkURL}`],
  config: {
    screens: {
      [ROUTES.APP_LOGIN]: '/login',
      [ROUTES.APP_GOOGLE_AUTH]: '/auth/callback/google',
      [ROUTES.APP_MY_ATTENDANCE]: '/my-attendance',
      [ROUTES.APP_GENERAL_ATTENDANCE]: '/general-attendance',
      [ROUTES.APP_TIMELINE]: '/timeline',
      [ROUTES.APP_CREATE_ATTENDANCE]: '/request/leave',
      [ROUTES.APP_PROFILE]: '/profile',
      [ROUTES.APP_PENDING_LEAVE]: '/pending-approval',
      [ROUTES.APP_LOANS]: '/loans',
      [ROUTES.APP_LOAN_DETAILS]: '/loan-details',
      [ROUTES.APP_REQUEST_LOAN]: '/request-loan',
      [ROUTES.APP_PAYSLIPS]: '/document/payslips',
      [ROUTES.APP_CONTRACTS]: '/contracts',
      [ROUTES.APP_PRIVACY_POLICY]: '/privacy-policy',
      [ROUTES.APP_HOLIDAYS]: '/holidays',
      [ROUTES.APP_LEAVE_SUMMARY]: '/leave-summary',
      [ROUTES.APP_OTHER_DOCUMENTS]: '/document/others',
      [ROUTES.APP_DASHBOARD]: '',
    },
  },
};

const Navigator = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const isAuthLoading = useSelector((state: RootState) => state.auth.loading);
  const { theme } = useContext(ThemeContext);

  const DarkTheme: Theme = {
    dark: true,
    colors: {
      primary: darkColor.primary,
      background: darkColor.background,
      card: darkColor.white,
      text: darkColor.text,
      border: darkColor.transparentBlack1,
      notification: darkColor.white,
    },
  };

  const LightTheme: Theme = {
    dark: false,
    colors: {
      primary: lightColor.primary,
      background: lightColor.background,
      card: lightColor.white,
      text: lightColor.text,
      border: lightColor.transparentBlack1,
      notification: lightColor.white,
    },
  };

  if (isAuthLoading) return <Loading />;

  return (
    <NavigationContainer
      linking={linking}
      theme={theme === ThemeType.DARK ? DarkTheme : LightTheme}>
      {user ? <AppNavigator /> : <AuthNavigator />}
    </NavigationContainer>
  );
};

export default Navigator;

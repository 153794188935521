import { Dimensions, Platform } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.View`
  margin: 10px;
  flex: 1;
`;

export const FlatListContainer = styled.View`
  margin-left: 10px;
  margin-right: 10px;
`;

export const HeaderDateText = styled.Text`
  font-size: 13px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const HeaderText = styled.Text`
  font-size: 14px;
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.fonts.MontserratBold};
  font-weight: ${Platform.OS === 'web' ? 'bold' : '500'};
`;

export const CountryText = styled.Text`
  font-size: 13px;
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const DateContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
`;

export const HeaderDateContainer = styled.View`
  flex-direction: row;
`;

export const WebContainer = styled.View`
  margin-top: 10px;
`;

export const Navigation = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const NavigationButton = styled.TouchableOpacity`
  background-color: ${props => props.theme.color.primary};
  margin: 0 2.5px;
  padding: 5px 10px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
`;

export const NavigationButtonText = styled.Text`
  color: ${props => props.theme.color.alwaysWhite};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const PageHeadingText = styled.Text`
  font-size: 20px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const PageHeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin: 0 15px 15px;
`;

import { createApi } from '@reduxjs/toolkit/query/react';

import config from '@config';
import { Attendance, EmployeeEvent } from '@types';

import axiosBaseQuery from './axiosBaseQuery';

enum EventsEnum {
  ReducerPath = 'eventsApi',
  TagType = 'events',
}

const eventsApi = createApi({
  reducerPath: EventsEnum.ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiBaseUrl,
  }),
  keepUnusedDataFor: 30,
  tagTypes: [EventsEnum.TagType],
  endpoints: build => ({
    getEmployeeEvents: build.query<EmployeeEvent[], void>({
      query: () => ({
        url: `/employee-events`,
        method: 'GET',
      }),
      transformResponse: (response: EmployeeEvent[]) => response,
      providesTags: [EventsEnum.TagType],
    }),
    getEventById: build.query<
      Attendance,
      { id: number; start: string; end: string }
    >({
      query: args => ({
        url: `/event`,
        method: 'GET',
        params: { id: args.id, start: args.start, end: args.end },
      }),
      transformResponse: (response: Attendance) => response,
      providesTags: [EventsEnum.TagType],
    }),
  }),
});

export const { useGetEmployeeEventsQuery, useLazyGetEventByIdQuery } =
  eventsApi;
export default eventsApi;

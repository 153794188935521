import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

import { RootState } from '../../redux/store';

import AppearanceSwitcher from './index.component';
import { getAppearanceModalVisibility } from '../../redux/profile/selectors';
import { updateAppearanceSwitcherVisibility } from '../../redux/profile/slice';

const mapStateToProps = (state: RootState) => ({
  isVisible: getAppearanceModalVisibility(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateAppearanceSwitcherVisibility: (visibilityStatus: boolean) => {
    dispatch(updateAppearanceSwitcherVisibility(visibilityStatus));
  },
});

const ConnectedAppearanceSwitcher = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppearanceSwitcher);

export default ConnectedAppearanceSwitcher;

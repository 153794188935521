import styled from 'styled-components/native';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { DefaultTheme } from 'styled-components';

interface CustomStyleProps {
  disabled?: boolean;
  today?: boolean;
  backgroundColor?: string;
  isSmallDevice?: boolean;
  color?: string;
  theme: DefaultTheme;
}

interface AttendanceStatusProps {
  color: string;
}

export const DayWrapper = styled.TouchableOpacity.attrs({
  activeOpacity: 1,
})`
  border: 1px solid ${props => props.theme.color.transparentBlack1};
  height: ${(props: CustomStyleProps) =>
    props.isSmallDevice ? '70px' : '110px'};
  width: 100%;
  padding: 5px 0px;
  background-color: ${(props: CustomStyleProps) =>
    props.backgroundColor ?? props.theme.color.white};
`;

export const BadgesContainer = styled.View`
  padding: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const Badge = styled.View`
  height: 32px;
  width: 32px;
  background-color: ${(props: CustomStyleProps) =>
    props.backgroundColor ?? props.theme.color.primary};
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  margin: 0 5px;
`;

export const BadgeText = styled.Text`
  color: ${props => props.theme.color.white};
  margin-top: -2px;
  font-family: ${props => props.theme.fonts.MontserratBold};
  font-weight: 600;
`;

export const DateText = styled.Text`
  color: ${(props: CustomStyleProps) =>
    props.disabled
      ? props.theme.color.lightGrey2
      : props.today
      ? props.theme.color.primary
      : props.theme.color.text};
  text-align: center;
  margin-bottom: 5px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  font-size: 14px;
`;

export const StatusMarkingWrapper = styled.View`
  position: absolute;
  right: 3px;
  bottom: 3px;
  flex-direction: row;
`;

export const StatusDot = styled.View`
  height: 8px;
  width: 8px;
  border-radius: 6px;
  background-color: ${(props: AttendanceStatusProps) => props.color};
`;

export const EventWrapper = styled.View`
  border: 1px solid ${props => props.theme.color.transparentBlack2};
  background-color: ${props => props.theme.color.white};
  padding: 5px 10px;
  border-radius: 5px;
`;

export const StatusIndicator = styled.View`
  position: absolute;
  height: 5px;
  width: 5px;
  background-color: ${(props: CustomStyleProps) => props.color};
  border-radius: 9px;
  right: 6px;
  top: 6px;
`;

export const IndividualEvent = styled.View`
  margin: 5px 0;
`;

export const EventHeader = styled.View`
  flex-direction: row;
  min-width: 250px;
  justify-content: space-between;
  align-items: center;
`;

export const EventDetails = styled.View``;

export const EventActions = styled.View`
  flex-direction: row;
`;

export const ActionButton = styled.TouchableOpacity`
  margin-left: 10px;
  border: 1px solid ${(props: AttendanceStatusProps) => props.color};
  padding: 5px 8px;
  border-radius: 5px;
`;

export const LeaveDates = styled.Text`
  font-size: 13px;
  font-family: ${props => props.theme.fonts.MontserratBold};
  font-weight: 600;
  color: ${props => props.theme.color.text};
`;

export const TitleWrapper = styled.View`
  margin: 5px 0;
`;

export const StatusBadge = styled.View`
  background-color: ${(props: AttendanceStatusProps) => props.color};
  padding: 3px 8px;
  border-radius: 10px;
  text-align: center;
  margin-top: 2px;
`;

export const StatusText = styled.Text`
  color: ${props => props.theme.color.white};
  font-size: 11px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const Title = styled.Text`
  font-size: 12px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const DescriptionSection = styled.View`
  margin: 12px 0 0 0;
`;

export const DescriptionHeader = styled.Text`
  font-family: ${props => props.theme.fonts.MontserratBold};
  color: ${props => props.theme.color.primary};
  font-weight: 600;
`;

export const DescriptionText = styled.Text`
  font-size: 14px;
  color: ${props => props.theme.color.grey};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const HolidayWrapper = styled.View`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

export const HolidayTitle = styled.Text`
  color: ${props => props.theme.color.grey};
  font-size: 11px;
  text-align: center;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const ActionIcon = styled(FeatherIcons)`
  color: ${(props: AttendanceStatusProps) => props.color};
  font-size: 18px;
`;

export const TooltipContainer = styled.View`
  width: 100%;
`;

import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { TextInputChangeEventData, NativeSyntheticEvent } from 'react-native';
import RNDropDownPicker, {
  ItemType,
  ValueType,
} from 'react-native-dropdown-picker';
import { useTheme } from 'styled-components/native';

import { ErrorMessage, InputLabel, FieldWrapper } from '../commonStyles';

interface IProps {
  control: Control<any, any>;
  name: string;
  onChange?: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void;
  setDropDownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  items: ItemType<ValueType>[];
  showLabel?: boolean;
  label?: string | null;
  error?: FieldError;
  placeholder?: string;
  open: boolean;
  disabled?: boolean;
}

const DropDownPicker: React.FC<IProps> = ({
  control,
  name,
  onChange: onFieldChange,
  open,
  items,
  error,
  label,
  disabled,
  setDropDownOpen,
  showLabel = true,
  placeholder,
}) => {
  const theme = useTheme();
  return (
    <FieldWrapper open={open}>
      {label && showLabel && <InputLabel>{label}</InputLabel>}
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <RNDropDownPicker
              open={open}
              closeOnBackPressed={true}
              value={value}
              items={items}
              setValue={() => {}}
              setOpen={setDropDownOpen}
              onSelectItem={item => {
                onChange(item.value);
                if (onFieldChange) {
                  onFieldChange(item.value);
                }
              }}
              dropDownContainerStyle={{
                borderColor: theme.color.lightWhite,
                backgroundColor: theme.color.white,
              }}
              style={{
                borderColor: error ? theme.color.red : theme.color.lightWhite,
                borderWidth: error ? 2 : 1,
                minHeight: 40,
                backgroundColor: theme.color.white,
              }}
              textStyle={{
                fontFamily: theme.fonts.MontserratRegular,
                color: theme.color.text,
              }}
              listChildLabelStyle={{
                fontFamily: theme.fonts.MontserratRegular,
              }}
              placeholder={placeholder}
              theme={theme.name}
              disabledItemLabelStyle={{
                opacity: 0.5,
              }}
              disabled={disabled}
              disabledStyle={{
                backgroundColor: theme.color.transparentBlack2,
              }}
            />
          );
        }}
        name={name}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </FieldWrapper>
  );
};

export default DropDownPicker;

import React from 'react';
import {
  ActivityIndicator,
  StyleProp,
  ViewProps,
  ViewStyle,
} from 'react-native';
import { useTheme } from 'styled-components/native';

import { Button, Text } from './index.style';

interface IProps extends ViewProps {
  label?: string | null;
  onPress: () => void;
  loading?: boolean;
  buttonStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
}

const BasicButton: React.FC<IProps> = ({
  buttonStyle,
  children,
  label,
  loading,
  onPress,
  disabled,
}) => {
  const theme = useTheme();

  return (
    <Button onPress={onPress} style={[buttonStyle]} disabled={disabled}>
      {loading ? (
        <ActivityIndicator color={theme.color.alwaysWhite} size={'small'} />
      ) : label !== null ? (
        <Text>{label}</Text>
      ) : (
        children
      )}
    </Button>
  );
};

export default BasicButton;

import styled from 'styled-components/native';

export const InputContainer = styled.View`
  margin-bottom: 15px;
`;

export const InputLabel = styled.Text`
  padding: 0 10px 5px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

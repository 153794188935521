import React from 'react';
import { ActivityIndicator, StyleProp, ViewStyle } from 'react-native';
import { useTheme } from 'styled-components/native';

import { LoadingContainer } from './index.style';

export interface LoadingPageProperties {
  color?: string;
  size?: number | 'small' | 'large';
  style?: StyleProp<ViewStyle> | undefined;
}

const Loading: React.FC<LoadingPageProperties> = props => {
  const { color, size } = props;
  const theme = useTheme();

  return (
    <LoadingContainer style={[props.style]}>
      <ActivityIndicator
        color={color ?? theme.color.primary}
        size={size || 'large'}
      />
    </LoadingContainer>
  );
};

export default Loading;

import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.View`
  padding: 15px 10px;
  padding-bottom: ${Platform.OS === 'web' ? '10px' : 0};
`;

export const EventWrapper = styled.View`
  padding: 8px 0;
  flex-direction: row;
`;

export const HolidayIndicator = styled.View`
  height: 35px;
  width: 35px;
  background-color: ${props => props.theme.color.grey};
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export const HolidayCode = styled.Text`
  font-size: 16px;
  color: ${props => props.theme.color.white};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const HolidayDetailsWrapper = styled.View``;

export const HolidayLabelWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const EventDate = styled.Text`
  font-size: 13px;
  font-family: ${props => props.theme.fonts.MontserratBold};
  font-weight: 600;
  color: ${props => props.theme.color.text};
  margin-bottom: 3px;
`;

export const EventType = styled.Text`
  font-size: 14px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
  margin-bottom: 3px;
`;

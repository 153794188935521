import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import {
  KeyboardTypeOptions,
  StyleProp,
  TextStyle,
  TextInputChangeEventData,
  NativeSyntheticEvent,
} from 'react-native';
import { useTheme } from 'styled-components/native';

import {
  ErrorMessage,
  FieldWrapper,
  InputLabel,
  StyledTextInput,
} from '../commonStyles';
import { isNumeric } from '../utils';

interface IProps {
  control: Control<any, any>;
  name: string;
  type?: 'numeric' | 'text';
  keyboardType?: KeyboardTypeOptions;
  onChange?: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void;
  multiline?: boolean;
  editable?: boolean;
  onTouchStart?: () => void;
  style?: StyleProp<TextStyle>;
  showLabel?: boolean;
  label?: string | null;
  error?: FieldError;
  placeholder?: string;
}

const InputField: React.FC<IProps> = ({
  control,
  name,
  type,
  keyboardType,
  onChange: onFieldChange,
  multiline,
  editable,
  error,
  label,
  onTouchStart,
  showLabel = true,
  style,
  placeholder,
}) => {
  const theme = useTheme();

  return (
    <FieldWrapper>
      {label && showLabel && <InputLabel>{label}</InputLabel>}
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          if (type === 'numeric' && (isNumeric(value) || value === '')) {
            value = value !== '' ? value : '';
          }

          return (
            <StyledTextInput
              placeholder={placeholder}
              onBlur={onBlur}
              keyboardType={keyboardType || 'default'}
              onChange={onFieldChange}
              onChangeText={value => {
                if (type === 'numeric') {
                  if (isNumeric(value) || value === '') {
                    onChange(value);
                  }
                } else {
                  onChange(value);
                }
              }}
              selectionColor={theme.color.primary}
              value={value}
              placeholderTextColor={theme.color.lightGrey2}
              multiline={multiline}
              editable={editable}
              autoCorrect={false}
              autoCapitalize={'none'}
              error={error ? true : false}
              onTouchStart={onTouchStart}
              style={style}
            />
          );
        }}
        name={name}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </FieldWrapper>
  );
};

export default InputField;

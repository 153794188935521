import axios, { RawAxiosRequestHeaders } from 'axios';

import config from '@config';

const axiosInstance = axios.create({
  baseURL: config.apiBaseUrl,
});

const get = (
  url: string,
  variable?: {
    [key: string]: string | number | null;
  },
  headers?: RawAxiosRequestHeaders,
) =>
  axiosInstance.get(url, {
    params: variable,
    headers: headers,
    withCredentials: true,
  });

const post = (
  url: string,
  variable?: {
    [key: string]: string | number | null;
  },
  headers?: RawAxiosRequestHeaders,
) =>
  axiosInstance.post(url, variable, {
    headers: headers,
    withCredentials: true,
  });

export { axiosInstance, get, post };

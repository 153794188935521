import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/FontAwesome';

export const TimeContainer = styled.View`
  flex-basis: 25%;
`;

export const TimeText = styled.Text`
  flex-basis: 6%;
  align-items: center;
  align-self: stretch;
  margin-top: 3px;
  font-weight: 600;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratBold};
`;

export const IconContainer = styled.View`
  flex-basis: 6%;
  align-items: center;
  align-self: stretch;
  margin-horizontal: 5%;
`;

export const ItemIcon = styled(Icon)`
  text-align: center;
  width: 30px;
  height: 30px;
  background-color: ${props => props.theme.color.primary};
  padding-top: 6px;
  border-radius: 15px;
  color: ${props => props.theme.color.alwaysWhite};
  font-size: 17px;
  overflow: hidden;
  border-color: ${props => props.theme.color.lightGrey};
`;

export const VerticalLine = styled.View`
  flex: 1;
  width: 1px;
  background-color: ${props => props.theme.color.lightGrey3};
`;

export const EventContainer = styled.View`
  flex-basis: 55%;
  align-items: flex-start;
  background-color: ${props => props.theme.color.white};
  padding: 16px;
  border-radius: 15px;
  margin-bottom: 10px;
`;

export const RowContainer = styled.TouchableOpacity`
  flex-direction: row;
  align-items: flex-start;
  margin-vertical: 5px;
`;

export const TitleText = styled.Text`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.color.lightBlack};
  text-align: left;
  margin-bottom: 5px;
  line-height: 20px;
  margin-top: -10px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const DescriptionText = styled.Text`
  text-align: left;
  font-size: 11px;
  line-height: 18px;
  padding-bottom: 10px;
  color: ${props => props.theme.color.grey};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const TimelineContainer = styled.View`
  flex: 1;
  width: 100%;
`;

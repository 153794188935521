import i18n from '@config/i18n';

const GENDER = {
  FEMALE: 0,
  MALE: 1,
};

const MARITAL_STATUS = {
  SINGLE: 0,
  MARRIED: 1,
  DIVORCED: 2,
  WIDOW: 3,
};

const getGenderFromCode = (code: number) => {
  if (code === GENDER.MALE) return i18n.t('app.gender.male');
  if (code === GENDER.FEMALE) return i18n.t('app.gender.female');

  return '';
};

const getMaritalStatusFromCode = (code: number | null | undefined) => {
  if (code === MARITAL_STATUS.SINGLE)
    return i18n.t('app.marital-status.single');
  if (code === MARITAL_STATUS.MARRIED)
    return i18n.t('app.marital-status.married');
  if (code === MARITAL_STATUS.DIVORCED)
    return i18n.t('app.marital-status.divorced');
  if (code === MARITAL_STATUS.WIDOW) return i18n.t('app.marital-status.widow');

  return '';
};

export { getGenderFromCode, getMaritalStatusFromCode };

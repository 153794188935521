import FeatherIcons from 'react-native-vector-icons/Feather';
import styled, { DefaultTheme } from 'styled-components/native';

interface SelectedProps {
  selected: boolean;
  theme: DefaultTheme;
}

export const Container = styled.View``;

export const Item = styled.Pressable`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 7px 0;
`;

export const Selected = styled.View`
  border: 2px solid ${props => props.theme.color.primary};
  height: 25px;
  width: 25px;
  margin-right: 10px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  background-color: ${(props: SelectedProps) =>
    props.selected ? props.theme.color.primary : props.theme.color.white};
`;

export const SelectedIcon = styled(FeatherIcons).attrs({
  name: 'check',
})`
  font-size: 15px;
  color: ${(props: SelectedProps) =>
    props.selected ? props.theme.color.white : props.theme.color.primary};
`;

export const Label = styled.Text`
  font-family: ${props => props.theme.fonts.MontserratRegular};
  font-size: 15px;
  color: ${props => props.theme.color.text};
`;

import React from 'react';

import { Holiday } from '@types';
import { formatDateRange, parseDate } from '@utils/dateTime';

import {
  HolidayCode,
  HolidayDetailsWrapper,
  HolidayLabelWrapper,
  Container,
  EventDate,
  EventWrapper,
  HolidayIndicator,
  EventType,
} from './index.style';

interface IProps {
  holiday: Holiday;
}

const HolidayEventDetail: React.FC<IProps> = props => {
  const { holiday } = props;

  return (
    <Container>
      <EventWrapper key={`${holiday.name}`}>
        <HolidayIndicator>
          <HolidayCode>{holiday.name.charAt(0)}</HolidayCode>
        </HolidayIndicator>
        <HolidayDetailsWrapper>
          <EventDate>
            {formatDateRange(parseDate(holiday.start), parseDate(holiday.end))}
          </EventDate>
          <HolidayLabelWrapper>
            <EventType>{holiday.name}</EventType>
          </HolidayLabelWrapper>
        </HolidayDetailsWrapper>
      </EventWrapper>
    </Container>
  );
};

export default HolidayEventDetail;

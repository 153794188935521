import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import RNDropDownPicker, {
  SchemaInterface,
} from 'react-native-dropdown-picker';
import { useTheme } from 'styled-components/native';

import { FormErrorMessage } from '@commons/styles';

import { Container, LabelText } from './index.style';

interface IProps {
  value: number | string | null;
  items: any[];
  onItemSelect: (item: any) => void;
  labelText?: string | null;
  showLabel?: boolean;
  schema?: Partial<SchemaInterface>;
  containerStyle?: StyleProp<ViewStyle>;
  openDropdown: boolean;
  setOpenDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
}

const DropDownPicker: React.FC<IProps> = ({
  labelText,
  value,
  items,
  onItemSelect,
  showLabel,
  schema,
  containerStyle,
  openDropdown,
  setOpenDropdown,
  placeholder,
  disabled,
  error,
}) => {
  const theme = useTheme();
  return (
    <Container style={containerStyle}>
      {showLabel && <LabelText>{labelText || 'Label text'}</LabelText>}
      <RNDropDownPicker
        open={openDropdown}
        value={value}
        items={items}
        setOpen={setOpenDropdown}
        setValue={() => {}}
        onSelectItem={onItemSelect}
        dropDownContainerStyle={{
          borderColor: theme.color.lightWhite,
          backgroundColor: theme.color.white,
        }}
        style={{
          borderColor: error ? theme.color.red : theme.color.lightWhite,
          borderWidth: error ? 2 : 1,
          minHeight: 42,
          backgroundColor: theme.color.white,
        }}
        textStyle={{
          fontFamily: theme.fonts.MontserratRegular,
          color: theme.color.text,
        }}
        listChildLabelStyle={{ fontFamily: theme.fonts.MontserratRegular }}
        schema={schema}
        placeholder={placeholder}
        theme={theme.name}
        disabledItemLabelStyle={{
          opacity: 0.5,
        }}
        disabled={disabled}
        disabledStyle={{
          backgroundColor: theme.color.transparentBlack2,
        }}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </Container>
  );
};

export default DropDownPicker;

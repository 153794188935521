import { createApi } from '@reduxjs/toolkit/query/react';

import config from '@config';
import { Attendance } from '@types';

import axiosBaseQuery from '../axiosBaseQuery';

enum AttendancesEnum {
  ReducerPath = 'attendancesApi',
  TagType = 'attendances',
}

const attendancesApi = createApi({
  reducerPath: AttendancesEnum.ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiBaseUrl,
  }),
  keepUnusedDataFor: 30,
  tagTypes: [AttendancesEnum.TagType],
  endpoints: build => ({
    getMyAttendances: build.query<
      Attendance[],
      { start?: string; end?: string }
    >({
      query: args => ({
        url: `/my-attendances`,
        method: 'GET',
        params: { start: args.start, end: args.end },
      }),
      transformResponse: (response: Attendance[]) => response,
      providesTags: [AttendancesEnum.TagType],
    }),
  }),
});

export const { useLazyGetMyAttendancesQuery } = attendancesApi;

export default attendancesApi;

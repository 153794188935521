import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

import { RootState } from '@redux/store';
import { getLocalSwitcherModalVisibility } from '@redux/profile/selectors';
import { updateLocaleSwitcherVisibility } from '@redux/profile/slice';

import LocaleSwitcherBottomSheet from './index.component';

const mapStateToProps = (state: RootState) => ({
  isVisible: getLocalSwitcherModalVisibility(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateLocaleSwitcherVisibility: (visibilityStatus: boolean) => {
    dispatch(updateLocaleSwitcherVisibility(visibilityStatus));
  },
});

const ConnectedLocaleSwitcherBottomSheet = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocaleSwitcherBottomSheet);

export default ConnectedLocaleSwitcherBottomSheet;

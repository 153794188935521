import { configContext } from '@dzangolab/react-config';
import React, { useContext } from 'react';
import { useTheme } from 'styled-components/native';

import { AppVersion } from '@components';

import { Container } from './index.style';

const AppFooter = () => {
  const config = useContext(configContext);
  const { size } = useTheme();

  if (size.isLarge) {
    return (
      <Container>
        <AppVersion version={config?.appVersion} />
      </Container>
    );
  }

  return <></>;
};

export default AppFooter;

import styled from 'styled-components/native';
import { Dimensions, Platform } from 'react-native';

export const Container = styled.View`
  justify-content: center;
  align-items: center;
  ${Platform.select({
    web: {
      height: Dimensions.get('window').height,
    },
    default: {
      flex: 1,
    },
  })}
`;

export const Message = styled.Text`
  font-family: ${props => props.theme.fonts.MontserratRegular};
  font-size: 16px;
  color: ${props => props.theme.color.text};
`;

import { useNavigation } from '@react-navigation/native';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-native';
import { Calendar, DateData } from 'react-native-calendars';
import { useTheme } from 'styled-components/native';
import FeatherIcons from 'react-native-vector-icons/Feather';
import Toast from 'react-native-toast-message';

import { Caret, ModalOverlay } from '@commons/styles';
import { MonthPicker, RefreshButton, ConfirmationDialog } from '@components';
import BottomSheet from '@components/BottomSheet';
import ROUTES from '@navigation/routes';
import { useDeleteEmployeeAttendanceMutation } from '@redux/apis/attendances';
import { Attendance, Holiday } from '@types';
import { formatDate, parseDate } from '@utils/dateTime';

import {
  CustomDay,
  AttendanceEventDetail,
  HolidayEventDetail,
} from './components';
import {
  CalendarHeader,
  Container,
  DeleteConfirmation,
  DeleteConfirmationText,
  HeaderDate,
} from './index.style';
import {
  CustomCalendarMarking,
  CustomDayMarking,
} from '../../../../types/attendance';

interface IProps {
  onMonthChange: (date: Date) => void;
  attendanceMarkings?: CustomCalendarMarking;
  holidayMarkings?: CustomCalendarMarking;
  onRefresh: (activeMonth: string) => void;
  loading?: boolean;
  activeMonth?: string;
}

const AttendanceCalendar: React.FC<IProps> = props => {
  const {
    activeMonth,
    attendanceMarkings,
    onRefresh,
    holidayMarkings,
    onMonthChange,
  } = props;
  const [displayMonthPicker, setDisplayMonthPicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [bottomSheetAttendance, setBottomSheetAttendance] =
    useState<Attendance[]>();
  const [bottomSheetHoliday, setBottomSheetHoliday] = useState<Holiday>();
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [attendanceToDelete, setAttendanceToDelete] = useState<Attendance>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const { navigate } = useNavigation<any>();

  const [deleteAttendance, { isLoading: isDeletingAttendance }] =
    useDeleteEmployeeAttendanceMutation();

  const handleMonthChange = (dateData: DateData) => {
    onMonthChange(parseDate(dateData.dateString));
  };

  const onDatePicked = (date: Date) => {
    setSelectedDate(date);
    setDisplayMonthPicker(false);
    onMonthChange(date);
  };

  const onAttendanceDelete = (attendance: Attendance) => {
    closeBottomSheet();
    setAttendanceToDelete(attendance);
    setShowDeleteConfirmation(true);
  };

  const onAttendanceUpdate = (attendance: Attendance) => {
    closeBottomSheet();
    navigate(ROUTES.APP_CREATE_ATTENDANCE, {
      attendanceId: attendance.id,
      activeMonth,
      type: 'edit',
    });
  };

  const onAttendanceDeletionConfirmed = async (attendance: Attendance) => {
    await deleteAttendance(attendance.id)
      .unwrap()
      .then(() => {
        setAttendanceToDelete(undefined);
        setShowDeleteConfirmation(false);
        Toast.show({
          type: 'success',
          text1: t('app.attendance.attendance-delete.success') || '',
        });
        if (activeMonth) {
          onRefresh(activeMonth);
        }
      })
      .catch(() => {
        Toast.show({
          type: 'error',
          text1: t('app.attendance.attendance-delete.failed') || '',
        });
      });
  };

  const onAttendanceDeletionCancelled = () => {
    setAttendanceToDelete(undefined);
    setShowDeleteConfirmation(false);
  };

  const getCalendarHeader = (day: any) => {
    return (
      <>
        <RefreshButton
          onPress={() => activeMonth && onRefresh(activeMonth)}
          isLoading={props.loading}
        />
        <CalendarHeader onPress={() => setDisplayMonthPicker(true)}>
          <HeaderDate>{formatDate(day.toDate(), 'MMMM yyyy')}</HeaderDate>
          <Caret />
        </CalendarHeader>
      </>
    );
  };

  const showAttendanceDetails = (dayMarking: CustomDayMarking) => {
    setBottomSheetAttendance(dayMarking.attendances);

    if (dayMarking.attendances) {
      setShowBottomSheet(true);
    }
  };

  const showHolidayDetails = (holiday?: Holiday) => {
    setBottomSheetAttendance(undefined);

    if (holiday) {
      setBottomSheetHoliday(holiday);
      setShowBottomSheet(true);
    }
  };

  const closeBottomSheet = () => {
    setShowBottomSheet(false);
    setBottomSheetAttendance(undefined);
    setBottomSheetHoliday(undefined);
  };

  const renderArrow = (direction: string) => {
    if (direction === 'left') {
      return (
        <FeatherIcons
          name={'chevron-left'}
          size={22}
          color={theme.color.text}
        />
      );
    } else {
      return (
        <FeatherIcons
          name={'chevron-right'}
          size={22}
          color={theme.color.text}
        />
      );
    }
  };

  const renderDayComponent = (props: any) => {
    const date = parseDate(props.date.dateString);
    let dayMarking = attendanceMarkings
      ? attendanceMarkings[props.date.dateString]
      : {};
    const holiday = holidayMarkings
      ? holidayMarkings[formatDate(date, 'yyyy-MM-dd')]
      : undefined;

    const onDaySelect = (
      isShowingAttendanceEvent: boolean,
      holiday?: Holiday,
    ) => {
      if (isShowingAttendanceEvent) {
        showAttendanceDetails(dayMarking);
      }

      if (holiday && !isShowingAttendanceEvent) {
        showHolidayDetails(holiday);
      }
    };

    return (
      <CustomDay
        activeMonth={activeMonth}
        date={date}
        marking={dayMarking}
        holiday={holiday}
        key={formatDate(date, 'yyyy-MM-dd')}
        onDateSelect={onDaySelect}
        onAttendanceDelete={onAttendanceDelete}
        onAttendanceUpdate={onAttendanceUpdate}
      />
    );
  };

  const getDeleteConfirmationContent = () => {
    if (attendanceToDelete) {
      return (
        <DeleteConfirmation>
          <DeleteConfirmationText>
            {t('app.attendance.attendance-delete.confirm-message')}
          </DeleteConfirmationText>
          <DeleteConfirmationText>
            {t('app.start')}:{' '}
            {formatDate(parseDate(attendanceToDelete.start), 'dd MMM yyyy')}
          </DeleteConfirmationText>
          <DeleteConfirmationText>
            {t('app.end')}:{' '}
            {formatDate(parseDate(attendanceToDelete.end), 'dd MMM yyyy')}
          </DeleteConfirmationText>
        </DeleteConfirmation>
      );
    }

    return <></>;
  };

  const getMaxDate = () => {
    const currentYear = new Date().getFullYear();
    const yearsToAdd = 2;

    const maxYearDate = new Date(currentYear + yearsToAdd, 0, 1);

    return maxYearDate;
  };

  return (
    <Container>
      <Calendar
        dayComponent={(props: any) => renderDayComponent(props)}
        current={formatDate(selectedDate, 'yyyy-MM-dd')}
        key={`${formatDate(selectedDate, 'yyyy-MM-dd')}-${theme.name}`}
        onMonthChange={handleMonthChange}
        renderHeader={getCalendarHeader}
        renderArrow={renderArrow}
        theme={{
          todayTextColor: theme.color.primary,
          textDayFontFamily: theme.fonts.MontserratRegular,
          textDayHeaderFontFamily: theme.fonts.MontserratRegular,
          calendarBackground: theme.color.background,
          // @ts-ignore
          'stylesheet.calendar.main': {
            week: {
              marginTop: 0,
              marginBottom: 0,
              flexDirection: 'row',
              justifyContent: 'space-between',
            },
          },
        }}
      />
      <Modal
        animationType={'fade'}
        transparent
        visible={displayMonthPicker}
        supportedOrientations={['landscape', 'portrait']}>
        <ModalOverlay onPress={() => setDisplayMonthPicker(false)}>
          <MonthPicker
            current={selectedDate}
            minDate={new Date('2020-01-01')}
            maxDate={getMaxDate()}
            onDatePicked={onDatePicked}
            allowJumpToToday={true}
          />
        </ModalOverlay>
      </Modal>

      <Modal
        animationType={'fade'}
        transparent
        visible={showDeleteConfirmation}
        supportedOrientations={['landscape', 'portrait']}>
        <ModalOverlay onPress={() => setShowDeleteConfirmation(false)}>
          <ConfirmationDialog
            title={t('app.attendance.attendance-delete.confirm-title')}
            confirmLabel={t('app.attendance.attendance-delete.delete')}
            onConfirm={() =>
              attendanceToDelete &&
              onAttendanceDeletionConfirmed(attendanceToDelete)
            }
            onCancel={() => onAttendanceDeletionCancelled()}
            cancelLabel={t('app.attendance.attendance-delete.cancel')}
            color={theme.color.red}
            isConfirmationLoading={isDeletingAttendance}>
            {getDeleteConfirmationContent()}
          </ConfirmationDialog>
        </ModalOverlay>
      </Modal>

      <BottomSheet
        visible={showBottomSheet}
        handleToggleModal={closeBottomSheet}
        onRequestClose={closeBottomSheet}>
        <>
          {bottomSheetAttendance && bottomSheetAttendance.length > 0 && (
            <AttendanceEventDetail
              attendances={bottomSheetAttendance}
              onAttendanceDelete={onAttendanceDelete}
              onAttendanceUpdate={onAttendanceUpdate}
            />
          )}
          {bottomSheetHoliday && (
            <HolidayEventDetail holiday={bottomSheetHoliday} />
          )}
        </>
      </BottomSheet>
    </Container>
  );
};

export default AttendanceCalendar;

export const isNumeric = (value: any) => {
  return /^-?\d+$/.test(value);
};

export const stringToInt = (value: any) => {
  if (isNumeric(value) || value === '') {
    value = value !== '' ? parseInt(value) : undefined;

    return value;
  }
};

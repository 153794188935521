import React from 'react';
import { AppFooter, BottomNavbar, Loading, Navbar } from '@components';

import {
  Container,
  Main,
  H1,
  Small,
  PageGridContainer,
  ToolbarContainer,
  ErrorPageContainer,
  ErrorMessageText,
} from './index.style';
import { LoadingPageProperties } from '@components/Loading';

interface Properties {
  children?: React.ReactNode;
  errorMessage?: string;
  loading?: boolean;
  loadingComponent?: React.ReactElement;
  loadingPageStyle?: LoadingPageProperties;
  subtitle?: string;
  title?: string | null;
  toolbar?: React.ReactNode;
}

const Page: React.FC<Properties> = ({
  children,
  errorMessage,
  loading = false,
  loadingComponent,
  loadingPageStyle,
  subtitle,
  title,
  toolbar,
}) => {
  let child = null;

  if (loading) {
    child = loadingComponent ? (
      loadingComponent
    ) : (
      <Loading {...loadingPageStyle} />
    );
  } else if (errorMessage) {
    child = (
      <ErrorPageContainer>
        <ErrorMessageText>{errorMessage}</ErrorMessageText>
      </ErrorPageContainer>
    );
  }

  return (
    <Main>
      <Navbar />
      <Container>
        <PageGridContainer>
          {title && (
            <H1>
              {title}
              {subtitle && <Small>{subtitle}</Small>}
            </H1>
          )}
          {toolbar && <ToolbarContainer>{toolbar}</ToolbarContainer>}
        </PageGridContainer>
        {child ? child : children}
      </Container>
      <BottomNavbar />
      <AppFooter />
    </Main>
  );
};

export default Page;

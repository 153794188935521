import React, { useState } from 'react';

import InputField from '@components/DateRangePicker/components/InputField';
import { Modal } from 'react-native';
import { FormErrorMessage, ModalOverlay } from '@commons/styles';
import MonthPicker from '@components/MonthPicker';
import { formatDate } from '@utils/dateTime';

interface IProps {
  onMonthSelect: (selectedMonth: Date) => void;
  labelText?: string | null;
  showLabel?: boolean;
  placeholder?: string;
  value?: Date | null;
  minDate: Date;
  maxDate: Date;
  error?: string;
}

const MonthPickerInput: React.FC<IProps> = props => {
  const {
    labelText,
    placeholder,
    showLabel,
    value,
    onMonthSelect,
    minDate,
    maxDate,
    error,
  } = props;

  const [monthPickerVisible, setMonthPickerVisible] = useState(false);

  const onDatePicked = (selectedDate: Date) => {
    onMonthSelect(selectedDate);
    setMonthPickerVisible(false);
  };

  return (
    <>
      <InputField
        label={labelText}
        placeholder={placeholder}
        selectedItem={value ? formatDate(value, 'MMMM yyyy') : null}
        handleToggleModal={() => {
          setMonthPickerVisible(true);
        }}
        disable={false}
        error={error}
      />
      <Modal
        animationType={'fade'}
        transparent
        visible={monthPickerVisible}
        supportedOrientations={['landscape', 'portrait']}>
        <ModalOverlay
          onPress={() => {
            setMonthPickerVisible(false);
          }}>
          <MonthPicker
            current={value ?? undefined}
            minDate={minDate}
            maxDate={maxDate}
            onDatePicked={onDatePicked}
            allowJumpToToday={false}
          />
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default MonthPickerInput;

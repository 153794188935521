import React from 'react';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { IconProps } from 'react-native-vector-icons/Icon';
import { StyleProp, ViewStyle } from 'react-native';

import { FButtonContainer } from './index.style';

interface IProps {
  onPress: () => void;
  icon?: IconProps;
  buttonStyle?: StyleProp<ViewStyle>;
}

const FloatingButton: React.FC<IProps> = ({ icon, onPress, buttonStyle }) => {
  return (
    <FButtonContainer
      activeOpacity={0.7}
      onPress={onPress}
      style={[buttonStyle]}>
      <Icon
        {...icon}
        color={icon?.color || 'white'}
        name={icon?.name || 'plus'}
        size={icon?.size || 25}
      />
    </FButtonContainer>
  );
};

export default FloatingButton;

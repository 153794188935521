import React, { useState } from 'react';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';

import { useAppDispatch } from '@redux/hook';
import { updateLocaleSwitcherVisibility } from '@redux/profile/slice';
import { save } from '@utils/storage';

import {
  Container,
  Locale,
  LocaleOptions,
  LocaleText,
  SelectedLocale,
  Switcher,
} from './index.style';

const LocaleSwitcher = () => {
  const { i18n, t } = useTranslation();
  const supportedLngs = i18n.options.supportedLngs;
  const [showOptions, setShowOptions] = useState(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const changeLocale = async (locale: string) => {
    i18n.changeLanguage(locale);
    setShowOptions(false);
    await save('locale', locale);
  };

  const onSwitcherSelected = () => {
    if (Platform.OS !== 'web' || !theme.size.isLarge) {
      dispatch(updateLocaleSwitcherVisibility(true));
    } else {
      setShowOptions(!showOptions);
    }
  };

  return (
    <Container>
      <Switcher onPress={onSwitcherSelected}>
        <SelectedLocale>{i18n.language}</SelectedLocale>
      </Switcher>
      {showOptions && supportedLngs && (
        <LocaleOptions>
          {supportedLngs
            .filter(locale => locale !== 'cimode')
            .map(locale => {
              return (
                <Locale onPress={() => changeLocale(locale)} key={locale}>
                  <LocaleText>{t(`supportedLocales.${locale}`)}</LocaleText>
                </Locale>
              );
            })}
        </LocaleOptions>
      )}
    </Container>
  );
};

export default LocaleSwitcher;

import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '@locales/en/locales.json';
import fr from '@locales/fr/locales.json';

const i18nConfig: InitOptions = {
  compatibilityJSON: 'v3',
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
  lng: 'en',
  supportedLngs: ['en', 'fr'],
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(initReactI18next).init(i18nConfig);

export default i18n;

import i18n from '@config/i18n';

import ROUTES from './routes';

export const getRouteTitleByName = (name: string) => {
  switch (name) {
    case ROUTES.APP_MY_ATTENDANCE:
      return i18n.t('app.screens.my-attendance');
    case ROUTES.APP_DASHBOARD:
      return i18n.t('app.screens.dashboard');
    case ROUTES.APP_GENERAL_ATTENDANCE:
      return i18n.t('app.screens.general-attendance');
    case ROUTES.APP_PROFILE:
      return i18n.t('app.screens.profile');
    case ROUTES.APP_TIMELINE:
      return i18n.t('app.screens.timeline');
    case ROUTES.APP_PENDING_LEAVE:
      return i18n.t('app.screens.pending-approval');
    case ROUTES.APP_LOANS:
      return i18n.t('app.screens.loans');
    case ROUTES.APP_LOAN_DETAILS:
      return i18n.t('app.screens.loan-details');
    case ROUTES.APP_PAYSLIPS:
      return i18n.t('app.screens.payslips');
    case ROUTES.APP_CONTRACTS:
      return i18n.t('app.screens.contracts');
    case ROUTES.APP_HOLIDAYS:
      return i18n.t('app.screens.holidays');
    case ROUTES.APP_OTHER_DOCUMENTS:
      return i18n.t('app.screens.other-docs');
    default:
      return i18n.t('app.screens.dashboard');
  }
};

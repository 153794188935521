import React, { useState, useEffect } from 'react';
import {
  Keyboard,
  Platform,
  ScrollView,
  StyleProp,
  ViewStyle,
} from 'react-native';

interface Props {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

const TOP_CONTAINER_MARGIN = Platform.OS === 'ios' ? 0.6 : 0.3;

const KeyboardAvoidingScrollView: React.FC<Props> = ({ children, style }) => {
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);

  useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener(
      'keyboardDidShow',
      event => {
        setKeyboardHeight(event.endCoordinates.height);
      },
    );

    const keyboardWillHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardHeight(0);
      },
    );

    return () => {
      keyboardWillShowListener.remove();
      keyboardWillHideListener.remove();
    };
  }, []);

  return (
    <ScrollView
      style={[style, { paddingBottom: keyboardHeight }]}
      contentContainerStyle={{
        top: -keyboardHeight * TOP_CONTAINER_MARGIN,
      }}>
      {children}
    </ScrollView>
  );
};

export default KeyboardAvoidingScrollView;

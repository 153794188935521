import React from 'react';
import {
  Modal,
  StyleProp,
  TouchableHighlight,
  TouchableWithoutFeedback,
  ViewStyle,
  ScrollView,
} from 'react-native';

import { ModalContainer, ModalOptionsContainer } from './index.style';

interface IProps {
  visible: boolean;
  handleToggleModal: () => void;
  onRequestClose?: () => void;
  modalBackgroundStyle?: StyleProp<ViewStyle>;
  modalOptionsContainerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactElement;
  bottomComponent?: React.ReactNode;
  headerComponent?: React.ReactNode;
  customContent?: React.ReactNode;
  animationType?: 'none' | 'slide' | 'fade' | undefined;
}

const BottomSheet: React.FC<IProps> = props => {
  const {
    visible,
    handleToggleModal,
    onRequestClose,
    modalBackgroundStyle,
    modalOptionsContainerStyle,
    children,
    customContent,
    animationType,
  } = props;

  return (
    <Modal
      transparent={true}
      visible={visible}
      onRequestClose={onRequestClose}
      animationType={animationType || 'none'}
      supportedOrientations={['landscape', 'portrait']}>
      <ModalContainer
        onPress={handleToggleModal}
        style={[modalBackgroundStyle]}>
        <TouchableWithoutFeedback>
          <ModalOptionsContainer style={[modalOptionsContainerStyle]}>
            {props.headerComponent}
            {customContent ? (
              customContent
            ) : (
              <ScrollView>
                <TouchableHighlight>{children}</TouchableHighlight>
              </ScrollView>
            )}
            {props.bottomComponent}
          </ModalOptionsContainer>
        </TouchableWithoutFeedback>
      </ModalContainer>
    </Modal>
  );
};

export default BottomSheet;

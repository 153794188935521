export const commonColor = {
  accent: '#5882FD',
  alwaysBlack: '#000000',
  alwaysWhite: '#FFFFFF',
  darkGrey: '#9a9b9a',
  darkRed: '#9e3200',
  deepBlue: '#00c3ff',
  darkYellow: '#f0a200',
  green: '#008810',
  grey: '#858585',
  lightBlack: '#555',
  lightBlue: '#B3EDFF',
  lightGreen: '#CEFFD4',
  lightGreen2: '#6bd098',
  lightGrey3: '#D2D2D2',
  lightGrey5: '#eee',
  lightGrey6: '#999999',
  lightMint: '#a2f3d4',
  lightOrange: '#ff951b61',
  lightRed: '#FDC0A5',
  lightWhite: '#f2f2f2',
  lightYellow: '#fbc658',
  lightYellow2: 'rgba(251, 197, 88, 0.4)',
  pinkLight: '#f8d7da',
  pinkDark: '#f1aeb5',
  primary: '#FB951B',
  red: '#f84f01',
  transparent: 'transparent',
  whiteSmoke: '#f5f5f5',
  yellow: '#f8d301',
};

export const lightColor = {
  ...commonColor,
  background: '#F2F2F2',
  black: '#000',
  lightGrey: '#E8E8E8',
  lightGrey2: 'rgba(0, 0, 0, 0.3)',
  lightGrey4: '#545353',
  text: '#333333',
  transparentBlack1: 'rgba(0, 0, 0, 0.1)',
  transparentBlack2: 'rgba(0, 0, 0, 0.2)',
  white: '#FFFFFF',
  lightBlack1: '#D2D2D2',
};

export const darkColor = {
  ...commonColor,
  background: '#141414',
  black: '#FFFFFF',
  lightGrey: '#2A2A2A',
  lightGrey2: 'rgba(255, 255, 255, 0.3)',
  lightGrey4: '#D2D2D2',
  text: '#C3C3C3',
  transparent: 'transparent',
  transparentBlack1: 'rgba(255, 255, 255, 0.1)',
  transparentBlack2: 'rgba(255, 255, 255, 0.2)',
  white: '#2A2A2A',
  lightBlack1: '#555',
};

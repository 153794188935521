import React from 'react';
import { ActivityIndicator } from 'react-native';
import { useTheme } from 'styled-components/native';

import {
  ActionButton,
  ActionButtonText,
  ModalAction,
  ModalBody,
  ModalContainer,
  ModalHeader,
  Title,
} from './index.style';

interface IProps {
  children: React.ReactElement;
  cancelLabel: string;
  color: string;
  confirmLabel: string;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  isConfirmationLoading?: boolean;
}

const ConfirmationDialog: React.FC<IProps> = props => {
  const {
    title,
    children,
    onCancel,
    color,
    onConfirm,
    confirmLabel,
    cancelLabel,
    isConfirmationLoading,
  } = props;
  const theme = useTheme();

  return (
    <ModalContainer>
      <ModalHeader color={color}>
        <Title>{title}</Title>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalAction>
        <ActionButton onPress={onConfirm} color={color}>
          {isConfirmationLoading ? (
            <ActivityIndicator color={theme.color.white} />
          ) : (
            <ActionButtonText>{confirmLabel}</ActionButtonText>
          )}
        </ActionButton>
        <ActionButton onPress={onCancel} color={theme.color.grey}>
          <ActionButtonText>{cancelLabel}</ActionButtonText>
        </ActionButton>
      </ModalAction>
    </ModalContainer>
  );
};

export default ConfirmationDialog;

import { createApi } from '@reduxjs/toolkit/query/react';

import config from '@config';
import { Attendance } from '@types';

import axiosBaseQuery from '../axiosBaseQuery';

enum PendingAttendancesEnum {
  ReducerPath = 'pendingAttendancesApi',
  TagType = 'pendingAttendances',
}

const pendingAttendancesApi = createApi({
  reducerPath: PendingAttendancesEnum.ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiBaseUrl,
  }),
  keepUnusedDataFor: 30,
  tagTypes: [PendingAttendancesEnum.TagType],
  endpoints: build => ({
    getPendingAttendances: build.query<
      Attendance[],
      { filters?: any } | undefined
    >({
      query: args => ({
        url: `/pending-attendances`,
        method: 'GET',
        params: { filters: JSON.stringify(args?.filters) },
      }),
      transformResponse: (response: Attendance[]) => response,
      providesTags: [PendingAttendancesEnum.TagType],
    }),
  }),
});

export const { useLazyGetPendingAttendancesQuery } = pendingAttendancesApi;

export default pendingAttendancesApi;

import { Slider } from '@miblanchard/react-native-slider';
import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { useTheme } from 'styled-components/native';

import { ErrorMessage, FieldWrapper, InputLabel } from '../commonStyles';
import { SelectedValue, SliderWrapper } from './index.style';

interface IProps {
  control: Control<any, any>;
  name: string;
  minimumValue: number;
  maximumValue: number;
  step: number;
  defaultValue?: number;
  label?: string | null;
  error?: FieldError;
  showLabel?: boolean;
  showValue?: boolean;
  valueUnit?: string;
}

const SliderInput: React.FC<IProps> = props => {
  const {
    control,
    name,
    minimumValue,
    maximumValue,
    defaultValue,
    step,
    label,
    error,
    showLabel,
    showValue,
    valueUnit,
  } = props;
  const theme = useTheme();

  return (
    <FieldWrapper style={{ marginBottom: 10 }}>
      {showLabel && !!label && <InputLabel>{label}</InputLabel>}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <SliderWrapper>
              {showValue && (
                <SelectedValue>
                  {value || defaultValue} {valueUnit}
                </SelectedValue>
              )}
              <Slider
                value={value || defaultValue}
                onValueChange={value => onChange(value[0])}
                minimumValue={minimumValue}
                maximumValue={maximumValue}
                thumbTintColor={theme.color.primary}
                minimumTrackTintColor={theme.color.primary}
                step={step}
              />
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </SliderWrapper>
          );
        }}
      />
    </FieldWrapper>
  );
};

export default SliderInput;

import styled from 'styled-components/native';

export const Container = styled.View`
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const WelcomeText = styled.Text`
  font-size: 16px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const LocaleSwitcherContainer = styled.View`
  position: absolute;
  top: 50px;
  right: 20px;
`;

export const WebContainer = styled.View`
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const PendingContainer = styled.View`
  margin-top: 10px;
`;

export const TitleText = styled.Text`
  font-size: 18px;
  align-self: center;
  margin-left: 10px;
  font-weight: 400;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const CardContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const CardTitleContainer = styled.View`
  flex-direction: row;
`;

import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import ThirdPartyEmailPassword from 'supertokens-web-js/recipe/thirdpartyemailpassword';

import config from '@config';

SuperTokens.init({
  appInfo: {
    apiDomain: config.apiBaseUrl,
    apiBasePath: '/auth',
    appName: config.appTitle,
  },
  recipeList: [
    Session.init({
      sessionTokenFrontendDomain: `.${config.baseDomain}`,
    }),
    ThirdPartyEmailPassword.init(),
  ],
});

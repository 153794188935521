import React, { useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';

import ROUTES from '@navigation/routes';
import Loading from '@components/Loading';
import { useSuperTokenAuthMutation } from '@redux/auth/api';
import { setUser } from '@redux/auth/slice';
import { useAppDispatch } from '@redux/hook';

const AuthGoogleCallback = () => {
  const dispatch = useAppDispatch();
  const route = useRoute();
  const { navigate } = useNavigation<any>();

  const [superTokenAuth, { isLoading }] = useSuperTokenAuthMutation();

  const authCallback = async () => {
    try {
      const params = route.params as {
        code: string;
      };

      let code = params ? params.code : '';

      await superTokenAuth({ code })
        .unwrap()
        .then(response => {
          if (response) {
            const user = response.user;

            dispatch(setUser(user));
          } else {
            navigate(ROUTES.APP_LOGIN);
          }
        });
    } catch (error: any) {
      navigate(ROUTES.APP_LOGIN);
    }
  };

  useEffect(() => {
    authCallback();
  }, []);

  return <Loading />;
};

export default AuthGoogleCallback;

import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import { ThemeType } from '@types';

export const Container = styled.View`
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.color.white};
  height: ${props => (props.theme.size.isLarge ? '90px' : '50px')};

  ${props =>
    Platform.OS === 'web' &&
    css`
      box-shadow: 0 0 10px
        ${props.theme.name === ThemeType.LIGHT
          ? props.theme.color.lightGrey
          : props.theme.color.background};
      transition: 0.5s;
    `};
`;

import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

import { RootState } from '@redux/store';
import { getMyLoans } from '@redux/loans/selectors';

import LoanDetails from './index.component';

const mapStateToProps = (state: RootState) => ({
  loans: getMyLoans(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

const ConnectedLoanDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanDetails);

export default ConnectedLoanDetails;

import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';
import { DefaultTheme } from 'styled-components';
import EntypoIcons from 'react-native-vector-icons/Entypo';

interface IDropdownMenuItemProps {
  borderLess?: boolean;
  theme: DefaultTheme;
}

export const Container = styled.View`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 20px;
`;

export const MenuContainer = styled.View`
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;

export const UserDetails = styled.TouchableOpacity.attrs({
  activeOpacity: 1,
})`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const User = styled.Text`
  font-size: 16px;
  color: ${props => props.theme.color.text};
  margin-right: 8px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const DropdownMenu = styled.View`
  position: absolute;
  right: 0;
  width: 150px;
  position: absolute;
  background-color: ${props => props.theme.color.white};
  top: 28px;
  border-radius: 5px;

  ${props =>
    Platform.OS === 'web' &&
    css`
      box-shadow: 0 0 10px ${props.theme.color.transparentBlack2};
    `};
`;

export const DropdownMenuItem = styled.TouchableOpacity`
  padding: 5px 0;
  padding: 8px 10px;

  ${(props: IDropdownMenuItemProps) =>
    !props.borderLess &&
    `
      border-bottom-width: 1px;
      border-bottom-color: ${props.theme.color.transparentBlack2};
    `}
`;

export const DropdownMenuText = styled.Text`
  font-size: 15px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const MenuBarContainer = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 20px;
  flex: 1;
`;

export const MenuItem = styled.TouchableOpacity`
  margin-end: 20px;
`;

export const MenuItemText = styled.Text`
  font-size: 17px;
  font-weight: 400;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const ThemeSwitch = styled.Pressable`
  margin-right: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ThemeIcon = styled(EntypoIcons).attrs({
  name: 'light-up',
})`
  font-size: 19px;
  color: ${props => props.theme.color.text};
  margin-right: 8px;
`;

import styled from 'styled-components/native';

export const EventContainer = styled.View`
  align-items: flex-start;
  background-color: ${props => props.theme.color.white};
  padding: 13px;
  border-radius: 15px;
  margin-bottom: 10px;
  border: 1px solid ${props => props.theme.color.transparentBlack1};
`;

import { Platform } from 'react-native';
import styled, { DefaultTheme } from 'styled-components/native';
import { DataTable } from 'react-native-paper';

interface DayProps {
  weekend?: boolean;
  color?: string;
  hovered?: boolean;
  loggedInUser?: boolean;
  theme: DefaultTheme;
  isCurrentDate?: boolean;
}

export const LoaderWrapper = styled.View`
  flex: 1;
  justify-content: center;
  padding: 60px 0;
`;

export const Container = styled.View`
  flex: ${Platform.OS !== 'web' ? 1 : 'none'};
`;

export const TableHeader = styled.View`
  margin-bottom: 10px;
`;

export const HeaderRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const TitleWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const SearchWrapper = styled.View`
  flex: 1;
  max-width: 40%;
  margin-left: 10px;
`;

export const SearchBox = styled.View`
  background-color: ${props => props.theme.color.white};
  padding: 0 10px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.color.transparentBlack2};
`;

export const SearchTextField = styled.TextInput`
  font-size: 15px;
  padding: 10px 0;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};

  ${Platform.OS === 'web' &&
  `
  outline-style: none;
  `}
`;

export const TableTitle = styled.Text`
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratBold};
`;

export const Navigation = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const NavigationButton = styled.TouchableOpacity`
  background-color: ${props => props.theme.color.primary};
  margin: 0 2.5px;
  padding: 5px 10px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
`;

export const NavigationButtonText = styled.Text`
  color: ${props => props.theme.color.alwaysWhite};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const TableWrapper = styled.ScrollView`
  padding-bottom: 5px;
  width: 100%;
  background-color: ${props => props.theme.color.white};
`;

export const DataRows = styled.ScrollView``;

export const EmployeeHeading = styled.Text`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratBold};
`;

export const MonthDay = styled.Text`
  font-size: 14px;
  color: ${(props: DayProps) =>
    props.weekend
      ? props.isCurrentDate
        ? props.theme.color.primary
        : props.theme.color.alwaysBlack
      : props.isCurrentDate
      ? props.theme.color.primary
      : props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
  ${(props: DayProps) =>
    props.isCurrentDate &&
    `
    color: ${props.theme.color.white};
  `}
`;

export const MonthDayContainer = styled.View<{ isCurrentDate?: boolean }>`
  width: 22px;
  height: 22px;
  background: ${props =>
    props.isCurrentDate ? props.theme.color.primary : 'transparent'};
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 10px;
`;

export const EmployeeName = styled.Text`
  font-size: 14px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const RowWrapper = styled.Pressable`
  background-color: ${(props: DayProps) =>
    props.hovered
      ? props.theme.color.lightGrey
      : props.loggedInUser
      ? props.theme.color.lightMint
      : props.theme.color.white};
`;

export const DayCellWrapper = styled.Pressable`
  position: relative;
  background-color: ${(props: DayProps) =>
    props.weekend
      ? props.theme.color.lightGrey3
      : props.color ?? 'transparent'};
  min-width: ${Platform.OS === 'web' ? '35px' : '10px'};
  flex: 1;
  border-right-width: 1px;
  border-color: ${props => props.theme.color.transparentBlack2};
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  ${(props: DayProps) =>
    props.isCurrentDate &&
    `
    border-right-width: 1px;
    border-left-width: 1px;
    border-color: ${props.theme.color.primary};
  `}
`;

export const DayBadge = styled.View`
  height: 30px;
  width: 30px;
  background-color: ${(props: DayProps) => props.color ?? 'transparent'};
  justify-content: center;
  align-items: center;
  border-radius: 30px;
`;

export const DayCellText = styled.Text`
  color: ${props => props.theme.color.alwaysWhite};
  font-size: 13px;
  font-weight: 600;
  font-family: ${props => props.theme.fonts.MontserratBold};
`;

export const RejectedDotWrapper = styled.View`
  bottom: 2px;
  right: 2px;
  position: absolute;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 9;
`;

export const RejectedDot = styled.View`
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background-color: ${props => props.theme.color.red};
`;

export const WDHeading = styled.Text`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratBold};
`;

export const WorkingDayCell = styled(DataTable.Cell)`
  border-color: ${props => props.theme.color.transparentBlack2};
  min-width: ${Platform.OS === 'web' ? 35 : 10};
  justify-content: center;
`;

import { useState, useEffect } from 'react';
import { useLazyGetLeaveTypesQuery } from '@redux/leaves/api';
import { COMPENSATION_EVENT_TYPE_ID, LEAVE_EVENT_TYPE_ID } from '@constants';

const useLeaveTypes = () => {
  const [leaveTypes, setLeaveTypes] = useState<
    { id: number; title: string; typeId: number }[]
  >([]);
  const [leaveTypesTrigger, { data: apiLeaveTypes }] =
    useLazyGetLeaveTypesQuery();

  useEffect(() => {
    leaveTypesTrigger({});
  }, []);

  useEffect(() => {
    if (apiLeaveTypes) {
      const mappedLeaveTypes = apiLeaveTypes.map(leaveType => ({
        id: leaveType.id,
        title: leaveType.name,
        typeId: LEAVE_EVENT_TYPE_ID,
      }));

      setLeaveTypes([
        ...mappedLeaveTypes,
        {
          id: COMPENSATION_EVENT_TYPE_ID,
          title: 'Compensation leave',
          typeId: COMPENSATION_EVENT_TYPE_ID,
        },
      ]);
    }
  }, [apiLeaveTypes]);

  return leaveTypes;
};

export default useLeaveTypes;

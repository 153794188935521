import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.View`
  flex: 1;
`;

export const ErrorText = styled.Text`
  font-size: 16px;
  align-self: center;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const ImageContainer = styled.View`
  height: 100%;
  margin-top: 10px;
`;

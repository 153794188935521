import styled from 'styled-components/native';

export const Container = styled.View`
  padding: 20px;
`;

export const Title = styled.Text`
  font-family: ${props => props.theme.fonts.MontserratRegular};
  font-size: 16px;
  margin-bottom: 10px;
  color: ${props => props.theme.color.text};
`;

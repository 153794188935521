import { Platform } from 'react-native';
import styled, { DefaultTheme, css } from 'styled-components/native';

interface DayProps {
  disabled?: boolean;
  startingDay?: boolean;
  endingDay?: boolean;
  color?: string;
  theme: DefaultTheme;
}

export const Container = styled.View`
  flex: ${Platform.OS === 'web' ? 'auto' : 1};
  background-color: ${props => props.theme.color.background};
`;

export const CalendarHeader = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

export const HeaderDate = styled.Text`
  font-size: 16px;
  color: ${props => props.theme.color.text};
  margin-right: 7px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const AttendanceEventsContainer = styled.View`
  flex: ${Platform.OS === 'web' ? 'none' : 1};
  padding: ${Platform.OS === 'web' ? '5px 10px' : '10px'};
  margin-top: ${Platform.OS === 'web' ? '5px' : '0'};
`;

export const DayEventContainer = styled.TouchableOpacity`
  background-color: ${(props: DayProps) =>
    props.color ?? props.theme.color.primary};
  padding: 5px 10px;
  border-top-left-radius: ${(props: DayProps) =>
    props.startingDay ? 10 : 0}px;
  border-bottom-left-radius: ${(props: DayProps) =>
    props.startingDay ? 10 : 0}px;
  border-top-right-radius: ${(props: DayProps) => (props.endingDay ? 10 : 0)}px;
  border-bottom-right-radius: ${(props: DayProps) =>
    props.endingDay ? 10 : 0}px;
  margin: ${(props: DayProps) =>
    props.startingDay && props.endingDay
      ? '2px 5px'
      : props.startingDay
      ? '2px 0 2px 10px'
      : props.endingDay
      ? '2px 10px 2px 0'
      : '2px 0'};
  width: 100%;

  ${Platform.OS === 'web' &&
  css`
    width: ${(props: DayProps) =>
      props.startingDay && props.endingDay
        ? 'calc(100% - 10px)'
        : props.startingDay || props.endingDay
        ? 'calc(100% - 10px)'
        : '100%'};
  `}
`;

export const DayEventLabel = styled.Text`
  color: ${props => props.theme.color.white};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const DeleteConfirmation = styled.View`
  padding: 5px 15px;
`;

export const DeleteConfirmationText = styled.Text`
  font-size: 15px;
  margin: 6px 0;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

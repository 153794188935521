import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const FButtonContainer = styled.TouchableOpacity`
  align-items: center;
  background-color: ${props => props.theme.color.primary};
  border-radius: 45px;
  bottom: ${Platform.OS === 'web' ? '60px' : '20px'};
  justify-content: center;
  height: 45px;
  position: ${Platform.OS === 'web' ? 'fixed' : 'absolute'};
  right: ${Platform.OS === 'web' ? '20px' : '10px'};
  width: 45px;
`;

import _ from 'lodash';
import React from 'react';

import { Container, Item, Label, Selected, SelectedIcon } from './index.styled';

interface IProps {
  options: {
    key: string;
    label: string;
    value: any;
  }[];
  values: any[];
  onChange: (value: any) => void;
}

const CheckedOption: React.FC<IProps> = props => {
  const { options, values, onChange } = props;

  return (
    <Container>
      {options.map(option => {
        return (
          <Item key={option.key} onPress={() => onChange(option.value)}>
            <Selected selected={values.includes(option.value)}>
              <SelectedIcon selected={values.includes(option.value)} />
            </Selected>
            <Label>{option.label}</Label>
          </Item>
        );
      })}
    </Container>
  );
};

export default CheckedOption;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Attendance, Holiday } from '@types';
import {
  getHolidayMarkings,
  getMonthlyAttendanceMarkings,
} from '@utils/attendance';

import { CustomCalendarMarking, MonthlyMarkings } from '../../types/attendance';

interface MyAttendanceState {
  activeMonth: string | null;
  attendance: { [key: string]: Attendance[] };
  attendanceMarkings: MonthlyMarkings;
  fetchedMonths: string[];
  holidays: Holiday[];
  holidaysMarkings: CustomCalendarMarking;
}

const initialState: MyAttendanceState = {
  activeMonth: null,
  attendance: {},
  attendanceMarkings: {},
  fetchedMonths: [],
  holidays: [],
  holidaysMarkings: {},
};

export const myAttendanceSlice = createSlice({
  name: 'myAttendance',
  initialState,
  reducers: {
    setAttendance(
      state,
      action: PayloadAction<{ month: string; attendance: Attendance[] }>,
    ) {
      state.attendance = {
        ...state.attendance,
        [action.payload.month]: action.payload.attendance,
      };
      state.attendanceMarkings = {
        ...state.attendanceMarkings,
        [action.payload.month]: getMonthlyAttendanceMarkings(
          action.payload.attendance,
        ),
      };
    },
    setHolidays(state, action: PayloadAction<Holiday[]>) {
      state.holidays = action.payload;
      state.holidaysMarkings = getHolidayMarkings(action.payload);
    },
    setActiveMonth(state, action: PayloadAction<string>) {
      state.activeMonth = action.payload;
    },
    updateFetchedMonths(state, action: PayloadAction<string[]>) {
      state.fetchedMonths = action.payload;
    },
    resetMyAttendance(state) {
      state.activeMonth = null;
      state.attendance = {};
      state.attendanceMarkings = {};
      state.fetchedMonths = [];
      state.holidays = [];
      state.holidaysMarkings = {};
    },
  },
});

export const {
  setAttendance,
  setHolidays,
  setActiveMonth,
  updateFetchedMonths,
  resetMyAttendance,
} = myAttendanceSlice.actions;
export default myAttendanceSlice.reducer;

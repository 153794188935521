import { DefaultTheme } from 'styled-components/native';

import { ThemeType } from '@types';

import { lightColor } from './colors';

export const getLightTheme = (
  size: DefaultTheme['size'],
  fonts: { [key: string]: string },
): DefaultTheme => {
  return {
    name: ThemeType.LIGHT,
    color: lightColor,
    size,
    fonts,
  };
};

import styled, { DefaultTheme } from 'styled-components/native';
import FeatherIcons from 'react-native-vector-icons/Feather';

interface MessageProps {
  type?: 'error' | 'warning';
  theme: DefaultTheme;
}

export const Container = styled.View`
  background-color: ${props => props.theme.color.white};
  padding: 10px;
  margin: 15px;
  border-radius: 10px;
`;

export const RequestLeaveContainer = styled.View`
  align-items: center;
`;

export const AttendanceTitle = styled.Text`
  color: ${props => props.theme.color.grey};
  font-size: 15px;
  margin-bottom: 5px;
  margin-left: 3px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const AttendanceContainer = styled.View`
  margin-bottom: 15px;
`;

export const LeaveContainer = styled.View<{
  theme: DefaultTheme;
}>`
  margin-top: 30px;
  background-color: ${props => props.theme.color.white};
  border-width: 1px;
  border-color: ${props => props.theme.color.transparentBlack2};
  padding: 30px;
`;

export const Title = styled.Text<{
  theme: DefaultTheme;
}>`
  font-size: 32px;
  font-weight: 600;
  margin-top: 20px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const ButtonContainer = styled.View`
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
`;

export const MessageBox = styled.View`
  margin: 5px 0;
  flex-direction: row;
  align-items: center;
`;

export const WarningIcon = styled(FeatherIcons).attrs({
  name: 'alert-triangle',
})`
  font-size: 16px;
  color: ${props => props.theme.color.lightYellow};
`;

export const MessageText = styled.Text`
  color: ${(props: MessageProps) =>
    props.type === 'warning'
      ? props.theme.color.darkYellow
      : props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
  margin-left: 10px;
  flex: 1;
`;

import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { Platform } from 'react-native';

export const Container = styled.ScrollView`
  flex: 1;
  margin: 10px;
`;

export const WebContainer = styled.View`
  margin-top: 10px;
  z-index: -1;
`;

export const CardContainer = styled.View`
  background-color: ${props => props.theme.color.white};
  border-radius: 10px;
  margin: 5px;
  overflow: hidden;
  border: 1px solid ${props => props.theme.color.transparentBlack1};
  box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 0px;
`;

export const CardHeaderContainer = styled.TouchableOpacity`
  background-color: ${props => props.theme.color.white};
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CardHeaderTitle = styled.Text`
  color: ${props => props.theme.color.black};
  font-size: 16px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const CardContent = styled.View`
  padding: 10px;
  border-top-width: 1px;
  border-top-color: #dddddd58;
  flex-direction: ${props =>
    Platform.OS === 'web' &&
    (props.theme.size.isLarge || props.theme.size.isMedium)
      ? 'row'
      : 'column'};
  justify-content: center;
`;

export const CardCartIcon = styled(Icon)`
  color: ${props => props.theme.color.black};
`;

export const HeaderActionButton = styled.TouchableOpacity`
  margin-right: 10px;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`;

export const HeaderText = styled.Text`
  font-size: 14px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

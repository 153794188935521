import styled from 'styled-components/native';
import EntypoIcons from 'react-native-vector-icons/Entypo';
import FeatherIcons from 'react-native-vector-icons/Feather';

export const Container = styled.ScrollView`
  padding: 20px 20px 0 20px;
`;

export const DrawerBody = styled.View``;

export const DrawerFooter = styled.View`
  margin: 25px 0;
`;

export const ThemeSwitchWrapper = styled.View`
  margin-top: 20px;
`;

export const ThemeSwitch = styled.Pressable`
  border: 1px solid ${props => props.theme.color.primary};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 7px 10px;
`;

export const LightIcon = styled(EntypoIcons).attrs({
  name: 'light-up',
})`
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  margin-right: 7px;
`;

export const DarkIcon = styled(FeatherIcons).attrs({
  name: 'moon',
})`
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  margin-right: 7px;
`;

export const ThemeName = styled.Text`
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.primary};
  text-transform: capitalize;
`;

export const UserDetails = styled.View`
  padding: 10px 0;
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.color.transparentBlack1};
  justify-content: center;
  align-items: center;
`;

export const AvatarContainer = styled.View`
  height: 60px;
  width: 60px;
  background-color: ${props => props.theme.color.primary};
  border-radius: 30px;
  justify-content: center;
  align-items: center;
`;

export const AvatarContent = styled.Text`
  font-size: 32px;
  color: ${props => props.theme.color.white};
  font-family: ${props => props.theme.fonts.MontserratRegular};
  text-transform: uppercase;
`;

export const UserName = styled.Text`
  margin-top: 5px;
  font-size: 15px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const UserEmail = styled.Text`
  font-size: 12px;
  color: ${props => props.theme.color.grey};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const MenuContainer = styled.View``;

export const MenuItem = styled.TouchableOpacity`
  padding: 10px 5px;
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.color.transparentBlack1};
`;

export const NestedMenuItem = styled.View`
  padding: 10px 5px;
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.color.transparentBlack1};
`;

export const MenuText = styled.Text`
  color: ${props => props.theme.color.text};
  font-weight: 600;
  font-family: ${props => props.theme.fonts.MontserratBold};
`;

export const BottomContainer = styled.View`
  align-items: center;
`;

export const LinkText = styled.Text`
  color: ${props => props.theme.color.accent};
  font-family: ${props => props.theme.fonts.MontserratRegular};
  font-size: 12px;
`;

export const MenuLink = styled.TouchableOpacity`
  padding: 5px;
`;

export const BottomMenuContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  border-top-width: 1px;
  border-color: ${props => props.theme.color.lightGrey};
`;

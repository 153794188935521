import {
  getFocusedRouteNameFromRoute,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import React from 'react';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import {
  Container,
  MaterialMenuIcons,
  MenuItem,
  MenuLabel,
} from './index.style';
import ROUTES from '@navigation/routes';

const BottomNavbar = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const route = useRoute();
  const { navigate } = useNavigation<any>();

  if (!theme.size.isLarge) {
    return (
      <Container>
        <MenuItem onPress={() => navigate(ROUTES.APP_DASHBOARD)}>
          <MaterialMenuIcons
            name="home"
            active={route.name === ROUTES.APP_DASHBOARD}
          />
          <MenuLabel active={route.name === ROUTES.APP_DASHBOARD}>
            {t('app.screens.dashboard')}
          </MenuLabel>
        </MenuItem>
        <MenuItem onPress={() => navigate(ROUTES.APP_MY_ATTENDANCE)}>
          <MaterialMenuIcons
            name="calendar"
            active={route.name === ROUTES.APP_MY_ATTENDANCE}
          />
          <MenuLabel active={route.name === ROUTES.APP_MY_ATTENDANCE}>
            {t('app.screens.my-attendance')}
          </MenuLabel>
        </MenuItem>
        <MenuItem onPress={() => navigate(ROUTES.APP_TIMELINE)}>
          <MaterialMenuIcons
            name="timeline-clock"
            active={route.name === ROUTES.APP_TIMELINE}
          />
          <MenuLabel active={route.name === ROUTES.APP_TIMELINE}>
            {t('app.screens.timeline')}
          </MenuLabel>
        </MenuItem>
        <MenuItem onPress={() => navigate(ROUTES.APP_PROFILE)}>
          <MaterialMenuIcons
            name="account-circle-outline"
            active={route.name === ROUTES.APP_PROFILE}
          />
          <MenuLabel active={route.name === ROUTES.APP_PROFILE}>
            {t('app.screens.profile')}
          </MenuLabel>
        </MenuItem>
      </Container>
    );
  }

  return <></>;
};

export default BottomNavbar;

import { useNavigation } from '@react-navigation/native';
import {
  endOfMonth,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  startOfMonth,
} from 'date-fns';
import React from 'react';
import { Platform, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';

import { ATTENDANCE_STATUS, ATTENDANCE_TYPES } from '@constants';

import {
  getAttendanceEventLabel,
  getAttendanceStatusColor,
  getAttendanceStatusLabel,
} from '@utils/attendance';
import {
  formatDate,
  formatDateRange,
  isDateInWeekend,
  parseDate,
} from '@utils/dateTime';
import ROUTES from '@navigation/routes';
import { Attendance, Holiday } from '@types';

import {
  ActionButton,
  ActionIcon,
  Badge,
  BadgesContainer,
  BadgeText,
  DateText,
  DayWrapper,
  DescriptionHeader,
  DescriptionSection,
  DescriptionText,
  EventActions,
  EventDetails,
  EventHeader,
  EventWrapper,
  HolidayTitle,
  HolidayWrapper,
  IndividualEvent,
  LeaveDates,
  StatusBadge,
  StatusDot,
  StatusMarkingWrapper,
  StatusText,
  TooltipContainer,
  Title,
  TitleWrapper,
} from './index.style';

import {
  AttendanceBadge,
  CustomDayMarking,
} from '../../../../../../types/attendance';
import { Popable, usePopable } from '@components';

interface IProps {
  activeMonth?: string;
  date: Date;
  marking?: CustomDayMarking;
  holiday?: CustomDayMarking;
  onDateSelect?: (isShowingAttendanceEvent: boolean, holiday?: Holiday) => void;
  onAttendanceDelete: (attendance: Attendance) => void;
  onAttendanceUpdate: (attendance: Attendance) => void;
}

const CustomDay: React.FC<IProps> = props => {
  const {
    activeMonth,
    date,
    holiday,
    marking,
    onDateSelect,
    onAttendanceDelete,
    onAttendanceUpdate,
  } = props;
  const { navigate } = useNavigation<any>();
  const theme = useTheme();
  const { t } = useTranslation();
  const isInActiveMonth = activeMonth
    ? isSameDay(date, startOfMonth(parseDate(activeMonth))) ||
      (isAfter(date, startOfMonth(parseDate(activeMonth))) &&
        isBefore(date, endOfMonth(parseDate(activeMonth))))
    : false;

  const [ref, { show }] = usePopable();

  const isWeekend = isDateInWeekend(date);
  let badges: AttendanceBadge[] =
    marking && marking.badges ? marking.badges : [];
  const overtime = badges.filter(
    (badge: any) => badge.type === ATTENDANCE_TYPES.OVERTIME.key,
  );
  const isShowingAttendanceEvent =
    (marking &&
      ((!isWeekend && !holiday && isInActiveMonth) || overtime.length > 0)) ??
    false;
  const proposedBadges = badges.filter(
    badge => badge.status === ATTENDANCE_STATUS.PROPOSED,
  );
  const approvedBadges = badges.filter(
    badge => badge.status === ATTENDANCE_STATUS.APPROVED,
  );
  const rejectedBadges = badges.filter(
    badge => badge.status === ATTENDANCE_STATUS.REJECTED,
  );

  const onDayPress = () => {
    if (
      (isShowingAttendanceEvent || holiday) &&
      ((Platform.OS === 'web' && !theme.size.isLarge) ||
        Platform.OS != 'web') &&
      onDateSelect
    ) {
      onDateSelect(
        isShowingAttendanceEvent,
        holiday ? holiday.holiday : undefined,
      );
    }

    if (
      Platform.OS === 'web' &&
      theme.size.isLarge &&
      isShowingAttendanceEvent
    ) {
      show();
    }

    if (!isShowingAttendanceEvent && !holiday) {
      if (isWeekend || !isInActiveMonth) return;

      if (isAfter(date, new Date()) || isSameDay(date, new Date())) {
        navigate(ROUTES.APP_CREATE_ATTENDANCE, {
          startDate: formatDate(date, 'yyyy-MM-dd'),
        });
      }
    }
  };

  const getTooltipContent = () => {
    if (marking && marking.attendances) {
      const attendances = marking.attendances;

      return (
        <EventWrapper>
          {attendances.map(attendance => {
            return (
              <IndividualEvent key={`tooltip-${attendance.id}`}>
                <EventHeader>
                  <EventDetails>
                    <LeaveDates>
                      {formatDateRange(
                        parseDate(attendance.start),
                        parseDate(attendance.end),
                      )}
                    </LeaveDates>
                    <TitleWrapper>
                      <Title>{getAttendanceEventLabel(attendance)}</Title>
                      <StatusBadge
                        color={getAttendanceStatusColor(attendance.status)}>
                        <StatusText>
                          {getAttendanceStatusLabel(attendance.status)}
                        </StatusText>
                      </StatusBadge>
                    </TitleWrapper>
                  </EventDetails>
                  {attendance.eventType === ATTENDANCE_TYPES.LEAVE.key && (
                    <EventActions>
                      {(attendance.status === ATTENDANCE_STATUS.PROPOSED ||
                        attendance.status === ATTENDANCE_STATUS.REJECTED) && (
                        <ActionButton
                          onPress={() => onAttendanceDelete(attendance)}
                          color={theme.color.red}>
                          <ActionIcon name={'trash'} color={theme.color.red} />
                        </ActionButton>
                      )}
                      {attendance.status === ATTENDANCE_STATUS.PROPOSED && (
                        <ActionButton
                          onPress={() => onAttendanceUpdate(attendance)}
                          color={theme.color.green}>
                          <ActionIcon name={'edit'} color={theme.color.green} />
                        </ActionButton>
                      )}
                    </EventActions>
                  )}
                </EventHeader>
                {attendance && attendance.description && (
                  <DescriptionSection>
                    <DescriptionHeader>
                      {t('app.attendance.reason')}:
                    </DescriptionHeader>
                    <DescriptionText numberOfLines={5} ellipsizeMode="tail">
                      {attendance.description}
                    </DescriptionText>
                  </DescriptionSection>
                )}

                {attendance && attendance.rejectionReason && (
                  <DescriptionSection>
                    <DescriptionHeader>
                      {t('app.attendance.rejection-reason')}:
                    </DescriptionHeader>
                    <DescriptionText numberOfLines={5} ellipsizeMode="tail">
                      {attendance.rejectionReason}
                    </DescriptionText>
                  </DescriptionSection>
                )}
              </IndividualEvent>
            );
          })}
        </EventWrapper>
      );
    }
    return <></>;
  };

  const getDayBackgroundColor = () => {
    if (
      marking &&
      isShowingAttendanceEvent &&
      marking.status === ATTENDANCE_STATUS.APPROVED
    ) {
      return marking.backgroundColor ?? theme.color.white;
    }

    if (!isShowingAttendanceEvent && holiday && !isWeekend) {
      return theme.color.lightGrey;
    }

    return theme.color.white;
  };

  return (
    <TooltipContainer>
      <Popable
        ref={ref}
        content={getTooltipContent()}
        action={'press'}
        position="top"
        backgroundColor="transparent"
        strictPosition
        style={{
          width: 'auto',
          maxWidth: 300,
          height: 'auto',
          padding: 0,
          marginBottom: -14,
          zIndex: 1000,
        }}>
        <DayWrapper
          isSmallDevice={Platform.OS !== 'web' || !theme.size.isLarge}
          onPress={onDayPress}
          backgroundColor={getDayBackgroundColor()}>
          <DateText
            disabled={isWeekend || !isInActiveMonth}
            today={isToday(props.date)}>
            {props.date.getDate() === 1 && formatDate(date, 'LLL')}{' '}
            {props.date.getDate()}
          </DateText>
          {isShowingAttendanceEvent && (
            <StatusMarkingWrapper>
              {rejectedBadges.map(badge => (
                <StatusDot
                  color={getAttendanceStatusColor(badge.status)}
                  key={`status-dot-${badge.attendanceId}`}
                />
              ))}
            </StatusMarkingWrapper>
          )}
          {isShowingAttendanceEvent && (
            <BadgesContainer>
              {[...approvedBadges, ...proposedBadges].map(
                (badge: AttendanceBadge) => {
                  return (
                    <Badge
                      backgroundColor={badge.color}
                      key={`badge-${formatDate(date, 'yyyy-MM-dd')}`}>
                      <BadgeText>{badge.code}</BadgeText>
                    </Badge>
                  );
                },
              )}
            </BadgesContainer>
          )}
          {Platform.OS === 'web' &&
            theme.size.isLarge &&
            holiday &&
            !isShowingAttendanceEvent && (
              <HolidayWrapper>
                <HolidayTitle>{holiday.title}</HolidayTitle>
              </HolidayWrapper>
            )}
        </DayWrapper>
      </Popable>
    </TooltipContainer>
  );
};

export default CustomDay;

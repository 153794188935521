import { Dimensions, Platform } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.SafeAreaView`
  flex: 1;
`;

export const TimelineContainer = styled.View`
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  min-height: ${Platform.OS === 'web'
    ? `${Dimensions.get('window').height}px`
    : '100%'};
`;

export const EmptyEventContainer = styled.View`
  height: ${Dimensions.get('window').height}px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const EmptyTitleText = styled.Text`
  font-size: 16px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const DetailContainer = styled.View``;

export const DetailText = styled.Text`
  color: ${props => props.theme.color.text};
  font-size: 14px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const HeaderDateText = styled.Text`
  font-size: 13px;
  color: ${props => props.theme.color.text};
  margin-bottom: 7px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const HeaderText = styled.Text`
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 600;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratBold};
  font-weight: 600;
  line-height: 20px;
`;

export const TitleContainer = styled.View`
  flex-direction: ${Platform.OS === 'web' ? 'column' : 'row'};
  justify-content: space-between;
  width: 100%;
`;

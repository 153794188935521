import styled from 'styled-components/native';
import { Dimensions } from 'react-native';

export const Main = styled.View`
  padding-bottom: ${props => (!props.theme.size.isLarge ? 50 : 0)}px;
`;

export const Container = styled.View`
  z-index: -1;
  background: ${props => props.theme.color.white};
  min-height: ${props =>
    Dimensions.get('window').height - (props.theme.size.isLarge ? 110 : 100)}px;
  padding-left: ${props => (props.theme.size.isLarge ? 20 : 0)}px;
  padding-right: ${props => (props.theme.size.isLarge ? 10 : 0)}px;
`;

export const H1 = styled.Text`
  font-size: 24px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const Small = styled.Text`
  font-size: 16px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const PageGridContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-left: 16px;
  margin-top: 16px;
  margin-right: 16px;
`;

export const ToolbarContainer = styled.View`
  align-content: center;
  align-self: center;
`;

export const ErrorPageContainer = styled.View``;

export const ErrorMessageText = styled.Text`
  color: ${props => props.theme.color.red};
  font-size: 16px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

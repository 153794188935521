import { Dimensions } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.View`
  height: ${Dimensions.get('window').height}px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ScreenName = styled.Text`
  font-size: 16px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

import React from 'react';
import {
  CardCartIcon,
  CardContainer,
  CardHeaderContainer,
  CardHeaderTitle,
} from './index.style';

interface IProps {
  title: string;
  isExpanded: boolean;
  content: React.ReactNode;
  onToggle: (val: boolean) => void;
}

const ExpandableCard: React.FC<IProps> = ({
  title,
  content,
  isExpanded,
  onToggle,
}) => {
  const toggleExpand = () => {
    onToggle(!isExpanded);
  };

  return (
    <CardContainer>
      <CardHeaderContainer onPress={toggleExpand}>
        <CardHeaderTitle>{title}</CardHeaderTitle>
        <CardCartIcon name={isExpanded ? 'chevron-up' : 'chevron-down'} />
      </CardHeaderContainer>
      {isExpanded && content}
    </CardContainer>
  );
};

export default ExpandableCard;

import { Platform } from 'react-native';
import { REACT_APP_ENVIRONMENT, REACT_APP_VERSION } from 'react-native-dotenv';
import DeviceInfo from 'react-native-device-info';

import config from '@config';

const getAppVersion = () => {
  const isProd =
    REACT_APP_ENVIRONMENT === 'production' || REACT_APP_ENVIRONMENT === 'prod';

  if ((Platform.OS === 'android' || Platform.OS === 'ios') && isProd) {
    return `${DeviceInfo.getVersion()}+${DeviceInfo.getBuildNumber()}`;
  } else if (Platform.OS === 'web' && isProd) {
    return config?.appVersion;
  }

  return Platform.OS === 'web'
    ? config?.appVersion
    : `${REACT_APP_VERSION}-${REACT_APP_ENVIRONMENT}`;
};

export { getAppVersion };

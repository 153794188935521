import { AppConfig, configContext } from '@dzangolab/react-config';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import type { Loan } from 'core';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Platform, SafeAreaView } from 'react-native';

import ROUTES from '@navigation/routes';
import { useLazyGetMyLoansQuery } from '@redux/loans/api';
import { CenteredMessage, FloatingButton, Loading } from '@components';
import { getLoanDuration, getLoanTitle, getLoanStatus } from '@utils/loans';
import { formatCurrency } from '@utils/NumberFormat';
import PageMain from '@components/Page';

import {
  Amount,
  AmountItem,
  AmountLabel,
  AmountRow,
  Container,
  Duration,
  Heading,
  LoanStatus,
  LoanType,
  LoanWrapper,
} from './index.style';

interface IProps {
  loans: Loan[];
  setLoans: (loans: Loan[]) => void;
}

const Loans: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<any>();
  const { loans, setLoans } = props;
  const isFocused = useIsFocused();
  const config = useContext(configContext) as AppConfig;

  const [loanTrigger, { data: loansData, isFetching: isFetchingLoans }] =
    useLazyGetMyLoansQuery();

  const renderLoanItem = (loan: Loan) => {
    return (
      <LoanWrapper
        key={loan.id}
        onPress={() =>
          navigate(ROUTES.APP_LOAN_DETAILS, {
            loanId: loan.id,
            ...(Platform.OS !== 'web' && { title: getLoanTitle(loan) }),
          })
        }>
        <Heading>
          <LoanType>{getLoanTitle(loan)}</LoanType>
          <LoanStatus>{getLoanStatus(loan.status)}</LoanStatus>
        </Heading>
        <AmountRow>
          <AmountItem>
            <AmountLabel>{t('app.loan.amount')}</AmountLabel>
            <Amount>{formatCurrency(loan.amount, '')}</Amount>
          </AmountItem>
          <AmountItem>
            <AmountLabel>{t('app.loan.balance')}</AmountLabel>
            <Amount>
              {loan.outstandingAmount
                ? formatCurrency(loan.outstandingAmount, '')
                : '~'}
            </Amount>
          </AmountItem>
        </AmountRow>
        <Duration>{getLoanDuration(loan)}</Duration>
      </LoanWrapper>
    );
  };

  useEffect(() => {
    loanTrigger({});
  }, [isFocused]);

  useEffect(() => {
    if (loansData) {
      setLoans(loansData);
    }
  }, [loansData]);

  const getLoanList = () => {
    if (isFetchingLoans) {
      return <Loading />;
    }

    if (loans.length > 0)
      return (
        <FlatList
          renderItem={({ item }) => renderLoanItem(item)}
          data={loans}
        />
      );

    if (loans.length === 0)
      return <CenteredMessage message={t('app.loan.no-records')} />;
  };

  if (Platform.OS === 'web') {
    return (
      <PageMain loading={isFetchingLoans} title={t('app.screens.loans')}>
        <Container>{getLoanList()}</Container>
        {config.features.requestLoan && (
          <FloatingButton
            onPress={() => {
              navigate(ROUTES.APP_REQUEST_LOAN);
            }}
          />
        )}
      </PageMain>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Container>
        {getLoanList()}
        {config.features.requestLoan && (
          <FloatingButton
            onPress={() => {
              navigate(ROUTES.APP_REQUEST_LOAN);
            }}
          />
        )}
      </Container>
    </SafeAreaView>
  );
};

export default Loans;

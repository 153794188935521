import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SuperToken from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';

import { Caret, DzangoLogo } from '@commons/styles';
import ROUTES from '@navigation/routes';
import { useAppDispatch, useAppSelector } from '@redux/hook';
import { logoutUser } from '@redux/auth/slice';
import { ThemeContext } from '@theme';
import { ThemeType } from '@types';

import {
  Container,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuText,
  MenuContainer,
  ThemeIcon,
  ThemeSwitch,
  User,
  UserDetails,
} from './index.style';
import MenuBar from './menubar';
import OutsidePressHandler from 'react-native-outside-press';

const DesktopNav = () => {
  const dispatch = useAppDispatch();
  const { navigate } = useNavigation<any>();
  const { t } = useTranslation();
  const theme = useTheme();
  const { changeAppTheme } = useContext(ThemeContext);

  const user = useAppSelector(state => state.auth.user);
  const [showDropDown, setShowDropDown] = useState<{ [key: string]: boolean }>(
    {},
  );

  const getName = () => {
    if (user?.middleName) {
      return `${user?.givenName} ${user?.middleName} ${user?.surname}`;
    }

    return `${user?.givenName} ${user?.surname}`;
  };

  const changeTheme = (theme: ThemeType | 'DEFAULT') => {
    changeAppTheme(theme);
    setShowDropDown({
      theme: false,
    });
  };

  const signOut = async () => {
    await SuperToken.signOut();
    dispatch(logoutUser());
  };

  return (
    <Container>
      <MenuContainer>
        <DzangoLogo height={45} width={150} />
        <MenuBar />
      </MenuContainer>
      <OutsidePressHandler onOutsidePress={() => setShowDropDown({})}>
        <MenuContainer>
          <ThemeSwitch
            onPress={() => {
              setShowDropDown({
                theme: !showDropDown['theme'],
              });
            }}>
            <ThemeIcon />
            <Caret rotate={showDropDown['theme']} />
            {showDropDown['theme'] && (
              <DropdownMenu>
                <DropdownMenuItem onPress={() => changeTheme(ThemeType.LIGHT)}>
                  <DropdownMenuText>{t('app.themes.light')}</DropdownMenuText>
                </DropdownMenuItem>
                <DropdownMenuItem onPress={() => changeTheme(ThemeType.DARK)}>
                  <DropdownMenuText>{t('app.themes.dark')}</DropdownMenuText>
                </DropdownMenuItem>
                <DropdownMenuItem
                  borderLess
                  onPress={() => changeTheme('DEFAULT')}>
                  <DropdownMenuText>{t('app.themes.default')}</DropdownMenuText>
                </DropdownMenuItem>
              </DropdownMenu>
            )}
          </ThemeSwitch>
          <UserDetails
            onPress={() => {
              setShowDropDown({
                profile: !showDropDown['profile'],
              });
            }}>
            {user?.givenName ? (
              <User>{getName()}</User>
            ) : (
              <User>{user?.email}</User>
            )}
            <Caret rotate={showDropDown['profile']} />
            {showDropDown['profile'] && (
              <DropdownMenu>
                <DropdownMenuItem onPress={() => navigate(ROUTES.APP_PROFILE)}>
                  <DropdownMenuText>Profile</DropdownMenuText>
                </DropdownMenuItem>
                <DropdownMenuItem borderLess onPress={signOut}>
                  <DropdownMenuText>{t('app.sign-out')}</DropdownMenuText>
                </DropdownMenuItem>
              </DropdownMenu>
            )}
          </UserDetails>
        </MenuContainer>
      </OutsidePressHandler>
    </Container>
  );
};

export default DesktopNav;

import React, { useEffect, useState } from 'react';
import {
  FlatList,
  Modal,
  Platform,
  SafeAreaView,
  TouchableOpacity,
  View,
} from 'react-native';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'react-native-paper';
import Icon from 'react-native-vector-icons/Octicons';
import Toast from 'react-native-toast-message';

import PageMain from '@components/Page';
import EventCard from '@components/EventCard';
import CenteredMessage from '@components/CenteredMessage';
import ConfirmationDialog from '@components/ConfirmationDialog';
import Loading from '@components/Loading';
import { useLazyGetDocumentsQuery } from '@redux/apis/documents';
import { downloadFile, shareFile } from '@utils/MediaManager';
import type { EmployeeDocument } from 'core';
import { ModalOverlay } from '@commons/styles';

import {
  ActionButton,
  ConfirmationDialogContainer,
  ConfirmationText,
  Container,
  DateContainer,
  FlatListContainer,
  HeaderDateContainer,
  HeaderDateText,
  HeaderText,
  ModalHeader,
  ModalHeaderContainer,
  ModalHeaderTitle,
  TitleContainer,
  WebContainer,
} from './index.style';
import { BottomSheet } from '@components';
import FileViewer from '@components/FileViewer';

const OtherDocsScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showDownloadConfirmation, setShowDownloadConfirmation] =
    useState(false);
  const [downloadProgress, setDownloadProgress] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<EmployeeDocument>();
  const [documentsTrigger, { data: documentsData, isFetching }] =
    useLazyGetDocumentsQuery();

  useEffect(() => {
    documentsTrigger();
  }, []);

  const handleConfirmation = async (confirmed: boolean) => {
    if (confirmed && selectedDocument) {
      const download = await downloadFile(selectedDocument.downloadUrl);

      if (download) {
        setDownloadProgress(false);
      }
    } else {
      setDownloadProgress(false);
      setShowDownloadConfirmation(false);
      setSelectedDocument(undefined);
    }
  };

  const handleDownloadAction = (item: EmployeeDocument) => {
    if (downloadProgress) {
      Toast.show({
        type: 'info',
        text1: t('app.documents.download-progress') || '',
      });
    } else {
      setSelectedDocument(item);
      setDownloadProgress(true);
      setShowDownloadConfirmation(true);
    }
  };

  const downloadDialogBox = (
    <Modal
      animationType={'fade'}
      transparent
      visible={showDownloadConfirmation}
      supportedOrientations={['landscape', 'portrait']}>
      <ModalOverlay>
        <ConfirmationDialog
          title={t(`app.documents.alert.title`)}
          confirmLabel={t(`app.documents.alert.btn-confirm`)}
          onConfirm={() => {
            setShowDownloadConfirmation(false);
            handleConfirmation(true);
          }}
          onCancel={() => handleConfirmation(false)}
          cancelLabel={t(`app.documents.alert.btn-cancel`)}
          color={theme.color.primary}>
          <ConfirmationDialogContainer>
            <ConfirmationText>
              {t('app.documents.alert.message')}
            </ConfirmationText>
          </ConfirmationDialogContainer>
        </ConfirmationDialog>
      </ModalOverlay>
    </Modal>
  );

  const renderDocumentItem = ({
    item,
    index,
  }: {
    item: EmployeeDocument;
    index: number;
  }) => (
    <FlatListContainer key={index}>
      <EventCard>
        <View
          style={{
            flex: 1,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <View style={{ flex: 1, paddingEnd: 10 }}>
            <DateContainer>
              <HeaderDateContainer>
                <HeaderDateText>{item.type}</HeaderDateText>
              </HeaderDateContainer>
            </DateContainer>
            <TitleContainer>
              <HeaderText numberOfLines={1}>{item.description}</HeaderText>
            </TitleContainer>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <ActionButton
              color={theme.color.green}
              onPress={() => {
                setShowPDF(true);
                setSelectedDocument(item);
              }}>
              <Icon name="eye" size={16} color={theme.color.green} />
            </ActionButton>
            {Platform.OS !== 'web' && (
              <ActionButton
                color={theme.color.accent}
                onPress={async () => {
                  await shareFile(item.url);
                }}>
                <Icon
                  name="share-android"
                  size={16}
                  color={theme.color.accent}
                />
              </ActionButton>
            )}
            <ActionButton
              color={theme.color.primary}
              onPress={() => handleDownloadAction(item)}
              style={{ marginRight: 0 }}>
              <Icon name="download" size={16} color={theme.color.primary} />
            </ActionButton>
          </View>
        </View>
      </EventCard>
    </FlatListContainer>
  );

  const showDocument = () => {
    if (selectedDocument && selectedDocument.url) {
      return (
        <BottomSheet
          animationType={Platform.select({
            web: 'none',
            default: 'slide',
          })}
          visible={showPDF}
          handleToggleModal={() => setShowPDF(!showPDF)}
          modalOptionsContainerStyle={{
            height: '100%',
            borderRadius: 0,
            ...(Platform.OS === 'web' && {
              alignSelf: theme.size.isLarge ? 'center' : 'auto',
              height: theme.size.isLarge ? '90%' : '100%',
              minWidth: '50%',
            }),
          }}
          modalBackgroundStyle={{
            ...Platform.select({
              web: {
                justifyContent: 'center',
              },
              default: {
                backgroundColor: theme.color.white,
              },
            }),
          }}
          headerComponent={
            <ModalHeaderContainer>
              <ActionButton
                color={theme.color.primary}
                onPress={() => {
                  if (selectedDocument) {
                    downloadFile(selectedDocument.downloadUrl);
                  }
                }}>
                <Icon name="download" size={16} color={theme.color.primary} />
              </ActionButton>
              <ModalHeader>
                <ModalHeaderTitle>
                  {theme.size.isLarge
                    ? `${t(`app.screens.documents`)} - ${
                        selectedDocument?.description
                      }`
                    : selectedDocument?.description}
                </ModalHeaderTitle>
              </ModalHeader>
              <TouchableOpacity
                onPress={() => setShowPDF(!showPDF)}
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                }}>
                <Icon
                  name={Platform.select({
                    web: 'x-circle-fill',
                    default: 'chevron-left',
                  })}
                  size={26}
                  color={theme.color.text}
                />
              </TouchableOpacity>
            </ModalHeaderContainer>
          }
          customContent={
            <React.Suspense fallback={<Loading />}>
              <FileViewer url={selectedDocument.url} />
            </React.Suspense>
          }></BottomSheet>
      );
    }

    return <></>;
  };

  const DocumentComponent =
    documentsData && documentsData.length > 0 ? (
      theme.size.isLarge ? (
        <DataTable
          style={{
            backgroundColor: theme.color.white,
            marginLeft: 15,
            width: '98%',
          }}>
          <DataTable.Header>
            <DataTable.Title
              textStyle={{
                color: theme.color.text,
                fontFamily: theme.fonts.MontserratRegular,
              }}
              style={{ maxWidth: 200 }}>
              {t('app.documents.table-cell-title.type')}
            </DataTable.Title>
            <DataTable.Title
              textStyle={{
                color: theme.color.text,
                fontFamily: theme.fonts.MontserratRegular,
              }}>
              {t('app.documents.table-cell-title.description')}
            </DataTable.Title>
            <DataTable.Title
              style={{
                maxWidth: 120,
                justifyContent: 'center',
                alignContent: 'center',
              }}>
              <Icon name="gear" size={20} color={theme.color.text} />
            </DataTable.Title>
          </DataTable.Header>
          {documentsData.map((item, index) => (
            <DataTable.Row style={{ borderBottomWidth: 0.5 }} key={index}>
              <DataTable.Cell
                textStyle={{
                  color: theme.color.text,
                  fontFamily: theme.fonts.MontserratRegular,
                }}
                style={{ maxWidth: 200 }}>
                {item.type}
              </DataTable.Cell>
              <DataTable.Cell
                textStyle={{
                  color: theme.color.text,
                  fontFamily: theme.fonts.MontserratRegular,
                }}>
                {item.description}
              </DataTable.Cell>
              <DataTable.Cell
                style={{
                  maxWidth: 110,
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
                textStyle={{
                  color: theme.color.text,
                  fontFamily: theme.fonts.MontserratRegular,
                }}>
                <ActionButton
                  color={theme.color.green}
                  onPress={() => {
                    setShowPDF(true);
                    setSelectedDocument(item);
                  }}>
                  <Icon name="eye" size={16} color={theme.color.green} />
                </ActionButton>
                <ActionButton
                  color={theme.color.primary}
                  onPress={() => handleDownloadAction(item)}>
                  <Icon name="download" size={16} color={theme.color.primary} />
                </ActionButton>
              </DataTable.Cell>
            </DataTable.Row>
          ))}
        </DataTable>
      ) : (
        <FlatList data={documentsData} renderItem={renderDocumentItem} />
      )
    ) : (
      <CenteredMessage message={t('app.documents.empty-message')} />
    );

  return Platform.OS === 'web' ? (
    <PageMain title={t(`app.screens.other-docs`)} loading={isFetching}>
      <WebContainer>
        {DocumentComponent}
        {downloadDialogBox}
        {showDocument()}
      </WebContainer>
    </PageMain>
  ) : (
    <SafeAreaView style={{ flex: 1 }}>
      <Container>
        {isFetching ? <Loading /> : DocumentComponent}
        {downloadDialogBox}
        {showDocument()}
      </Container>
    </SafeAreaView>
  );
};

export default OtherDocsScreen;

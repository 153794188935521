import { addDays, format, differenceInDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Modal, TouchableWithoutFeedback } from 'react-native';
import { Calendar, DateData } from 'react-native-calendars';
import MaterialIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useTheme } from 'styled-components/native';

import InputField from './components/InputField';
import {
  BottomContainer,
  CalenderContainer,
  CloseIcon,
  ModelButton,
  ModelButtonText,
  ModelContainer,
} from './index.style';

interface IProps {
  onSuccess: (startDate: string, endDate: string) => void;
  labelText?: string | null;
  showLabel?: boolean;
  placeholder?: string;
  value?: string;
  fromDate?: string;
  toDate?: string;
  disablePastMonth?: boolean;
}

const DateRangePicker: React.FC<IProps> = ({
  onSuccess,
  showLabel,
  labelText,
  placeholder,
  value,
  fromDate,
  toDate,
  disablePastMonth,
}) => {
  const theme = useTheme();
  const [modalVisible, setModalVisible] = useState(false);
  const [state, setState] = useState({
    isFromDatePicked: false,
    isToDatePicked: false,
    markedDates: {},
    fromDate: format(new Date(), 'yyyy-MM-dd'),
    toDate: format(new Date(), 'yyyy-MM-dd') || undefined,
  });

  const onDayPress = (day: DateData) => {
    if (
      !state.isFromDatePicked ||
      (state.isFromDatePicked && state.isToDatePicked)
    ) {
      setupStartMarker(day);
    } else if (!state.isToDatePicked) {
      let markedDates = { ...state.markedDates };
      let [mMarkedDates, range] = setupMarkedDates(
        state.fromDate,
        day.dateString,
        markedDates,
      );
      if (range >= 0) {
        setState({
          ...state,
          isFromDatePicked: true,
          isToDatePicked: true,
          markedDates: mMarkedDates,
          toDate: day.dateString,
        });
      } else {
        setupStartMarker(day);
      }
    }
  };

  const setupStartMarker = (day: DateData) => {
    let markedDates = {
      [day.dateString]: {
        startingDay: true,
        color: theme.color.primary,
        textColor: theme.color.white,
      },
    };

    setState({
      ...state,
      isFromDatePicked: true,
      isToDatePicked: false,
      fromDate: day.dateString,
      toDate: undefined,
      markedDates: markedDates,
    });
  };

  const setupMarkedDates = (
    fromDate: string,
    toDate: string,
    markedDates: any,
  ) => {
    let mFromDate = new Date(fromDate);
    let mToDate = new Date(toDate);
    let range = differenceInDays(mToDate, mFromDate);
    if (range >= 0) {
      if (range == 0) {
        markedDates = {
          [toDate]: {
            color: theme.color.primary,
            textColor: theme.color.white,
          },
        };
      } else {
        for (let i = 1; i <= range; i++) {
          let tempDate = format(addDays(mFromDate, i), 'yyyy-MM-dd');
          if (i < range) {
            markedDates[tempDate] = {
              color: theme.color.primary,
              textColor: theme.color.white,
            };
          } else {
            markedDates[tempDate] = {
              endingDay: true,
              color: theme.color.primary,
              textColor: theme.color.white,
            };
          }
        }
      }
    }
    return [markedDates, range];
  };

  const closeModel = () => {
    setModalVisible(!modalVisible);
    setState({
      ...state,
      markedDates: {},
      isToDatePicked: false,
      isFromDatePicked: false,
    });
  };

  const renderArrow = (direction: string) => {
    if (direction === 'left') {
      return (
        <MaterialIcons
          name={'chevron-left'}
          size={22}
          color={theme.color.text}
        />
      );
    } else {
      return (
        <MaterialIcons
          name={'chevron-right'}
          size={22}
          color={theme.color.text}
        />
      );
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      let markedDates = {
        [fromDate]: {
          startingDay: true,
          color: theme.color.primary,
          textColor: theme.color.white,
        },
      };

      let [mMarkedDates, range] = setupMarkedDates(
        fromDate,
        toDate,
        markedDates,
      );

      if (range >= 0) {
        setState({
          ...state,
          isFromDatePicked: true,
          isToDatePicked: true,
          markedDates: mMarkedDates,
          toDate: toDate,
          fromDate: fromDate,
        });
      } else {
        setupStartMarker({
          dateString: fromDate,
          year: 0,
          month: 0,
          day: 0,
          timestamp: 0,
        });
      }
    }
  }, [modalVisible]);

  return (
    <>
      <InputField
        label={labelText}
        placeholder={placeholder}
        selectedItem={value}
        handleToggleModal={() => setModalVisible(!modalVisible)}
        disable={true}
      />
      <Modal
        animationType="slide"
        transparent
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
        supportedOrientations={['landscape', 'portrait']}>
        <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
          <ModelContainer>
            <CalenderContainer>
              <Calendar
                markingType="period"
                style={{
                  width: 300,
                }}
                current={fromDate}
                markedDates={state.markedDates}
                onDayPress={onDayPress}
                renderArrow={renderArrow}
                theme={{
                  monthTextColor: theme.color.text,
                  calendarBackground: theme.color.white,
                  todayTextColor: theme.color.primary,
                  textDayFontFamily: theme.fonts.MontserratRegular,
                  textDayHeaderFontFamily: theme.fonts.MontserratRegular,
                  textDisabledColor: theme.color.lightGrey2,
                  dayTextColor: theme.color.text,
                }}
                {...(disablePastMonth && {
                  minDate: format(new Date(), 'yyyy-MM-dd'),
                })}
              />
              <BottomContainer>
                <ModelButton onPress={closeModel}>
                  <ModelButtonText>Cancel</ModelButtonText>
                </ModelButton>
                <ModelButton
                  onPress={() => {
                    closeModel();
                    onSuccess(state.fromDate, state.toDate || state.fromDate);
                  }}>
                  <ModelButtonText>Continue</ModelButtonText>
                </ModelButton>
              </BottomContainer>
            </CalenderContainer>
          </ModelContainer>
        </TouchableWithoutFeedback>
      </Modal>
    </>
  );
};

export default DateRangePicker;

import { RootState } from '../store';

export const getAllAttendance = (store: RootState) =>
  store.generalAttendance.attendances;
export const getAttendanceMarkings = (store: RootState) =>
  store.generalAttendance.attendanceMarkings;
export const getEmployees = (store: RootState) =>
  store.generalAttendance.employees;
export const getHolidays = (store: RootState) =>
  store.generalAttendance.holidays;
export const getActiveMonths = (store: RootState) =>
  store.generalAttendance.activeMonths;

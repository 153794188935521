import { Dimensions, Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

const LoadingContainer = styled.View`
  align-items: center;
  background-color: ${props =>
    Platform.OS === 'web'
      ? props.theme.color.white
      : props.theme.color.background};
  justify-content: center;

  ${Platform.OS !== 'web' &&
  `
    flex: 1;
  `}

  ${Platform.OS === 'web' &&
  css`
    min-height: ${Dimensions.get('window').height}px;
  `};
`;

export { LoadingContainer };

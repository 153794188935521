import styled from 'styled-components/native';

export const Container = styled.View`
  height: 20px;
  background: ${props => props.theme.color.white};
  padding: 0 20px;
  flex-direction: row;
  justify-content: right;
  z-index: -1;
`;

import React from 'react';
import { View } from 'react-native';

import { EmployeeEvent } from '@types';

import { DetailContainer, DetailText } from '../index.style';

interface IProps {
  event: EmployeeEvent;
}

const EventDetail: React.FC<IProps> = ({ event }) => {
  return (
    <DetailContainer>
      <View style={{ flexDirection: 'row', marginBottom: 10 }}>
        {event.groupCompany?.code && (
          <DetailText>{event.groupCompany?.code}</DetailText>
        )}
        {event.groupCompany?.code && event.gradeName && (
          <DetailText> - </DetailText>
        )}
        {event.gradeName && <DetailText>{event.gradeName}</DetailText>}
      </View>
      {event.description && (
        <DetailText style={{ fontSize: 12 }}>{event.description} </DetailText>
      )}
    </DetailContainer>
  );
};

export default EventDetail;

import { Dimensions, Platform } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  background: ${props => props.theme.color.white};
`;

export const Item = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: ${(props: { noBorder?: boolean }) =>
    props.noBorder ? '0px' : '1px'};
  border-bottom-color: ${props => props.theme.color.transparentBlack1};
  padding: 7px 0;
`;

export const ItemLabel = styled.Text`
  font-size: 14px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.lightGrey4};
  max-width: 50%;
`;

export const ItemValue = styled.Text`
  font-size: 14px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.black};
  flex: 1;
  margin-left: 10px;
  text-align: right;
`;

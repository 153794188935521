import React from 'react';
import { PrivacyContainer, WebContainer } from './index.style';

const PrivacyPolicy = () => {
  const policyComponent = (
    <PrivacyContainer showsVerticalScrollIndicator={false}>
      <>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            textAlign: 'center',
            lineHeight: '150%',
          }}>
          <strong>
            <span style={{ fontFamily: 'Garamond' }}>PRIVACY POLICY</span>
          </strong>
        </p>
        <p
          style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: '150%' }}>
          <span style={{ fontFamily: 'Garamond' }}>&nbsp;</span>
        </p>
        <p
          style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: '150%' }}>
          <span style={{ fontFamily: 'Garamond' }}>Effective Date: [</span>
          <em>
            <span style={{ fontFamily: 'Garamond' }}>07/08/2023</span>
          </em>
          <span style={{ fontFamily: 'Garamond' }}>]</span>
        </p>
        <p
          style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: '150%' }}>
          <span style={{ fontFamily: 'Garamond' }}>&nbsp;</span>
        </p>
        <p
          style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: '150%' }}>
          <span style={{ fontFamily: 'Garamond' }}>
            This Privacy Policy (the&nbsp;
          </span>
          <strong>
            <span style={{ fontFamily: 'Garamond' }}>“Policy”</span>
          </strong>
          <span style={{ fontFamily: 'Garamond' }}>
            ) sets forth the terms and conditions governing the collection, use,
            disclose, and protection of personal information of employees who
            use the 8BIT Employee App (referred to as the&nbsp;
          </span>
          <strong>
            <span style={{ fontFamily: 'Garamond' }}>“App”</span>
          </strong>
          <span style={{ fontFamily: 'Garamond' }}>
            ). This Policy is entered into by and between Dzango, a Hong
            Kong-based IT company, and its affiliates, including 8BIT
            (collectively referred to as “we,” “us,” or “our”), and the
            employees of 8BIT (referred to as “you,” “users,” or “employees”).
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '18pt',
            marginBottom: '0pt',
            lineHeight: '150%',
          }}>
          <strong>
            <span style={{ fontFamily: 'Garamond' }}>&nbsp;</span>
          </strong>
        </p>
        <ol type={'1'} style={{ margin: '5pt', paddingLeft: '0pt' }}>
          <li
            style={{
              marginLeft: '12.88pt',
              lineHeight: '150%',
              paddingLeft: '5.12pt',
              fontFamily: 'Garamond',
              fontWeight: 'bold',
            }}>
            Data Collection:
            <ol
              type={'1'}
              style={{
                marginRight: '0pt',
                marginLeft: '0pt',
                paddingLeft: '0pt',
              }}>
              <li
                style={{
                  marginLeft: '21.6pt',
                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '5.1pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                The App does not directly collect any data from users, except
                for essential cookies required for the functioning of the web
                app.&nbsp; The App solely provides employees with access to the
                data stored in our HRIS system, which includes information
                collected when the employee joined us or added later through the
                HRIS system including but not limited to evaluations,
                promotions, and revisions. The App may also collect personal
                information from employees when they use the App, through the
                completion of forms and interactions within the App.
              </li>
              <li
                style={{
                  marginLeft: '21.6pt',
                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '5.1pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                The data held, accessed, collected by the App may include, but
                is not limited to, the following categories of data:
                <ol
                  type={'1'}
                  style={{
                    marginRight: '0pt',
                    marginLeft: '0pt',
                    paddingLeft: '0pt',
                  }}>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    Personal identification data (such as contact information,
                    name, employee ID, email address, and phone number);
                  </li>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    Leave applications and related detail;
                  </li>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    Pay slips and related financial information;
                  </li>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    Performance evaluation records, promotions, raises, and
                    revisions.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '18pt',
            marginBottom: '0pt',
            lineHeight: '150%',
          }}>
          <strong>
            <span style={{ fontFamily: 'Garamond' }}>&nbsp;</span>
          </strong>
        </p>
        <ol start={2} type={'1'} style={{ margin: '5pt', paddingLeft: '0pt' }}>
          <li
            style={{
              marginLeft: '13.75pt',
              lineHeight: '150%',
              paddingLeft: '4.25pt',
              fontFamily: 'Garamond',
              fontWeight: 'bold',
            }}>
            Use of Collected Data:
            <ol
              type={'1'}
              style={{
                marginRight: '0pt',
                marginLeft: '0pt',
                paddingLeft: '0pt',
              }}>
              <li
                style={{
                  marginLeft: '21.6pt',

                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '5.1pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                The data stored in the App through the HRIS system including the
                data collected through the App will be used by Dzango and its
                affiliates, including 8BIT.
              </li>
              <li
                style={{
                  marginLeft: '21.6pt',

                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '5.1pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                We may use the collected data including but not limited for the
                following purposes:
                <ol
                  type={'1'}
                  style={{
                    marginRight: '0pt',
                    marginLeft: '0pt',
                    paddingLeft: '0pt',
                  }}>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    Leave management and processing, including approving or
                    denying leave requests;
                  </li>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    Generating pay slips and managing payroll;
                  </li>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    Performance evaluation and career development discussions;
                  </li>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    Providing support and responding to employee inquiries;
                  </li>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    Compliance with legal and regulatory requirements.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li
            style={{
              marginLeft: '13.75pt',
              lineHeight: '150%',
              paddingLeft: '4.25pt',
              fontFamily: 'Garamond',
              fontWeight: 'bold',
            }}>
            Usage of Cookies or Web Beacons:
            <ol
              type={'1'}
              style={{
                marginRight: '0pt',
                marginLeft: '0pt',
                paddingLeft: '0pt',
              }}>
              <li
                style={{
                  marginLeft: '21.6pt',
                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '5.1pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                We may use cookies or similar technologies to enhance the
                functionality of the web App version and improve your user
                experience. These cookies are essential for providing the App’s
                core features and do not track or collect any personal
                information. However, these technologies may collect certain
                information automatically, such as your device’s IP address,
                browser type, and operating system. By using the App, you
                consent to the use of such technologies. You have the option to
                disable cookies through your web browser settings, but this may
                affect the web App’s functionality or you may even choose to use
                the mobile App version which doesn’t utilize any cookies as
                such.
              </li>
            </ol>
          </li>
        </ol>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '18pt',
            marginBottom: '0pt',
            lineHeight: '150%',
          }}>
          <strong>
            <span style={{ fontFamily: 'Garamond' }}>&nbsp;</span>
          </strong>
        </p>
        <ol start={4} type={'1'} style={{ margin: '5pt', paddingLeft: '0pt' }}>
          <li
            style={{
              marginLeft: '13.75pt',
              lineHeight: '150%',
              paddingLeft: '4.25pt',
              fontFamily: 'Garamond',
              fontWeight: 'bold',
            }}>
            Third-Party Plugins and Data Collection:
            <ol
              type={'1'}
              style={{
                marginRight: '0pt',
                marginLeft: '0pt',
                paddingLeft: '0pt',
              }}>
              <li
                style={{
                  marginLeft: '21.6pt',

                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '5.1pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                The App does not employ third-party plugins or services for data
                collection purposes. We do not engage in the collection of
                personal information through third-party plugins or services
                within the App. We do not track users as a commercial app would
                do. The App merely provides the employees with access to the
                data stored in our HRIS system.
              </li>
            </ol>
          </li>
        </ol>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '18pt',
            marginBottom: '0pt',
            lineHeight: '150%',
          }}>
          <strong>
            <span style={{ fontFamily: 'Garamond' }}>&nbsp;</span>
          </strong>
        </p>
        <ol start={5} type={'1'} style={{ margin: '5pt', paddingLeft: '0pt' }}>
          <li
            style={{
              marginLeft: '13.75pt',
              lineHeight: '150%',
              paddingLeft: '4.25pt',
              fontFamily: 'Garamond',
              fontWeight: 'bold',
            }}>
            Sharing of Data:
            <ol
              type={'1'}
              style={{
                marginRight: '0pt',
                marginLeft: '0pt',
                paddingLeft: '0pt',
              }}>
              <li
                style={{
                  marginLeft: '21.6pt',

                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '5.1pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                We do not share the data accessed through the App with any third
                parties, except for the following circumstances:
                <ol
                  type={'1'}
                  style={{
                    marginRight: '0pt',
                    marginLeft: '0pt',
                    paddingLeft: '0pt',
                  }}>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    With Dzango and its affiliates, including 8BIT, for the
                    purposes stated in this Policy;
                  </li>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    With service providers who assist us in operating the App
                    and providing related services such as HRIS system
                    maintenance;
                  </li>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    When required by law or in response to a valid legal
                    request;
                  </li>
                  <li style={{ marginLeft: '21.6pt' }}>
                    <span
                      style={{
                        width: '0.45pt',
                        font: '7pt "Times New Roman"',
                        display: 'inline-block',
                      }}>
                      &nbsp;
                    </span>
                    In the event of a merger, acquisition, or sale of all or a
                    portion of our assets.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '18pt',
            marginBottom: '0pt',
            lineHeight: '150%',
          }}>
          <strong>
            <span style={{ fontFamily: 'Garamond' }}>&nbsp;</span>
          </strong>
        </p>
        <ol start={6} type={'1'} style={{ margin: '5pt', paddingLeft: '0pt' }}>
          <li
            style={{
              marginLeft: '13.75pt',
              lineHeight: '150%',
              paddingLeft: '4.25pt',
              fontFamily: 'Garamond',
              fontWeight: 'bold',
            }}>
            Opting Out of Data Collection:
            <ol
              type={'1'}
              style={{
                marginRight: '0pt',
                marginLeft: '0pt',
                paddingLeft: '0pt',
              }}>
              <li
                style={{
                  marginLeft: '21.6pt',
                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '5.1pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                Since the App does not collect any data directly from users,
                there is no opt-out process required. Nevertheless, since the
                App is designed for employee use and certain data collection
                might be necessary for its functionality such as requesting
                leave via the App, you may choose not to use specific features
                or decline to provide certain information. However, please note
                that opting out of essential data collection may limit your
                ability to use certain App features effectively.
              </li>
            </ol>
          </li>
        </ol>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '18pt',
            marginBottom: '0pt',
            lineHeight: '150%',
          }}>
          <strong>
            <span style={{ fontFamily: 'Garamond' }}>&nbsp;</span>
          </strong>
        </p>
        <ol start={7} type={'1'} style={{ margin: '5pt', paddingLeft: '0pt' }}>
          <li
            style={{
              marginLeft: '13.75pt',
              lineHeight: '150%',
              paddingLeft: '4.25pt',
              fontFamily: 'Garamond',
              fontWeight: 'bold',
            }}>
            Data Deletion or Removal:
            <ol
              type={'1'}
              style={{
                marginRight: '0pt',
                marginLeft: '0pt',
                paddingLeft: '0pt',
              }}>
              <li
                style={{
                  marginLeft: '21.6pt',
                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '5.1pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                We do not undertake to delete or remove the data accessed
                through the App at employees’ request. The data stored in our
                HRIS system is subject to our internal data retention policies
                and legal obligations. However, we will retain the collected
                data for only as long as necessary to fulfill the purposes
                outlined in this Policy or as required by applicable laws and
                regulations.
              </li>
            </ol>
          </li>
        </ol>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '18pt',
            marginBottom: '0pt',
            lineHeight: '150%',
          }}>
          <strong>
            <span style={{ fontFamily: 'Garamond' }}>&nbsp;</span>
          </strong>
        </p>
        <ol start={8} type={'1'} style={{ margin: '5pt', paddingLeft: '0pt' }}>
          <li
            style={{
              marginLeft: '13.75pt',
              lineHeight: '150%',
              paddingLeft: '4.25pt',
              fontFamily: 'Garamond',
              fontWeight: 'bold',
            }}>
            Data Transfer to Other Jurisdictions:
            <ol
              type={'1'}
              style={{
                marginRight: '0pt',
                marginLeft: '0pt',
                paddingLeft: '0pt',
              }}>
              <li
                style={{
                  marginLeft: '21.6pt',
                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '5.1pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                By using the App, you consent that your personal information
                stored or collected through the App might be processed,
                transferred and stored in Nepal and/or Hong-Kong or other
                jurisdictions where Dzango or its affiliates operate which may
                have data protection laws different from your country of
                residence.
              </li>
            </ol>
          </li>
        </ol>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '39.6pt',
            marginBottom: '0pt',
            lineHeight: '150%',
          }}>
          <strong>
            <span style={{ fontFamily: 'Garamond' }}>&nbsp;</span>
          </strong>
        </p>
        <ol start={9} type={'1'} style={{ margin: '5pt', paddingLeft: '0pt' }}>
          <li
            style={{
              marginLeft: '13.75pt',
              lineHeight: '150%',
              paddingLeft: '4.25pt',
              fontFamily: 'Garamond',
              fontWeight: 'bold',
            }}>
            Security Measures:
            <ol
              type={'1'}
              style={{
                marginRight: '0pt',
                marginLeft: '0pt',
                paddingLeft: '0pt',
              }}>
              <li
                style={{
                  marginLeft: '22.5pt',
                  marginBottom: '8pt',
                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '8.62pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                We implement reasonable technical, physical, and organizational
                measures to protect personal information and confidential
                information against unauthorized access, disclosure, alteration,
                or destruction. However, no method of transmission over the
                internet or method of electronic storage is entirely secure, and
                we cannot guarantee absolute security<strong>.</strong>
              </li>
            </ol>
          </li>
          <li
            style={{
              marginLeft: '18pt',
              lineHeight: '150%',
              fontFamily: 'Garamond',
              fontWeight: 'bold',
            }}>
            Contact Information:
            <ol
              type={'1'}
              style={{
                marginRight: '0pt',
                marginLeft: '0pt',
                paddingLeft: '0pt',
              }}>
              <li
                style={{
                  marginLeft: '22.5pt',
                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '3pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;&nbsp;
                </span>
                If you have any questions, concerns, or grievances regarding
                this Policy or the handling of your personal information, please
                contact our Privacy Officer at: privacy@dzango.com
              </li>
            </ol>
          </li>
        </ol>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '18pt',
            marginBottom: '0pt',
            lineHeight: '150%',
          }}>
          <strong>
            <span style={{ fontFamily: 'Garamond' }}>&nbsp;</span>
          </strong>
        </p>
        <ol start={11} type={'1'} style={{ margin: '5pt', paddingLeft: '0pt' }}>
          <li
            style={{
              marginLeft: '17.63pt',
              lineHeight: '150%',
              paddingLeft: '0.37pt',
              fontFamily: 'Garamond',
              fontWeight: 'bold',
            }}>
            Modifications to the Policy:
            <ol
              type={'1'}
              style={{
                marginRight: '0pt',
                marginLeft: '0pt',
                paddingLeft: '0pt',
              }}>
              <li
                style={{
                  marginLeft: '22.5pt',
                  fontWeight: 'normal',
                }}>
                <span
                  style={{
                    width: '0.38pt',
                    font: '7pt "Times New Roman"',
                    display: 'inline-block',
                  }}>
                  &nbsp;
                </span>
                We reserve the right to modify this Policy at any time, without
                prior notice, by posting an updated version on the App. We
                encourage you to periodically review this Policy for the latest
                information on our privacy practices.
              </li>
            </ol>
          </li>
        </ol>
        <p
          style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: '150%' }}>
          <span style={{ fontFamily: 'Garamond' }}>&nbsp;</span>
        </p>
        <p
          style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: '150%' }}>
          <span style={{ fontFamily: 'Garamond' }}>
            By using the 8BIT Employee App, you acknowledge that you have read
            and understood this Policy and consent to the collection, use, and
            sharing of your personal information as described herein.
          </span>
        </p>
      </>
    </PrivacyContainer>
  );
  return <WebContainer>{policyComponent}</WebContainer>;
};

export default PrivacyPolicy;

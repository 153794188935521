import React, { useEffect } from 'react';
import { Platform, Pressable, SafeAreaView } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/MaterialIcons';

import EventCard from '@components/EventCard';
import PageMain from '@components/Page';
import ROUTES from '@navigation/routes';
import { useAppSelector } from '@redux/hook';
import { User } from '@types';
import { useLazyGetPendingAttendancesQuery } from '@redux/pendingAttendance/api';

import {
  Container,
  PendingContainer,
  TitleText,
  WelcomeText,
  WebContainer,
  CardContainer,
  CardTitleContainer,
} from './index.style';
import { DzangoLogo } from '@commons/styles';

const Dashboard = () => {
  const { t } = useTranslation();
  const isFocused = useIsFocused();
  const theme = useTheme();

  const user: User | null = useAppSelector(state => state.auth.user);
  const { navigate } = useNavigation<any>();

  const [
    pendingAttendanceTrigger,
    { data: pendingAttendancesData, isFetching },
  ] = useLazyGetPendingAttendancesQuery();

  useEffect(() => {
    if (user?.isManager) {
      pendingAttendanceTrigger(undefined);
    }
  }, [isFocused]);

  const Layout = () => (
    <PendingContainer>
      <Pressable
        onPress={() => navigate(ROUTES.APP_PENDING_LEAVE)}
        style={{
          ...(Platform.OS === 'web' &&
            theme.size.isLarge && {
              width: '30%',
              marginLeft: 20,
              marginRight: 20,
              borderRadius: 15,
            }),
        }}>
        <EventCard
          eventStyle={{
            marginLeft: 10,
            marginRight: 10,
            width: 'auto',
            ...(Platform.OS === 'web' &&
              theme.size.isLarge && {
                margin: 0,
              }),
          }}>
          <CardContainer>
            <CardTitleContainer>
              <Icon
                name="pending-actions"
                size={55}
                color={theme.color.yellow}
              />
              <TitleText>{`${t('app.dashboard.pending-leave-title')} (${
                pendingAttendancesData ? pendingAttendancesData.length : 0
              })`}</TitleText>
            </CardTitleContainer>
            {Platform.OS !== 'web' && (
              <Icon
                name="arrow-forward-ios"
                size={20}
                color={theme.color.text}
                style={{
                  alignItems: 'center',
                  alignSelf: 'center',
                }}
              />
            )}
          </CardContainer>
        </EventCard>
      </Pressable>
    </PendingContainer>
  );

  return Platform.OS === 'web' ? (
    <PageMain>
      {user?.isManager ? (
        Layout()
      ) : (
        <WebContainer>
          <DzangoLogo height={100} width={200} />
          <WelcomeText>{t('app.welcome')}</WelcomeText>
        </WebContainer>
      )}
    </PageMain>
  ) : (
    <SafeAreaView style={{ flex: 1 }}>
      {user?.isManager ? (
        Layout()
      ) : (
        <Container>
          <DzangoLogo height={100} width={200} />
          <WelcomeText>{t('app.welcome')}</WelcomeText>
        </Container>
      )}
    </SafeAreaView>
  );
};

export default Dashboard;

import React from 'react';

import { Container, Item, ItemLabel, ItemValue } from './index.style';

interface IProps {
  items: { label: string; value: string | number | undefined | null }[];
}

const DescriptionList: React.FC<IProps> = props => {
  const { items } = props;
  return (
    <Container>
      {items.map((item, index) => {
        return (
          <Item key={item.label} noBorder={index === items.length - 1}>
            <ItemLabel>{item.label}</ItemLabel>
            <ItemValue>{item.value}</ItemValue>
          </Item>
        );
      })}
    </Container>
  );
};

export default DescriptionList;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leave } from '@types';

interface LeaveState {
  leaveTypes: Leave[];
}

const initialState: LeaveState = {
  leaveTypes: [],
};

export const leaveSlice = createSlice({
  name: 'leave',
  initialState,
  reducers: {
    updateLeaveTypes(state, action: PayloadAction<Leave[]>) {
      state.leaveTypes = action.payload;
    },
  },
});

export const { updateLeaveTypes } = leaveSlice.actions;
export default leaveSlice.reducer;

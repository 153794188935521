import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

import { RootState } from '../../redux/store';
import { getIsProfileLoading, getProfile } from '../../redux/profile/selectors';
import { setProfile } from '../../redux/profile/slice';
import { EmployeeProfile } from '../../types';
import ProfileScreen from './index.component';

const mapStateToProps = (state: RootState) => ({
  isLoading: getIsProfileLoading(state),
  profile: getProfile(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setProfile: (profile: EmployeeProfile) => {
    dispatch(setProfile(profile));
  },
});

const ConnectedProfileScreen = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileScreen);

export default ConnectedProfileScreen;

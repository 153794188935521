import { AppConfig, AppFeatures, parse } from '@dzangolab/react-config';
import { Platform } from 'react-native';

import {
  REACT_APP_BASE_URL,
  REACT_APP_DEEP_LINK_URL,
} from 'react-native-dotenv';

declare module '@dzangolab/react-config' {
  interface AppConfig {
    baseDomain: string;
    deepLinkURL: string;
    privacyPolicyUrl: string;
  }
}

const config: AppConfig = {
  apiBaseUrl:
    Platform.OS === 'web' ? process.env.REACT_APP_BASE_URL : REACT_APP_BASE_URL,
  appPort: (Platform.OS === 'web' &&
    parse(process.env.PORT, '20141')) as string,
  appTitle: (Platform.OS === 'web' && process.env.REACT_APP_TITLE) as string,
  appVersion: (Platform.OS === 'web' &&
    `${process.env.REACT_APP_VERSION}+${process.env.REACT_APP_BUILD}`) as string,
  baseDomain: process.env.REACT_APP_BASE_DOMAIN as string,
  deepLinkURL:
    Platform.OS === 'web'
      ? process.env.REACT_APP_DEEP_LINK_URL
      : REACT_APP_DEEP_LINK_URL,
  features: {
    contracts: process.env.REACT_APP_FEATURE_CONTRACTS_ENABLED === 'true',
    documents: process.env.REACT_APP_FEATURE_DOCUMENTS_ENABLED === 'true',
    loan: process.env.REACT_APP_FEATURE_LOAN_ENABLED === 'true',
    requestLoan: process.env.REACT_APP_FEATURE_LOAN_REQUEST_ENABLED === 'true',
    showVersion: process.env.REACT_APP_FEATURE_SHOW_VERSION_ENABLED === 'true',
    showLeaveRequest:
      process.env.REACT_APP_FEATURE_SHOW_LEAVE_REQUEST_ENABLED === 'true',
    privacyPolicy: process.env.REACT_APP_FEATURE_PRIVACY_POLICY === 'true',
    showWFO: process.env.REACT_APP_FEATURE_SHOW_WFO_ENABLED === 'true',
  } as AppFeatures,
  privacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY_URL as string,
  websiteDomain: '',
};

export default config;

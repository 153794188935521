import { RootState } from '../store';

export const getProfile = (store: RootState) => store.profile.profile;
export const getIsProfileLoading = (store: RootState) =>
  store.profile.isLoadingProfile;
export const getLocalSwitcherModalVisibility = (store: RootState) =>
  store.profile.isLocaleSwitcherVisible;
export const getAppearanceModalVisibility = (store: RootState) =>
  store.profile.isAppearanceSwitcherVisible;

export const getEmployeeSalary = (store: RootState) => {
  const profile = getProfile(store);
  let totalSalary = 0;

  if (profile?.salary && profile.dearnessAllowance) {
    totalSalary = profile.salary + profile.dearnessAllowance;
  }

  return totalSalary;
};

export const getJoiningDate = (store: RootState) => {
  const profile = getProfile(store);

  return profile?.joinedAt;
};

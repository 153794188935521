export const SUPERTOKEN = {
  ERROR: {
    TRY_REFRESH_TOKEN: 'try refresh token',
  },
};

export const LEAVE_ID = {
  ABSENT: 5,
  ANNUAL_LEAVE: 2,
  LWP: 4,
  PATERNITY_LEAVE: 3,
  SICK_LEAVE: 1,
};

export const ATTENDANCE_TABLE_VIEWS = {
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
};

export const ATTENDANCE_STATUS = {
  PROPOSED: 0,
  APPROVED: 1,
  REJECTED: 2,
};
export const LEAVE_EVENT_TYPE_ID = 4;
export const COMPENSATION_EVENT_TYPE_ID = 8;

export const ATTENDANCE_TYPES = {
  LEAVE: {
    key: 'LEAVE',
    title: 'Leave',
  },
  OVERTIME: {
    key: 'OVERTIME',
    title: 'Overtime',
  },
  WORK_FROM_HOME: {
    key: 'WORK_FROM_HOME',
    title: 'Work from home',
  },
  COMPENSATION_LEAVE: {
    key: 'COMPENSATION_LEAVE',
    title: 'Compensation Leave',
  },
};

export const LEAVE_TYPES = [
  {
    id: LEAVE_ID.SICK_LEAVE,
    title: 'Sick Leave',
    typeId: LEAVE_EVENT_TYPE_ID,
  },
  {
    id: LEAVE_ID.ANNUAL_LEAVE,
    title: 'Annual Leave',
    typeId: LEAVE_EVENT_TYPE_ID,
  },
  {
    id: LEAVE_ID.LWP,
    title: 'LWP (Annual)',
    typeId: LEAVE_EVENT_TYPE_ID,
  },
  {
    id: COMPENSATION_EVENT_TYPE_ID,
    title: 'Compensation leave',
    typeId: COMPENSATION_EVENT_TYPE_ID,
  },
];

export const LEAVE_STATUS = [
  {
    id: 0,
    title: 'Proposed',
  },
  {
    id: 1,
    title: 'Approved',
  },
  {
    id: 2,
    title: 'Rejected',
  },
];

export const LOAN_TYPES = [
  {
    id: 1,
    key: 'LOAN',
    title: 'Loan',
  },
  {
    id: 2,
    key: 'SALARY_ADVANCE',
    title: 'Salary advance',
  },
];

// [SB: 2023-04-27] FIXME: Supported currency type should come from the API
export const SUPPORTED_CURRENCIES = [
  {
    id: 1,
    key: 'NPR',
    title: 'NPR',
  },
  {
    id: 2,
    key: 'USD',
    title: 'USD',
  },
];

export const MAXIMUM_LOAN_DURATION_IN_MONTH = 24;
export const MAXIMUM_LOAN_AMOUNT = 150000;
export const MINIMUM_MONTHLY_TRANSACTION_AMOUNT = 500;

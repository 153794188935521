import React from 'react';
import { ActivityIndicator, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { IconProps } from 'react-native-vector-icons/Icon';
import { useTheme } from 'styled-components/native';

interface IProps {
  onPress: () => void;
  iconProps?: IconProps;
  isLoading?: boolean;
}

const RefreshButton: React.FC<IProps> = ({ onPress, isLoading, iconProps }) => {
  const theme = useTheme();

  return (
    <TouchableOpacity onPress={onPress}>
      {isLoading ? (
        <ActivityIndicator
          size={18}
          style={{ marginRight: 10 }}
          color={theme.color.primary}
        />
      ) : (
        <Icon
          name={iconProps?.name || 'refresh'}
          size={iconProps?.size || 22}
          style={[iconProps?.style, { marginRight: 10 }]}
          color={(iconProps?.color, theme.color.primary)}
        />
      )}
    </TouchableOpacity>
  );
};

export default RefreshButton;

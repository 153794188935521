import { createApi } from '@reduxjs/toolkit/query/react';
import { Platform } from 'react-native';

import config from '@config';
import { setSuperToken } from '@utils/storage';

import axiosBaseQuery from '../axiosBaseQuery';

enum AuthEnum {
  ReducerPath = 'authApi',
  TagType = 'auth',
}

const authApi = createApi({
  reducerPath: AuthEnum.ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiBaseUrl,
  }),
  keepUnusedDataFor: 30,
  tagTypes: [AuthEnum.TagType],
  endpoints: build => ({
    superTokenAuth: build.mutation<any, Partial<any>>({
      query: data => ({
        url: '/auth/signinup',
        method: 'POST',
        headers: {
          rid: 'thirdpartyemailpassword',
          'content-type': 'application/json',
        },
        data: {
          code: data.code,
          redirectURI: `${config.apiBaseUrl}/auth/callback/google`,
          thirdPartyId: 'google',
        },
      }),
      transformResponse: async (response: any, meta: any, error) => {
        if (response && response.status === 'OK') {
          if (meta && meta.headers) {
            setSuperToken(
              meta?.headers['id-refresh-token'],
              meta?.headers['front-token'],
            );
          }

          return response;
        }

        return null;
      },
      invalidatesTags: [AuthEnum.TagType],
    }),

    superTokenAuthorisation: build.query({
      query: args => ({
        url: '/auth/authorisationurl',
        method: 'GET',
        params: { thirdPartyId: 'google' },
        headers: {
          rid: 'thirdpartyemailpassword',
        },
      }),
      transformResponse: (response: any) => {
        if (response) {
          let urlObj = new URL(response.url);
          const clientId = urlObj.searchParams.get('client_id');

          urlObj.searchParams.append(
            'redirect_uri',
            `${config?.apiBaseUrl}/auth/callback/google`,
          );
          urlObj.searchParams.append(
            'state',
            JSON.stringify({
              device: Platform.OS === 'web' ? 'web' : 'mobile',
            }),
          );
          urlObj.searchParams.set(
            'client_id',
            clientId ? clientId.replace('/', '') : '',
          );

          return urlObj.toString();
        }

        return null;
      },
      providesTags: [AuthEnum.TagType],
    }),
  }),
});

export const {
  useLazySuperTokenAuthorisationQuery,
  useSuperTokenAuthMutation,
} = authApi;
export default authApi;

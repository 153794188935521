import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.View`
  margin: 10px;
  flex: 1;
`;

export const FlatListContainer = styled.View`
  margin-left: 10px;
  margin-right: 10px;
`;

export const HeaderDateText = styled.Text`
  font-size: 13px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const HeaderText = styled.Text`
  font-size: 14px;
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.fonts.MontserratBold};
  font-weight: ${Platform.OS === 'web' ? 'bold' : '500'};
`;

export const DateContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
`;

export const HeaderDateContainer = styled.View`
  flex-direction: row;
`;

export const WebContainer = styled.View`
  margin-top: 10px;
`;

export const ActionButton = styled.TouchableOpacity<{ color: string }>`
  margin-right: 10px;
  border: 1px solid ${props => props.color};
  border-radius: 5px;
  width: 30px;
  height: 30px;
  align-items: center;
  padding: 5px;
`;

export const ConfirmationDialogContainer = styled.View`
  padding: 5px 15px;
`;

export const ConfirmationText = styled.Text`
  font-size: 15px;
  margin: 6px 0;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const ModalHeader = styled.View`
  flex: 1;
`;

export const ModalHeaderTitle = styled.Text`
  font-size: 18px;
  color: ${props => props.theme.color.text};
  text-align: center;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const ModalHeaderContainer = styled.View`
  border-bottom-color: ${props => props.theme.color.lightGrey5};
  border-bottom-width: 1px;
  flex-direction: row-reverse;
  padding-bottom: 12px;
  padding-horizontal: 10px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
`;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BottomSheet, Checkbox } from '@components';
import { ThemeType } from '@types';
import { ThemeContext } from '@theme';

import { Container, Title } from './index.style';

interface IProps {
  isVisible: boolean;
  updateAppearanceSwitcherVisibility: (visibilityStatus: boolean) => void;
}

const AppearanceSwitcherBottomSheet: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const { isVisible, updateAppearanceSwitcherVisibility } = props;
  const { theme, changeAppTheme, isDefaultTheme } = useContext(ThemeContext);

  const options = [
    { key: ThemeType.DARK, label: t('app.themes.dark'), value: ThemeType.DARK },
    {
      key: ThemeType.LIGHT,
      label: t('app.themes.light'),
      value: ThemeType.LIGHT,
    },
    {
      key: 'default',
      label: t('app.themes.default'),
      value: 'DEFAULT',
    },
  ];

  const closeModal = () => {
    updateAppearanceSwitcherVisibility(false);
  };

  const changeTheme = (value: ThemeType) => {
    changeAppTheme(value);
    closeModal();
  };

  return (
    <BottomSheet
      visible={isVisible}
      handleToggleModal={closeModal}
      onRequestClose={closeModal}>
      <Container>
        <Title>{t('app.appearance-picker-modal.title')}</Title>
        <Checkbox
          options={options}
          values={[isDefaultTheme ? 'DEFAULT' : theme]}
          onChange={changeTheme}
        />
      </Container>
    </BottomSheet>
  );
};

export default AppearanceSwitcherBottomSheet;

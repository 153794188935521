import { ThemeType } from '@types';
import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

interface LogoProps {
  height: number;
  width: number;
}

interface CaretProps {
  rotate?: boolean;
}

export const PageMain = styled.View``;

export const DzangoLogo = styled.Image.attrs(props => ({
  source:
    props.theme.name === ThemeType.LIGHT
      ? require('../assets/logo-black.png')
      : require('../assets/logo-white.png'),
  resizeMode: 'contain',
}))`
  height: ${(props: LogoProps) => props.height}px;
  width: ${(props: LogoProps) => props.width}px;
`;

export const Caret = styled.View`
  width: 0;
  height: 0;
  background-color: transparent;
  border-style: solid;
  border-top-width: 8px;
  border-left-width: 6px;
  border-right-width: 6px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: ${props => props.theme.color.grey};

  ${(props: CaretProps) =>
    Platform.OS === 'web' &&
    `
      transform: rotate(${props.rotate ? '180deg' : '0deg'});
      transition: 0.5s;
    `}
`;

export const ModalOverlay = styled.TouchableOpacity.attrs({
  activeOpacity: 1,
})`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.color.transparentBlack2};
`;

export const Card = styled.View`
  background-color: ${props => props.theme.color.white};
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid ${props => props.theme.color.transparentBlack1};

  ${props =>
    Platform.OS === 'web' &&
    css`
      box-shadow: 0 0 10px
        ${props.theme.name === ThemeType.LIGHT
          ? props.theme.color.lightGrey
          : props.theme.color.background};
      transition: 0.5s;
    `};
`;

export const ScreenMain = styled.View`
  flex: 1;
`;

export const FormErrorMessage = styled.Text`
  color: ${props => props.theme.color.red};
  margin-top: 8px;
  font-size: 12px;
  line-height: 15px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const Heading1 = styled.Text`
  font-size: 32px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

import { ConfigProvider } from '@dzangolab/react-config';
import React, { Fragment, useEffect } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import RNBootSplash from 'react-native-splash-screen';
import Toast from 'react-native-toast-message';
import { EventProvider } from 'react-native-outside-press';

import {
  AppearanceSwitcher,
  LocaleSwitcherBottomSheet,
  Root,
  StatusBar,
} from '@components';
import '@config/i18n';
import config from '@config';
import Navigator from '@navigation';
import { RootState, store } from '@redux/store';
import ThemeProvider from '@theme';
import AxiosInterceptor from '@utils/AxiosInterceptor';
import NotificationService from '@service/pushNotification';

interface IProps {
  superTokenSession: any;
  sessionRevoke: Function;
}

const App: React.FC<IProps> = ({ superTokenSession, sessionRevoke }) => {
  const isNative = Platform.OS === 'android' || Platform.OS === 'ios';
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      RNBootSplash.hide();
    }
  }, []);

  useEffect(() => {
    NotificationService.init(user);

    return () => NotificationService.clearNotifications();
  }, [user]);

  return (
    <ConfigProvider appConfig={config}>
      <ThemeProvider>
        <Root superTokenSession={superTokenSession}>
          <AxiosInterceptor sessionRevoke={sessionRevoke} isNative={isNative}>
            <EventProvider>
              <Fragment>
                {isNative && <StatusBar />}
                <Navigator />
                <Toast
                  position={isNative ? 'bottom' : 'top'}
                  bottomOffset={20}
                />
                <LocaleSwitcherBottomSheet />
                <AppearanceSwitcher />
              </Fragment>
            </EventProvider>
          </AxiosInterceptor>
        </Root>
      </ThemeProvider>
    </ConfigProvider>
  );
};

export default App;

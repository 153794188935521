export const ANIMATION_DURATION = 250;

export const BORDER_RADIUS = 2;

export const CARET_SIDE_SIZE = 14;

export const POPOVER_BACKGROUND_COLOR = 'black';
export const POPOVER_FONT_COLOR = 'white';
export const POPOVER_FONT_SIZE = 12;
export const POPOVER_PADDING = 6;
export const POPOVER_WIDTH = 150;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { setAuthLoadingStatus, setUser } from '@redux/auth/slice';
import { setProfile } from '@redux/profile/slice';
import { useAppDispatch } from '@redux/hook';
import { User } from '@types';
import { get } from '@utils/storage';
import { useLazyGetProfileQuery } from '@redux/profile/api';

interface UserInfo {
  user: User;
}

interface IProps {
  children: React.ReactElement;
  superTokenSession: any;
}

const Root: React.FC<IProps> = props => {
  const { superTokenSession } = props;
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();
  const [profileTrigger, { data: profileData, isFetching: isFetchingProfile }] =
    useLazyGetProfileQuery();

  const getUser = async () => {
    try {
      const sessionExists = await superTokenSession.doesSessionExist();

      if (sessionExists) {
        const userInfo: UserInfo = await new Promise(
          async (resolve, reject) => {
            try {
              const user =
                await superTokenSession.getAccessTokenPayloadSecurely();
              resolve(user);
            } catch (error) {
              dispatch(setUser(null));
              dispatch(setAuthLoadingStatus(false));
            }
          },
        )
          .then((data: any) => data)
          .catch(() => {});

        if (userInfo) {
          dispatch(setUser(userInfo.user));
          profileTrigger({});
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setAuthLoadingStatus(false));
    }
  };

  const loadLocale = async () => {
    const savedLocale = await get('locale');
    const supportedLngs = i18n.options.supportedLngs;

    if (savedLocale && supportedLngs && supportedLngs.includes(savedLocale)) {
      i18n.changeLanguage(savedLocale);
    }
  };

  useEffect(() => {
    getUser();
    loadLocale();
  }, []);

  useEffect(() => {
    if (profileData) {
      dispatch(setProfile(profileData));
    }
  }, [profileData]);

  return props.children;
};

export default Root;

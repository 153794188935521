import React, { useEffect, useState } from 'react';
import {
  FlatList,
  Platform,
  SafeAreaView,
  TouchableOpacity,
  View,
  Modal,
  Text,
} from 'react-native';

import PageMain from '@components/Page';

import {
  ActionButton,
  CardContainer,
  Container,
  ConfirmationDialogContainer,
  ConfirmationText,
  HeaderTitleText,
  ModalHeader,
  ModalHeaderContainer,
  ModalHeaderTitle,
  WebContainer,
  HeaderActionButton,
  HeaderText,
} from './index.style';
import EventCard from '@components/EventCard';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/Octicons';
import { useLazyGetPayslipsQuery } from '@redux/apis/payslips';
import {
  BottomSheet,
  CenteredMessage,
  ConfirmationDialog,
  Loading,
} from '@components';
import { PaySlip } from '@types';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import { downloadFile, shareFile } from '@utils/MediaManager';
import { ModalOverlay } from '@commons/styles';
import DropDownPicker from 'react-native-dropdown-picker';
import { getYear } from 'date-fns';
import { useNavigation } from '@react-navigation/native';
import YearSwitcherBottomSheet from '@components/YearSwitcher';
import FileViewer from '@components/FileViewer';

const PayslipsScreen = () => {
  const [payslipsTrigger, { data: payslipsDetail, isFetching }] =
    useLazyGetPayslipsQuery();
  const theme = useTheme();
  const [showPDF, setShowPDF] = useState(false);
  const [pdfUrl, setPDFUrl] = useState<string>();
  const [selectedPayslip, setSelectedPayslip] = useState<PaySlip>();
  const [downloadProgress, setDownloadProgress] = useState(false);
  const [showDownloadConfirmation, setShowDownloadConfirmation] =
    useState(false);
  const { t } = useTranslation();
  const { navigate, setOptions } = useNavigation<any>();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<any>();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    payslipsTrigger({ year: getCurrentYear() });
    setValue(getCurrentYear());
  }, []);

  const handleConfirmation = async (confirmed: boolean) => {
    if (confirmed && selectedPayslip) {
      const download = await downloadFile(selectedPayslip.documentUrl);

      if (download) {
        setDownloadProgress(false);
      }
    } else {
      setDownloadProgress(false);
      setShowDownloadConfirmation(false);
      setSelectedPayslip(undefined);
    }
  };

  const getPayslipYears = () => {
    if (payslipsDetail && payslipsDetail.payslipYears.length > 0) {
      const items: any[] = payslipsDetail.payslipYears.map((year: number) => {
        return {
          key: year,
          label: year.toString(),
          value: year,
        };
      });

      return items;
    }

    const currentYear = getCurrentYear();

    return [{ label: currentYear.toString(), value: currentYear }];
  };

  const getCurrentYear = () => {
    const currentDate = new Date();

    const currentYear = getYear(currentDate);

    return currentYear;
  };

  const downloadDialogBox = (
    <Modal
      animationType={'fade'}
      transparent
      visible={showDownloadConfirmation}
      supportedOrientations={['landscape', 'portrait']}>
      <ModalOverlay>
        <ConfirmationDialog
          title={t(`app.payslips.alert.title`)}
          confirmLabel={t(`app.payslips.alert.btn-confirm`)}
          onConfirm={() => {
            setShowDownloadConfirmation(false);
            handleConfirmation(true);
          }}
          onCancel={() => handleConfirmation(false)}
          cancelLabel={t(`app.payslips.alert.btn-cancel`)}
          color={theme.color.primary}>
          <ConfirmationDialogContainer>
            <ConfirmationText>
              {t('app.payslips.alert.message')}
            </ConfirmationText>
          </ConfirmationDialogContainer>
        </ConfirmationDialog>
      </ModalOverlay>
    </Modal>
  );

  const payslipsComponent =
    payslipsDetail && payslipsDetail.payslips.length > 0 ? (
      <>
        <FlatList
          data={payslipsDetail.payslips}
          renderItem={({ item }) => (
            <EventCard key={item.id} eventStyle={{ marginTop: 8 }}>
              <CardContainer>
                <HeaderTitleText>{item.payslipAt}</HeaderTitleText>
                <View
                  style={{
                    flexDirection: 'row',
                  }}>
                  <ActionButton
                    color={theme.color.green}
                    onPress={() => {
                      setShowPDF(true);
                      setSelectedPayslip(item);
                      setPDFUrl(item.documentUrl);
                    }}>
                    <Icon name="eye" size={16} color={theme.color.green} />
                  </ActionButton>
                  {Platform.OS !== 'web' && (
                    <ActionButton
                      color={theme.color.accent}
                      onPress={async () => {
                        await shareFile(item.documentUrl);
                      }}>
                      <Icon
                        name="share-android"
                        size={16}
                        color={theme.color.accent}
                      />
                    </ActionButton>
                  )}
                  <ActionButton
                    color={theme.color.primary}
                    onPress={() => {
                      if (downloadProgress) {
                        Toast.show({
                          type: 'info',
                          text1: t('app.payslips.download-progress') || '',
                        });
                      } else {
                        setSelectedPayslip(item);
                        setDownloadProgress(true);
                        setShowDownloadConfirmation(true);
                      }
                    }}
                    style={{
                      marginRight: 0,
                    }}>
                    <Icon
                      name="download"
                      size={16}
                      color={theme.color.primary}
                    />
                  </ActionButton>
                </View>
              </CardContainer>
            </EventCard>
          )}
        />
        {downloadDialogBox}
        {pdfUrl && (
          <BottomSheet
            animationType={Platform.select({
              web: 'none',
              default: 'slide',
            })}
            visible={showPDF}
            handleToggleModal={() => setShowPDF(!showPDF)}
            modalOptionsContainerStyle={{
              height: '100%',
              borderRadius: 0,
              ...(Platform.OS === 'web' && {
                alignSelf: theme.size.isLarge ? 'center' : 'auto',
                height: theme.size.isLarge ? '90%' : '100%',
                minWidth: '50%',
              }),
            }}
            modalBackgroundStyle={{
              ...Platform.select({
                web: {
                  justifyContent: 'center',
                },
                default: {
                  backgroundColor: theme.color.white,
                },
              }),
            }}
            headerComponent={
              <ModalHeaderContainer>
                <ActionButton
                  color={theme.color.primary}
                  onPress={() => {
                    if (selectedPayslip) {
                      downloadFile(selectedPayslip.documentUrl);
                    }
                  }}>
                  <Icon name="download" size={16} color={theme.color.primary} />
                </ActionButton>
                <ModalHeader>
                  <ModalHeaderTitle>{`Payslips - ${selectedPayslip?.payslipAt}`}</ModalHeaderTitle>
                </ModalHeader>
                <TouchableOpacity
                  onPress={() => setShowPDF(!showPDF)}
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}>
                  <Icon
                    name={Platform.select({
                      web: 'x-circle-fill',
                      default: 'chevron-left',
                    })}
                    size={26}
                    color={theme.color.lightBlack}
                  />
                </TouchableOpacity>
              </ModalHeaderContainer>
            }
            customContent={
              <React.Suspense fallback={<Loading />}>
                <FileViewer url={pdfUrl} />
              </React.Suspense>
            }></BottomSheet>
        )}
      </>
    ) : (
      <CenteredMessage message={t('app.payslips.empty-message')} />
    );

  useEffect(() => {
    setOptions({
      headerRight: () => (
        <HeaderActionButton onPress={() => setIsVisible(true)}>
          <HeaderText>{value}</HeaderText>
          <Icon
            name="chevron-down"
            size={20}
            style={{
              marginLeft: 5,
              marginTop: -2,
              color: theme.color.text,
            }}
          />
        </HeaderActionButton>
      ),
    });
  }, [value]);

  return Platform.OS === 'web' ? (
    <PageMain
      loading={isFetching}
      title={'Payslips'}
      toolbar={
        <View>
          <DropDownPicker
            open={open}
            value={value}
            items={getPayslipYears()}
            setOpen={setOpen}
            setValue={setValue}
            onSelectItem={item => {
              payslipsTrigger({ year: item.value });
            }}
            style={{
              minHeight: 30,
              borderColor: '#c2c2c2',
            }}
            dropDownContainerStyle={{
              minHeight: 30,
              borderColor: '#c2c2c2',
            }}
          />
        </View>
      }>
      <WebContainer
        style={{
          zIndex: -1,
        }}>
        {payslipsComponent}
      </WebContainer>
    </PageMain>
  ) : isFetching ? (
    <Loading />
  ) : (
    <SafeAreaView style={{ flex: 1 }}>
      <Container>{payslipsComponent}</Container>
      <YearSwitcherBottomSheet
        isVisible={isVisible}
        updateYearSwitcherVisibility={(val: boolean) => setIsVisible(val)}
        onYearChange={(value: number) => {
          payslipsTrigger({ year: value });
          setValue(value);
        }}
        options={getPayslipYears()}
        value={value}
      />
    </SafeAreaView>
  );
};

export default PayslipsScreen;

import styled from 'styled-components/native';

export const WebContainer = styled.View`
  margin: 10px 20px;
`;

export const PrivacyContainer = styled.ScrollView`
  flex-grow: 1;
`;

export const PrivacyHeader = styled.Text`
  font-size: 35px;
  color: #2e3192;
  margin-bottom: 20px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const PrivacySection = styled.View`
  margin-bottom: 20px;
`;

export const PrivacySubHeading = styled.Text`
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: bold;
  font-family: ${props => props.theme.fonts.MontserratBold};
`;

export const Paragraph = styled.Text`
  font-size: 16px;
  margin-bottom: 15px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const BulletPoint = styled.Text`
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 10px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const UpdatedDate = styled.Text`
  margin-top: 15;
  font-style: italic;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const Container = styled.View`
  margin: 10px;
  flex: 1;
`;

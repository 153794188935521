import { Linking, Platform } from 'react-native';
import ReactNativeBlobUtil from 'react-native-blob-util';
import Toast from 'react-native-toast-message';
import i18n from '@config/i18n';
import axios from 'axios';

const getFileDir = (fileName: string) => {
  const dirs = ReactNativeBlobUtil.fs.dirs;

  return `${
    Platform.OS === 'ios' ? dirs.DocumentDir : global.RNFS.DownloadDirectoryPath
  }/${fileName}`;
};

const getFileNameFromURL = (url: string) => {
  const urlParams = new URL(url);

  return decodeURIComponent(urlParams.pathname.replace(/^.*[\\\/]/, ''));
};

const downloadFile = async (fileUrl: string) => {
  if (Platform.OS === 'web') {
    Linking.openURL(fileUrl).catch(err => {
      Toast.show({
        type: 'error',
        text1: `Failed to download PDF file: ${err}` || '',
      });
    });

    return true;
  } else {
    const fileName = getFileNameFromURL(fileUrl);
    const fileDir = getFileDir(fileName);

    return ReactNativeBlobUtil.config({
      fileCache: true,
      appendExt: 'pdf',
      path: fileDir,
      addAndroidDownloads: {
        useDownloadManager: true,
        notification: true,
        title: fileName,
        description: i18n.t('app.payslips.download.description') || '',
        mime: 'application/pdf',
        path: fileDir,
      },
    })
      .fetch('GET', fileUrl)
      .then(res => {
        if (Platform.OS === 'ios') {
          const filePath = res.path();
          let options = {
            type: 'application/pdf',
            url: filePath,
            saveToFiles: true,
            title: i18n.t('app.payslips.download.title'),
            failOnCancel: false,
          };

          global.RNShare.open(options)
            .then(resp => {
              if (resp.success) {
                Toast.show({
                  type: 'success',
                  text1:
                    i18n.t('app.media-manager.download.success-message') || '',
                });
              }
            })
            .catch(err => {});
        }

        if (Platform.OS === 'android') {
          Toast.show({
            type: 'success',
            text1: i18n.t('app.media-manager.download.success-message') || '',
          });
        }

        return true;
      })
      .catch(err => {
        Toast.show({
          type: 'error',
          text1: i18n.t('app.common.error-message') || '',
        });
        return true;
      });
  }
};

const shareFile = async (fileUrl: string) => {
  const fileName = getFileNameFromURL(fileUrl);
  const fileDir = getFileDir(fileName);

  ReactNativeBlobUtil.config({
    fileCache: true,
    appendExt: 'pdf',
    path: fileDir,
  })
    .fetch('GET', fileUrl)
    .then(res => {
      let options = {
        type: 'application/pdf',
        url: Platform.OS === 'android' ? `file://${fileDir}` : res.path(),
        title: i18n.t('app.payslips.download.title'),
        failOnCancel: false,
      };

      global.RNShare.open(options)
        .then(async resp => {
          if (resp.success) {
            Toast.show({
              type: 'success',
              text1: i18n.t('app.media-manager.share.success-message') || '',
            });
          }

          await ReactNativeBlobUtil.fs.unlink(fileDir);
        })
        .catch(err => {});
    })
    .catch(err => {
      Toast.show({
        type: 'error',
        text1: i18n.t('app.common.error-message') || '',
      });
    });
};

const getContentType = async (url: string): Promise<string> => {
  try {
    const response = await axios.get(url, { responseType: 'arraybuffer' });
    const contentType = response.headers['content-type'];

    return contentType;
  } catch (error) {
    console.error('Error:', error);

    return '';
  }
};

export { downloadFile, shareFile, getContentType };

import { endOfMonth, startOfMonth } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Platform, SafeAreaView } from 'react-native';

import { AttendanceTable } from '@components';
import PageMain from '@components/Page';
import { formatDate, parseDate } from '@utils/dateTime';
import { Employee, EmployeeAttendance, Holiday } from '@types';
import { useLazyGetHolidaysQuery } from '@redux/apis/holidays';
import { useLazyGetAttendancesQuery } from '@redux/apis/attendances';
import { useAppSelector } from '@redux/hook';

import { Container } from './index.style';
import { CustomCalendarMarking } from '../../types/attendance';

interface IProps {
  attendanceMarkings: { [key: string]: CustomCalendarMarking };
  holidays: Holiday[];
  employees: Employee[];
  activeMonths: string[];
  setAttendance: (attendances: EmployeeAttendance[]) => void;
  setHolidays: (holidays: Holiday[]) => void;
  setActiveMonths: (months: string[]) => void;
}

const GeneralAttendance: React.FC<IProps> = props => {
  const {
    activeMonths,
    holidays,
    attendanceMarkings,
    employees,
    setAttendance,
    setHolidays,
    setActiveMonths,
  } = props;
  const user = useAppSelector(state => state.auth.user);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [holidayTrigger, { data: holidaysData, isFetching }] =
    useLazyGetHolidaysQuery();
  const [
    attendanceTrigger,
    { data: attendanceData, isFetching: isFetchingAttendance },
  ] = useLazyGetAttendancesQuery();

  useEffect(() => {
    holidayTrigger({});
  }, []);

  useEffect(() => {
    if (holidaysData) {
      setHolidays(holidaysData);
    }
  }, [holidaysData]);

  useEffect(() => {
    if (attendanceData) {
      setAttendance(attendanceData);
    }
  }, [attendanceData]);

  const fetchAttendance = (monthsToFetch: string[]) => {
    const unFetchedMonths = monthsToFetch.filter(
      month => !activeMonths.includes(month),
    );

    if (unFetchedMonths.length > 0) {
      const start = formatDate(
        startOfMonth(parseDate(monthsToFetch[0])),
        'yyyy-MM-dd',
      );
      const end = formatDate(
        endOfMonth(parseDate(_.last(monthsToFetch) ?? '')),
        'yyyy-MM-dd',
      );

      setActiveMonths([...monthsToFetch]);
      attendanceTrigger({ start, end });
    }
  };

  if (user) {
    if (Platform.OS === 'web') {
      return (
        <PageMain>
          <Container>
            <AttendanceTable
              currentMonth={formatDate(currentMonth, 'yyyy-MM-dd')}
              holidays={holidays}
              attendanceMarkings={attendanceMarkings}
              employees={employees}
              fetchAttendance={fetchAttendance}
              enableScrolling={true}
              loading={isFetchingAttendance}
              user={user}
            />
          </Container>
        </PageMain>
      );
    } else {
      return (
        <SafeAreaView style={{ flex: 1 }}>
          <Container>
            <AttendanceTable
              currentMonth={formatDate(currentMonth, 'yyyy-MM-dd')}
              holidays={holidays}
              attendanceMarkings={attendanceMarkings}
              employees={employees}
              fetchAttendance={fetchAttendance}
              enableScrolling={true}
              loading={isFetchingAttendance}
              user={user}
            />
          </Container>
        </SafeAreaView>
      );
    }
  }

  return <></>;
};

export default GeneralAttendance;

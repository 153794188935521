import React from 'react';
import { useTranslation } from 'react-i18next';

import BottomSheet from '@components/BottomSheet';
import { save } from '@utils/storage';

import {
  Container,
  LocaleItem,
  LocaleTitle,
  Selected,
  SelectedIcon,
  Title,
} from './index.style';

interface IProps {
  isVisible: boolean;
  updateLocaleSwitcherVisibility: (visibilityStatus: boolean) => void;
}

const LocaleSwitcherBottomSheet: React.FC<IProps> = props => {
  const { i18n, t } = useTranslation();
  const supportedLngs = i18n.options.supportedLngs;

  const { isVisible, updateLocaleSwitcherVisibility } = props;

  const closeModal = () => {
    updateLocaleSwitcherVisibility(false);
  };

  const changeLocale = async (locale: string) => {
    i18n.changeLanguage(locale);
    updateLocaleSwitcherVisibility(false);
    await save('locale', locale);
  };

  return (
    <BottomSheet
      visible={isVisible}
      handleToggleModal={closeModal}
      onRequestClose={closeModal}>
      <Container>
        <Title>{t('app.locale-picker-modal.title')}</Title>
        {supportedLngs &&
          supportedLngs
            .filter(locale => locale !== 'cimode')
            .map(locale => {
              return (
                <LocaleItem key={locale} onPress={() => changeLocale(locale)}>
                  <Selected selected={i18n.language === locale}>
                    <SelectedIcon selected={i18n.language === locale} />
                  </Selected>
                  <LocaleTitle>{t(`supportedLocales.${locale}`)}</LocaleTitle>
                </LocaleItem>
              );
            })}
      </Container>
    </BottomSheet>
  );
};

export default LocaleSwitcherBottomSheet;

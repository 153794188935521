import React, { useEffect, useState } from 'react';
import { Platform, SafeAreaView, View } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';

import PageMain from '@components/Page';

import {
  CardContent,
  Container,
  HeaderActionButton,
  HeaderText,
  WebContainer,
} from './index.style';
import { useLazyGetLeaveSummaryQuery } from '@redux/apis/attendances';
import { CenteredMessage, Loading } from '@components';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  Rows,
  Table,
  TableWrapper,
} from 'react-native-reanimated-table';
import { format } from 'date-fns';
import ExpandableCard from './ExpandableCard';
import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Octicons';
import { useLazyGetLeaveTypesQuery } from '@redux/leaves/api';
import { useLazyGetProfileQuery } from '@redux/profile/api';
import YearSwitcherBottomSheet from '@components/YearSwitcher';

const LeaveSummaryScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [leaveSummaryTrigger, { data: leaveSummaries, isFetching }] =
    useLazyGetLeaveSummaryQuery();
  const [leaveTypesTrigger, { data: leaveTypes }] = useLazyGetLeaveTypesQuery();
  const [profileTrigger, { data: profileData }] = useLazyGetProfileQuery();
  const [activeCard, setActiveCard] = useState<number | null>(0);
  const { setOptions } = useNavigation<any>();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<any>();
  const [isVisible, setIsVisible] = useState(false);

  const currentYear = new Date().getFullYear();

  const toggleCard = (index: number) => {
    if (activeCard === index) {
      setActiveCard(null);
    } else {
      setActiveCard(index);
    }
  };
  const getLeaveYears = () => {
    const yearRange = [
      {
        key: currentYear.toString(),
        label: currentYear.toString(),
        value: currentYear,
      },
    ];

    if (profileData && profileData.joinedAt) {
      const joinedDate = new Date(profileData.joinedAt).getFullYear();

      for (let year = joinedDate; year < currentYear; year++) {
        yearRange.push({
          key: year.toString(),
          label: year.toString(),
          value: year,
        });
      }
    }

    return yearRange.sort(
      (preYear, nextYear) => nextYear.value - preYear.value,
    );
  };

  useEffect(() => {
    leaveSummaryTrigger({ year: currentYear });
    leaveTypesTrigger({});
    profileTrigger({});
    setValue(currentYear);
  }, []);

  useEffect(() => {
    setOptions({
      headerRight: () => (
        <HeaderActionButton onPress={() => setIsVisible(!isVisible)}>
          <HeaderText>{value}</HeaderText>
          <Icon
            name="chevron-down"
            size={20}
            style={{
              marginLeft: 5,
              marginTop: -2,
              color: theme.color.text,
            }}
          />
        </HeaderActionButton>
      ),
    });
  }, [value]);

  const leaveSummaryComponent = (
    <>
      {leaveSummaries && leaveTypes && leaveSummaries.length > 0 ? (
        leaveSummaries.map((attendanceSummary, index) => {
          const { date, leaveSummary, nonAllowanceLeaveSummary, stats } =
            attendanceSummary;
          const headerRow: string[] = leaveTypes.map(
            leaveType => leaveType.name.match(/\b\w+\b/i)?.[0] || '',
          );

          const dataRow = leaveTypes.map(leaveType => {
            const leaveSummaryArray = leaveType.isAllowance
              ? leaveSummary
              : nonAllowanceLeaveSummary;

            const filterSummary = leaveSummaryArray?.find(
              data => data.leaveTypeId === leaveType.id,
            );

            const defaultValue = leaveType.isAllowance ? 0 : '';

            if (filterSummary) {
              return [
                filterSummary.start
                  ? filterSummary.start.toFixed(1)
                  : defaultValue,
                filterSummary.earned
                  ? filterSummary.earned.toFixed(1)
                  : defaultValue,
                filterSummary.used.toFixed(1),
                filterSummary.manuallyAdjusted
                  ? filterSummary.manuallyAdjusted.toFixed(1)
                  : defaultValue,
                filterSummary.end ? filterSummary.end.toFixed(1) : defaultValue,
              ];
            }

            return [0, 0, 0, 0, 0];
          });

          return (
            <ExpandableCard
              key={index}
              title={format(new Date(date), 'MMMM yyyy')}
              isExpanded={activeCard == index || false}
              content={
                <CardContent key={index}>
                  <View
                    style={{
                      width:
                        Platform.OS === 'web' &&
                        (theme.size.isLarge || theme.size.isMedium)
                          ? '50%'
                          : '100%',
                    }}>
                    <Table
                      borderStyle={{
                        borderWidth: 1,
                        borderColor: theme.color.lightGrey3,
                      }}>
                      <Row
                        data={[
                          '',
                          t('app.leave-summary.summary-table-row.start'),
                          t('app.leave-summary.summary-table-row.earned'),
                          t('app.leave-summary.summary-table-row.used'),
                          t('app.leave-summary.summary-table-row.adjusted'),
                          t('app.leave-summary.summary-table-row.end'),
                        ]}
                        flexArr={[1.7, 1, 1.5, 1, 1.5, 1]}
                        style={{
                          height: 35,
                          backgroundColor: theme.color.white,
                        }}
                        textStyle={{
                          textAlign: 'center',
                          color: theme.color.black,
                          fontFamily: theme.fonts.MontserratRegular,
                        }}
                      />
                      <TableWrapper style={{ flexDirection: 'row' }}>
                        <Col
                          data={headerRow}
                          style={{
                            flex: 1.7,
                            backgroundColor: theme.color.white,
                          }}
                          heightArr={[35, 35]}
                          textStyle={{
                            textAlign: 'center',
                            color: theme.color.black,
                            fontFamily: theme.fonts.MontserratRegular,
                          }}
                        />
                        <Rows
                          data={dataRow}
                          flexArr={[1, 1.5, 1, 1.5, 1]}
                          style={{ height: 35 }}
                          textStyle={{
                            textAlign: 'center',
                            color: theme.color.black,
                            fontFamily: theme.fonts.MontserratRegular,
                          }}
                        />
                      </TableWrapper>
                    </Table>
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                      width:
                        Platform.OS === 'web' &&
                        (theme.size.isLarge || theme.size.isMedium)
                          ? '25%'
                          : '50%',
                      marginLeft:
                        Platform.OS === 'web' &&
                        (theme.size.isLarge || theme.size.isMedium)
                          ? '5%'
                          : '25%',
                    }}>
                    <Table
                      borderStyle={{
                        borderWidth: 1,
                        borderColor: theme.color.lightGrey3,
                      }}>
                      <TableWrapper style={{ flexDirection: 'row' }}>
                        <Col
                          data={[
                            t(
                              'app.leave-summary.total-summary-table-row.workdays',
                            ),
                            t(
                              'app.leave-summary.total-summary-table-row.worked',
                            ),
                            t(
                              'app.leave-summary.total-summary-table-row.average',
                            ),
                          ]}
                          style={{
                            flex: 3,
                            backgroundColor: theme.color.white,
                          }}
                          heightArr={[35, 35]}
                          textStyle={{
                            textAlign: 'center',
                            color: theme.color.black,
                            fontFamily: theme.fonts.MontserratRegular,
                          }}
                        />
                        <Rows
                          data={[
                            [stats.totalWorkingDays.toFixed(1)],
                            [stats.totalWorkedDays.toFixed(1)],
                            [stats.past_12MonthsAverage.toFixed(1)],
                          ]}
                          flexArr={[1, 1, 1]}
                          style={{ height: 35 }}
                          textStyle={{
                            textAlign: 'center',
                            color: theme.color.black,
                            fontFamily: theme.fonts.MontserratRegular,
                          }}
                        />
                      </TableWrapper>
                    </Table>
                  </View>
                </CardContent>
              }
              onToggle={() => toggleCard(index)}
            />
          );
        })
      ) : (
        <CenteredMessage message={t('app.leave-summary.empty-message')} />
      )}
    </>
  );

  return Platform.OS === 'web' ? (
    <PageMain
      title={t('app.leave-summary.title')}
      loading={isFetching}
      toolbar={
        <View>
          <DropDownPicker
            open={open}
            value={value}
            items={getLeaveYears()}
            setOpen={setOpen}
            setValue={setValue}
            onSelectItem={item => {
              leaveSummaryTrigger({ year: item.value });
            }}
            style={{
              minHeight: 30,
              borderColor: '#c2c2c2',
            }}
            dropDownContainerStyle={{
              minHeight: 30,
              borderColor: '#c2c2c2',
            }}
          />
        </View>
      }>
      <WebContainer>{leaveSummaryComponent}</WebContainer>
    </PageMain>
  ) : (
    <SafeAreaView style={{ flex: 1 }}>
      <Container>{isFetching ? <Loading /> : leaveSummaryComponent}</Container>
      <YearSwitcherBottomSheet
        isVisible={isVisible}
        updateYearSwitcherVisibility={(val: boolean) => setIsVisible(val)}
        onYearChange={(value: number) => {
          leaveSummaryTrigger({ year: value });
          setValue(value);
        }}
        options={getLeaveYears()}
        value={value}
      />
    </SafeAreaView>
  );
};

export default LeaveSummaryScreen;

enum APP_DRAWERS {
  APP_DRAWER_DEFAULT = 'APP_DRAWER_DEFAULT',
}

enum APP_ROUTES {
  APP_CONTRACTS = 'APP_CONTRACTS',
  APP_MY_ATTENDANCE = 'APP_MY_ATTENDANCE',
  APP_DASHBOARD = 'APP_DASHBOARD',
  APP_GENERAL_ATTENDANCE = 'APP_GENERAL_ATTENDANCE',
  APP_LEAVE_SUMMARY = 'APP_LEAVE_SUMMARY',
  APP_TIMELINE = 'APP_TIMELINE',
  APP_PROFILE = 'APP_PROFILE',
  APP_CREATE_ATTENDANCE = 'APP_CREATE_ATTENDANCE',
  APP_PENDING_LEAVE = 'APP_PENDING_LEAVE',
  APP_LOANS = 'APP_LOANS',
  APP_LOAN_DETAILS = 'APP_LOAN_DETAILS',
  APP_REQUEST_LOAN = 'APP_REQUEST_LOAN',
  APP_PAYSLIPS = 'APP_PAYSLIPS',
  APP_PRIVACY_POLICY = 'APP_PRIVACY_POLICY',
  APP_HOLIDAYS = 'APP_HOLIDAYS',
  APP_OTHER_DOCUMENTS = 'APP_OTHER_DOCUMENTS',
}

enum AUTH_ROUTES {
  APP_LOGIN = 'APP_LOGIN',
  APP_GOOGLE_AUTH = 'APP_GOOGLE_AUTH',
}

const ROUTES = {
  ...APP_DRAWERS,
  ...APP_ROUTES,
  ...AUTH_ROUTES,
};

export default ROUTES;

import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import { BasicButton } from '@components/Button';
import { getDeepLink } from '@utils/deeplink';
import ROUTES from '@navigation/routes';
import { useLazySuperTokenAuthorisationQuery } from '@redux/auth/api';
import { AppVersion } from '@components';
import { getAppVersion } from '@utils';

import {
  BottomContainer,
  Card,
  Container,
  Instruction,
  LoginForm,
  Title,
} from './index.style';
import { DzangoLogo } from '@commons/styles';

const Login = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { navigate } = useNavigation<any>();

  const [superTokenAuthorisation] = useLazySuperTokenAuthorisationQuery();

  const onLogin = async () => {
    setLoading(true);
    await superTokenAuthorisation({})
      .unwrap()
      .then(authURL => {
        if (authURL) {
          if (Platform.OS === 'android' || Platform.OS === 'ios') {
            global.openLink(authURL, getDeepLink('auth/callback/google'));
          } else {
            window.location.assign(authURL);
          }
        } else {
          navigate(ROUTES.APP_LOGIN);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      <Card>
        <DzangoLogo width={250} height={50} />
        <Title>{t('app.login.title')}</Title>
        <Instruction>{t('app.login.instruction')}</Instruction>
        <LoginForm>
          <BasicButton
            label={t('app.login.action.google')}
            onPress={onLogin}
            loading={loading}
          />
        </LoginForm>
      </Card>
      <BottomContainer>
        <AppVersion version={getAppVersion()} />
      </BottomContainer>
    </Container>
  );
};

export default Login;

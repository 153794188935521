import React, { useState, useEffect } from 'react';
import { Image, ScrollView, ActivityIndicator, Dimensions } from 'react-native';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTheme } from 'styled-components/native';
import { getContentType } from '@utils/MediaManager';
import { FileType } from '@types';
import { useTranslation } from 'react-i18next';
import { ErrorText, ImageContainer } from './index.style';

interface FileViewerProps {
  url: string;
}

const FileViewer: React.FC<FileViewerProps> = ({ url }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [fileType, setFileType] = useState<FileType>();

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  useEffect(() => {
    const fetchFileType = async () => {
      try {
        const contentType = await getContentType(url);
        let fileType: 'pdf' | 'image' | 'unsupported';

        if (contentType.startsWith('application/pdf')) {
          fileType = 'pdf';
        } else if (contentType.startsWith('image/')) {
          fileType = 'image';
        } else {
          fileType = 'unsupported';
        }
        setFileType(fileType);
      } catch (error) {
        setError(t('app.documents.file.error'));
      }
    };

    fetchFileType();
  }, [url]);

  const handleLoadError = () => {
    setError(t('app.documents.file.error'));
  };

  const renderPDFViewer = () => (
    <ScrollView style={{ alignSelf: 'center' }}>
      <Document file={url}>
        <Page
          pageNumber={1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          {...(theme.size.isLarge && {
            scale: 1.5,
          })}
          {...(theme.size.isSmall && {
            width: Dimensions.get('window').width,
          })}
        />
      </Document>
    </ScrollView>
  );

  const renderImageViewer = () => (
    <ImageContainer>
      <Image
        source={{ uri: url }}
        style={{ height: '90%' }}
        resizeMode="contain"
        onError={handleLoadError}
      />
    </ImageContainer>
  );

  const renderContent = () => {
    if (error) return <ErrorText>{error}</ErrorText>;

    switch (fileType) {
      case 'pdf':
        return renderPDFViewer();
      case 'image':
        return renderImageViewer();
      case 'unsupported':
        return (
          <ErrorText>{t('app.documents.file.unsupported-file')}</ErrorText>
        );
      default:
        return <ActivityIndicator />;
    }
  };

  return renderContent();
};

export default FileViewer;

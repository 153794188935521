import { useIsFocused, useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  Platform,
  View,
  Switch,
  TouchableOpacity,
  Modal,
  KeyboardAvoidingView,
  SafeAreaView,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useTheme } from 'styled-components/native';

import { Popable } from '@components';
import {
  Loading,
  InputField,
  CenteredMessage,
  ConfirmationDialog,
} from '@components';
import EventCard from '@components/EventCard';
import PageMain from '@components/Page';
import NavHeaderButton from '@components/NavHeaderButton';
import { InputLabel } from '@components/InputField/index.style';
import BottomSheet from '@components/BottomSheet';
import { BasicButton } from '@components/Button';
import { ATTENDANCE_TYPES, COMPENSATION_EVENT_TYPE_ID } from '@constants';
import { useLazyGetPendingAttendancesQuery } from '@redux/pendingAttendance/api';
import { useAppDispatch, useAppSelector } from '@redux/hook';
import { updatePendingAttendances } from '@redux/pendingAttendance/slice';
import { getAttendanceEventColor, getLeaveRule } from '@utils/attendance';
import { formatDate, formatDateRange } from '@utils/dateTime';
import { User } from '@types';

import {
  Container,
  DescriptionContainer,
  DescriptionText,
  FlatListContainer,
  HeaderDateText,
  HeaderText,
  ModalHeaderContainer,
  ModalHeader,
  ModalHeaderTitle,
  TitleContainer,
  WebContainer,
  LeaveTypeContainer,
  LeaveTypeTitle,
  BottomContainer,
  ResetButtonText,
  ActionButton,
  ConfirmationDialogContainer,
  ConfirmationText,
  CardBottomContainer,
  ButtonLabelText,
  HeaderDateContainer,
  DateWarningIcon,
  ToolTipText,
  TooltipWrapper,
  PopableContainer,
} from './index.style';
import { useUpdateEmployeeAttendanceMutation } from '@redux/apis/attendances';
import Toast from 'react-native-toast-message';
import type { Attendance, AttendanceCreateInput } from 'core';
import { ModalOverlay } from '@commons/styles';
import ROUTES from '@navigation/routes';
import { DataTable } from 'react-native-paper';
import useLeaveTypes from '@hook/useLeaveTypes';
import { useGetLeaveRulesQuery } from '@redux/apis/leaveRules';
import i18n from '@config/i18n';

enum ApprovalActionType {
  Approve = 'approve',
  Reject = 'reject',
}

const POPOVER_MAX_WIDTH_DEFAULT = 250;
const POPOVER_MAX_WIDTH_WEB = 250;

const PendingEvents = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [attendanceData, setAttendanceData] = useState<AttendanceCreateInput>();
  const [confirmationDialog, setConfirmationDialog] = useState({
    action: '',
    cancelLabel: '',
    color: '',
    confirmLabel: '',
    title: '',
  });
  const [rejectionReason, setRejectionReason] = useState('');
  const [popableZIndex, setPopableZIndex] = useState<{
    [key: number]: number;
  }>({});
  const leaveTypes = useLeaveTypes();

  const { data: leaveRules } = useGetLeaveRulesQuery();

  const user: User | null = useAppSelector(state => state.auth.user);
  const [
    pendingAttendanceTrigger,
    { data: pendingAttendancesData, isFetching },
  ] = useLazyGetPendingAttendancesQuery();
  const { navigate, setOptions } = useNavigation<any>();
  const [visible, setVisible] = useState(false);
  const [updateLeave, { isLoading: isUpdateLoading }] =
    useUpdateEmployeeAttendanceMutation();

  const defaultFilters = {
    name: '',
    attendanceTypes: leaveTypes.map(leaveType => leaveType.id),
  };

  const [filters, setFilters] = useState(defaultFilters);

  const filterCount = () => {
    if (
      filters.name != '' &&
      filters.attendanceTypes.length !== leaveTypes.length
    ) {
      return 2;
    } else if (
      filters.name != '' ||
      filters.attendanceTypes.length !== leaveTypes.length
    ) {
      return 1;
    }

    return 0;
  };

  const getLeaveType = (eventType: string, leaveType?: string) => {
    return eventType === ATTENDANCE_TYPES.COMPENSATION_LEAVE.key
      ? ATTENDANCE_TYPES.COMPENSATION_LEAVE.title
      : leaveType;
  };

  useEffect(() => {
    if (pendingAttendancesData) {
      dispatch(updatePendingAttendances(pendingAttendancesData));
    }
  }, [pendingAttendancesData]);

  useEffect(() => {
    if (user?.isManager) {
      pendingAttendanceTrigger(undefined);
    }
  }, [isFocused]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [leaveTypes]);

  const toolbar = (
    <NavHeaderButton
      icon={{
        name: 'filter-outline',
        color:
          filters.name || filters.attendanceTypes.length !== leaveTypes.length
            ? theme.color.accent
            : theme.color.lightGrey4,
      }}
      containerStyle={{
        flexDirection: 'row',
        marginRight: Platform.OS === 'web' ? 0 : 10,
      }}
      onPress={() => setVisible(true)}
      showBadge={
        filters.name != '' ||
        filters.attendanceTypes.length !== leaveTypes.length
      }
      badgeCount={filterCount()}
    />
  );

  useEffect(() => {
    setOptions({
      headerRight: () => toolbar,
    });
  }, [filters]);

  const resetForm = () => {
    setShowConfirmation(false);
    setAttendanceData(undefined);
    setConfirmationDialog({
      action: '',
      cancelLabel: '',
      color: '',
      confirmLabel: '',
      title: '',
    });
    setRejectionReason('');
  };

  const handleApprove = (item: Attendance) => {
    setConfirmationDialog({
      ...confirmationDialog,
      title: t('app.pending-approval.confirmation-dialog.approve.title'),
      action: ApprovalActionType.Approve,
      cancelLabel: t(
        'app.pending-approval.confirmation-dialog.approve.cancel-button-label',
      ),
      confirmLabel: t(
        'app.pending-approval.confirmation-dialog.approve.confirm-button-label',
      ),
      color: theme.color.green,
    });
    setAttendanceData(item);
    setShowConfirmation(true);
  };

  const handleReject = (item: Attendance) => {
    setConfirmationDialog({
      ...confirmationDialog,
      title: t('app.pending-approval.confirmation-dialog.reject.message'),
      action: ApprovalActionType.Reject,
      cancelLabel: t(
        'app.pending-approval.confirmation-dialog.reject.cancel-button-label',
      ),
      confirmLabel: t(
        'app.pending-approval.confirmation-dialog.reject.confirm-button-label',
      ),
      color: theme.color.red,
    });
    setAttendanceData(item);
    setShowConfirmation(true);
  };

  const onActionPress = async (attendance: AttendanceCreateInput) => {
    // check event type
    await updateLeave(attendance)
      .unwrap()
      .then(() => {
        Toast.show({
          type: 'success',
          text1: t('app.create-attendance.success-message.update') || '',
        });
        resetForm();
        pendingAttendanceTrigger({
          filters,
        });
      })
      .catch(error => {
        if (error && error.data) {
          Toast.show({
            type: 'error',
            text1: error.data,
          });
        } else {
          Toast.show({
            type: 'error',
            text1: t('app.common.error-message') || '',
          });
        }
      });
  };

  const handleConfirmation = async (confirmed: boolean) => {
    if (confirmed) {
      if (attendanceData) {
        await onActionPress({
          ...attendanceData,
          status:
            confirmationDialog.action === ApprovalActionType.Approve ? 1 : 2,
          rejectionReason:
            confirmationDialog.action === ApprovalActionType.Approve
              ? ''
              : rejectionReason,
          leaveTypeId:
            attendanceData.typeId === COMPENSATION_EVENT_TYPE_ID
              ? null
              : attendanceData.leaveTypeId,
        });
      }
    } else {
      resetForm();
    }
  };

  const ITEMS_PER_PAGE = 10;
  const [page, setPage] = React.useState<number>(0);
  const from = page * ITEMS_PER_PAGE;
  const to = Math.min(
    (page + 1) * ITEMS_PER_PAGE,
    pendingAttendancesData?.length || 0,
  );

  React.useEffect(() => {
    setPage(0);
  }, [ITEMS_PER_PAGE]);

  const handlePopable = (index: number) => {
    const values = Object.values(popableZIndex ?? {});
    const maxValue = Math.max(...values, 0);

    setPopableZIndex(prevState => ({
      ...prevState,
      [index]: maxValue + 1,
    }));
  };

  const leaveNoticePeriod = (
    itemId: number,
    startDate: string,
    endDate: string,
    leaveTypeId: number | null,
    leaveRequestDate?: string,
  ) => {
    const leaveRule = getLeaveRule({
      start: new Date(startDate),
      end: new Date(endDate),
      leaveRules,
      leaveTypeId,
      requestDate: leaveRequestDate ? new Date(leaveRequestDate) : new Date(),
    });

    const noticePeriodMessage = leaveRule
      ? i18n.t(`app.pending-approval.notice-period.message`, {
          noticePeriod: leaveRule.noticePeriod,
          daysType: leaveRule.daysType,
        })
      : null;

    return (
      <Popable
        content={
          <TooltipWrapper>
            {noticePeriodMessage && (
              <ToolTipText>{noticePeriodMessage}</ToolTipText>
            )}
            <ToolTipText
              style={{
                fontSize: 14,
              }}>
              {i18n.t(`app.pending-approval.notice-period.request-on`, {
                leaveRequestDate: leaveRequestDate
                  ? formatDate(new Date(leaveRequestDate), 'dd MMM yyyy')
                  : '-',
              })}
            </ToolTipText>
          </TooltipWrapper>
        }
        action={theme.size.isLarge ? 'hover' : 'press'}
        backgroundColor={'transparent'}
        style={{
          width: POPOVER_MAX_WIDTH_DEFAULT,
          marginLeft: 2,
        }}
        onAction={() => handlePopable(itemId)}>
        <DateWarningIcon name="warning" />
      </Popable>
    );
  };

  const leaveReasonLayout = (id: number, text: string) => (
    <PopableContainer>
      <Popable
        content={
          <TooltipWrapper>
            <ToolTipText numberOfLines={5} ellipsizeMode={'tail'}>
              {text}
            </ToolTipText>
          </TooltipWrapper>
        }
        action={theme.size.isLarge ? 'hover' : 'press'}
        backgroundColor={'transparent'}
        style={{
          width: 'auto',
          maxWidth:
            Platform.OS === 'web'
              ? POPOVER_MAX_WIDTH_WEB
              : POPOVER_MAX_WIDTH_DEFAULT,
        }}
        onAction={() => handlePopable(id)}>
        <DescriptionText numberOfLines={1} ellipsizeMode={'tail'}>
          {text}
        </DescriptionText>
      </Popable>
    </PopableContainer>
  );

  const MainLayout = () => {
    return (
      <>
        {pendingAttendancesData && pendingAttendancesData.length > 0 ? (
          theme.size.isLarge ? (
            <DataTable
              style={{
                backgroundColor: theme.color.white,
                marginLeft: 15,
                width: '98%',
              }}>
              <DataTable.Header>
                <DataTable.Title
                  textStyle={{
                    color: theme.color.text,
                    fontFamily: theme.fonts.MontserratRegular,
                  }}
                  style={{
                    maxWidth: 250,
                  }}>
                  {t('app.pending-approval.table-cell-title.name')}
                </DataTable.Title>
                <DataTable.Title
                  textStyle={{
                    color: theme.color.text,
                    fontFamily: theme.fonts.MontserratRegular,
                  }}>
                  {t('app.pending-approval.table-cell-title.reason')}
                </DataTable.Title>
                <DataTable.Title
                  textStyle={{
                    color: theme.color.text,
                    fontFamily: theme.fonts.MontserratRegular,
                  }}
                  style={{
                    maxWidth: 200,
                  }}>
                  {t('app.pending-approval.table-cell-title.leave-date')}
                </DataTable.Title>
                <DataTable.Title
                  textStyle={{
                    color: theme.color.text,
                    fontFamily: theme.fonts.MontserratRegular,
                  }}
                  style={{
                    maxWidth: 200,
                  }}>
                  {t('app.pending-approval.table-cell-title.attendance-type')}
                </DataTable.Title>
                <DataTable.Title
                  style={{
                    maxWidth: 180,
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}>
                  <Icon name="cog" size={20} color={theme.color.text} />
                </DataTable.Title>
              </DataTable.Header>
              {pendingAttendancesData?.slice(from, to).map((item, index) => (
                <DataTable.Row
                  style={{
                    borderBottomWidth: 0.5,
                    zIndex: popableZIndex[index],
                  }}
                  key={item.id}>
                  <DataTable.Cell
                    textStyle={{
                      color: theme.color.text,
                      fontFamily: theme.fonts.MontserratRegular,
                    }}
                    style={{
                      maxWidth: 250,
                    }}>
                    {item.name}
                  </DataTable.Cell>
                  <DataTable.Cell
                    textStyle={{
                      color: theme.color.text,
                      fontFamily: theme.fonts.MontserratRegular,
                    }}>
                    {item.description &&
                      leaveReasonLayout(index, item.description)}
                  </DataTable.Cell>
                  <DataTable.Cell
                    textStyle={{
                      color: theme.color.text,
                      fontFamily: theme.fonts.MontserratRegular,
                    }}
                    style={{
                      maxWidth: 200,
                    }}>
                    <>
                      <HeaderDateText>
                        {formatDateRange(
                          new Date(item.start),
                          new Date(item.end),
                        )}
                      </HeaderDateText>
                      {!item.isValid &&
                        leaveNoticePeriod(
                          index,
                          item.start,
                          item.end,
                          item.leaveTypeId,
                          item.createdAt,
                        )}
                    </>
                  </DataTable.Cell>
                  <DataTable.Cell
                    textStyle={{
                      fontWeight: '400',
                      color: getAttendanceEventColor(item.eventType),
                      fontFamily: theme.fonts.MontserratRegular,
                    }}
                    style={{
                      maxWidth: 200,
                    }}>
                    {getLeaveType(item.eventType, item.leaveType)}
                  </DataTable.Cell>
                  <DataTable.Cell
                    style={{
                      maxWidth: 180,
                    }}>
                    <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                      <ActionButton
                        color={theme.color.green}
                        onPress={() => handleApprove(item)}
                        style={{
                          backgroundColor: theme.color.green,
                        }}>
                        <ButtonLabelText>
                          {t('app.pending-approval.approve-btn-label')}
                        </ButtonLabelText>
                      </ActionButton>
                      <ActionButton
                        color={theme.color.red}
                        onPress={() => handleReject(item)}
                        style={{ backgroundColor: theme.color.red }}>
                        <ButtonLabelText>
                          {t('app.pending-approval.reject-btn-label')}
                        </ButtonLabelText>
                      </ActionButton>
                      <ActionButton
                        color={theme.color.green}
                        onPress={() =>
                          navigate(ROUTES.APP_CREATE_ATTENDANCE, {
                            attendanceId: item.id,
                            activeMonth: item.start,
                            type: 'edit',
                            isPendingLeave:
                              user?.isManager && item.status === 0,
                          })
                        }
                        style={{
                          marginRight: 0,
                        }}>
                        <Icon
                          name="square-edit-outline"
                          size={16}
                          color={theme.color.green}
                        />
                      </ActionButton>
                    </View>
                  </DataTable.Cell>
                </DataTable.Row>
              ))}
              <DataTable.Pagination
                page={page}
                numberOfPages={Math.ceil(
                  pendingAttendancesData.length / ITEMS_PER_PAGE,
                )}
                numberOfItemsPerPage={ITEMS_PER_PAGE}
                onPageChange={page => setPage(page)}
                label={`${from + 1}-${to} of ${pendingAttendancesData.length}`}
                showFastPaginationControls
              />
            </DataTable>
          ) : (
            <FlatList
              data={pendingAttendancesData}
              renderItem={({ item, index }) => (
                <FlatListContainer key={item.id}>
                  <EventCard>
                    <TitleContainer>
                      <HeaderDateContainer>
                        <HeaderDateText>
                          {formatDateRange(
                            new Date(item.start),
                            new Date(item.end),
                          )}
                        </HeaderDateText>
                        {!item.isValid &&
                          leaveNoticePeriod(
                            index,
                            item.start,
                            item.end,
                            item.leaveTypeId,
                            item.createdAt,
                          )}
                      </HeaderDateContainer>
                      <HeaderText
                        style={{
                          fontWeight: '400',
                          color: getAttendanceEventColor(item.eventType),
                        }}>
                        {getLeaveType(item.eventType, item.leaveType)?.replace(
                          /leave/gi,
                          '',
                        )}
                      </HeaderText>
                    </TitleContainer>
                    <TitleContainer style={{ zIndex: 0 }}>
                      <HeaderText>{item.name}</HeaderText>
                    </TitleContainer>
                    <DescriptionContainer>
                      {item.description &&
                        leaveReasonLayout(index, item.description)}
                    </DescriptionContainer>
                    <CardBottomContainer>
                      <View style={{ flexDirection: 'row' }}>
                        <ActionButton
                          color={theme.color.green}
                          onPress={() => handleApprove(item)}
                          style={{
                            backgroundColor: theme.color.green,
                          }}>
                          <ButtonLabelText>
                            {t('app.pending-approval.approve-btn-label')}
                          </ButtonLabelText>
                        </ActionButton>
                        <ActionButton
                          color={theme.color.red}
                          onPress={() => handleReject(item)}
                          style={{ backgroundColor: theme.color.red }}>
                          <ButtonLabelText>
                            {t('app.pending-approval.reject-btn-label')}
                          </ButtonLabelText>
                        </ActionButton>
                      </View>
                      <View>
                        <ActionButton
                          color={theme.color.green}
                          onPress={() =>
                            navigate(ROUTES.APP_CREATE_ATTENDANCE, {
                              attendanceId: item.id,
                              activeMonth: item.start,
                              type: 'edit',
                              isPendingLeave:
                                user?.isManager && item.status === 0,
                            })
                          }
                          style={{
                            marginRight: 0,
                          }}>
                          <Icon
                            name="square-edit-outline"
                            size={14}
                            color={theme.color.green}
                          />
                        </ActionButton>
                      </View>
                    </CardBottomContainer>
                  </EventCard>
                </FlatListContainer>
              )}
            />
          )
        ) : (
          <CenteredMessage message={t('app.dashboard.empty-pending-leaves')} />
        )}
        <Modal
          animationType={'fade'}
          transparent
          visible={showConfirmation}
          supportedOrientations={['landscape', 'portrait']}>
          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
            <ModalOverlay>
              <ConfirmationDialog
                title={confirmationDialog.title}
                confirmLabel={confirmationDialog.confirmLabel}
                onConfirm={() => handleConfirmation(true)}
                onCancel={() => handleConfirmation(false)}
                cancelLabel={confirmationDialog.cancelLabel}
                color={confirmationDialog.color}
                isConfirmationLoading={isUpdateLoading}>
                {confirmationDialog.action === ApprovalActionType.Approve ? (
                  <ConfirmationDialogContainer>
                    <ConfirmationText>
                      {t(
                        'app.pending-approval.confirmation-dialog.approve.message',
                      )}
                    </ConfirmationText>
                  </ConfirmationDialogContainer>
                ) : (
                  <ConfirmationDialogContainer>
                    <ConfirmationText>
                      {t(
                        'app.pending-approval.confirmation-dialog.reject.form-input-label',
                      )}
                    </ConfirmationText>
                    <InputField
                      onChange={(value: string) => setRejectionReason(value)}
                      inputStyle={{
                        textAlignVertical: 'top',
                        height: 80,
                      }}
                      multiline
                      value={rejectionReason || ''}
                    />
                  </ConfirmationDialogContainer>
                )}
              </ConfirmationDialog>
            </ModalOverlay>
          </KeyboardAvoidingView>
        </Modal>
        <BottomSheet
          visible={visible}
          handleToggleModal={() => setVisible(!visible)}
          modalOptionsContainerStyle={{
            height: '50%',
            ...(Platform.OS === 'web' &&
              theme.size.isLarge && {
                marginLeft: 30,
                marginRight: 30,
                borderRadius: 0,
                width: '40%',
                alignSelf: 'center',
              }),
          }}
          headerComponent={
            <ModalHeaderContainer>
              <TouchableOpacity
                onPress={() => {
                  setVisible(!visible);
                  setFilters(defaultFilters);
                  pendingAttendanceTrigger(undefined);
                }}
                style={{
                  marginRight: 10,
                  alignSelf: 'center',
                }}>
                <ResetButtonText>
                  {t('app.pending-approval.reset-button')}
                </ResetButtonText>
              </TouchableOpacity>
              <ModalHeader>
                <ModalHeaderTitle>
                  {t('app.pending-approval.filter-title')}
                </ModalHeaderTitle>
              </ModalHeader>
              <TouchableOpacity onPress={() => setVisible(!visible)}>
                <Icon name="close" size={26} color={theme.color.lightBlack} />
              </TouchableOpacity>
            </ModalHeaderContainer>
          }
          bottomComponent={
            <BottomContainer>
              <BasicButton
                label={t('app.pending-approval.apply-button')}
                onPress={() => {
                  setVisible(!visible);
                  pendingAttendanceTrigger({
                    filters,
                  });
                }}
                buttonStyle={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginBottom: 20,
                  marginTop: 10,
                }}
              />
            </BottomContainer>
          }
          modalBackgroundStyle={{
            ...(Platform.OS === 'web' &&
              theme.size.isLarge && {
                justifyContent: 'center',
              }),
          }}>
          <View
            style={{
              margin: 15,
            }}>
            <InputField
              onChange={(value: string) =>
                setFilters({ ...filters, name: value })
              }
              labelText={t('app.pending-approval.input.employee-name')}
              showLabel
              value={filters.name}
              editable={true}
              placeholder={t('app.pending-approval.input.filter-by-name') || ''}
            />
            <InputLabel>
              {t('app.pending-approval.input.attendance-types')}
            </InputLabel>
            {leaveTypes.map(item => (
              <LeaveTypeContainer key={item.id}>
                <LeaveTypeTitle>{item.title}</LeaveTypeTitle>
                <Switch
                  trackColor={{ false: '#767577', true: '#81b0ff' }}
                  thumbColor={'#f4f3f4'}
                  ios_backgroundColor="#3e3e3e"
                  value={filters.attendanceTypes.includes(item.id)}
                  style={{
                    transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }],
                  }}
                  onValueChange={value => {
                    if (!value) {
                      const removeAttendanceType =
                        filters.attendanceTypes.filter(val => val !== item.id);
                      setFilters({
                        ...filters,
                        attendanceTypes: removeAttendanceType,
                      });
                    } else {
                      setFilters({
                        ...filters,
                        attendanceTypes: [...filters.attendanceTypes, item.id],
                      });
                    }
                  }}
                />
              </LeaveTypeContainer>
            ))}
          </View>
        </BottomSheet>
      </>
    );
  };

  return Platform.OS === 'web' ? (
    <PageMain
      title={t('app.screens.pending-approval')}
      toolbar={toolbar}
      loading={isFetching || isUpdateLoading}>
      <WebContainer>{MainLayout()}</WebContainer>
    </PageMain>
  ) : (
    <SafeAreaView style={{ flex: 1 }}>
      <Container>
        {isFetching || isUpdateLoading ? <Loading /> : MainLayout()}
      </Container>
    </SafeAreaView>
  );
};

export default PendingEvents;

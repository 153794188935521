import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import attendancesApi from './apis/attendances';
import holidaysApi from './apis/holidays';
import payslipsApi from './apis/payslips';
import documentsApi from './apis/documents';
import eventsApi from './apis/events';
import leaveRulesApi from './apis/leaveRules';
import { leaveSlice } from './leaves/slice';
import leaveApi from './leaves/api';
import { myAttendanceSlice } from './myAttendance/slice';
import { pendingAttendanceSlice } from './pendingAttendance/slice';
import pendingAttendancesApi from './pendingAttendance/api';
import authApi from './auth/api';
import { authSlice } from './auth/slice';
import { profileSlice } from './profile/slice';
import profileApi from './profile/api';
import { generalAttendanceSlice } from './generalAttendance/slice';
import { myLoanSlice } from './loans/slice';
import myLoansApi from './loans/api';

const showDevtools = process.env.NODE_ENV !== 'production';

const reducer = {
  [attendancesApi.reducerPath]: attendancesApi.reducer,
  [myAttendanceSlice.name]: myAttendanceSlice.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [holidaysApi.reducerPath]: holidaysApi.reducer,
  [payslipsApi.reducerPath]: payslipsApi.reducer,
  [documentsApi.reducerPath]: documentsApi.reducer,
  [leaveSlice.name]: leaveSlice.reducer,
  [leaveApi.reducerPath]: leaveApi.reducer,
  [pendingAttendanceSlice.name]: pendingAttendanceSlice.reducer,
  [pendingAttendancesApi.reducerPath]: pendingAttendancesApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [authSlice.name]: authSlice.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [profileSlice.name]: profileSlice.reducer,
  [generalAttendanceSlice.name]: generalAttendanceSlice.reducer,
  [myLoanSlice.name]: myLoanSlice.reducer,
  [myLoansApi.reducerPath]: myLoansApi.reducer,
  [leaveRulesApi.reducerPath]: leaveRulesApi.reducer,
};

export const store = configureStore({
  reducer,
  devTools: showDevtools,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      attendancesApi.middleware,
      eventsApi.middleware,
      holidaysApi.middleware,
      payslipsApi.middleware,
      documentsApi.middleware,
      leaveApi.middleware,
      pendingAttendancesApi.middleware,
      authApi.middleware,
      profileApi.middleware,
      myLoansApi.middleware,
      leaveRulesApi.middleware,
    ),
});

// required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import styled from 'styled-components/native';

export const DropdownContainer = styled.View`
  margin-bottom: 20px;
  width: 100%;
`;

export const DropdownLabel = styled.Text`
  padding: 0 10px 5px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const SelectedItemsContainer = styled.View`
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const SelectedItems = styled.Text`
  color: ${props => props.theme.color.white};
  padding-horizontal: 10px;
  padding-vertical: 5px;
  border-radius: 10px;
  background-color: ${props => props.theme.color.primary};
  margin-right: 10px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const ItemPlaceholder = styled.Text`
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const DropdownIcon = styled.View`
  position: absolute;
  right: 5px;
  top: 8px;
`;

export const ErrorText = styled.Text`
  color: ${props => props.theme.color.red};
  margin-top: 8px;
  font-size: 12px;
  line-height: 15px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const ListItemContainer = styled.TouchableOpacity`
  padding-horizontal: 20px;
  padding-vertical: 10px;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
`;

export const ListItemIcon = styled.View`
  margin-right: 10px;
`;

export const ItemSeparator = styled.View`
  background-color: ${props => props.theme.color.grey};
  height: 1px;
  opacity: 0.15;
`;

export const EmptyList = styled.View`
  align-items: center;
  width: 100%;
  margin-vertical: 20px;
`;

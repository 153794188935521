import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

import { RootState } from '../../redux/store';
import ProfileScreen from './index.component';
import {
  setAttendance,
  setActiveMonths,
  setHolidays,
} from '../../redux/generalAttendance/slice';
import { EmployeeAttendance, Holiday } from '../../types';
import {
  getActiveMonths,
  getHolidays,
  getAttendanceMarkings,
  getEmployees,
} from '../../redux/generalAttendance/selectors';

const mapStateToProps = (state: RootState) => ({
  attendanceMarkings: getAttendanceMarkings(state),
  activeMonths: getActiveMonths(state),
  holidays: getHolidays(state),
  employees: getEmployees(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setAttendance: (attendances: EmployeeAttendance[]) => {
    dispatch(setAttendance(attendances));
  },
  setHolidays: (holidays: Holiday[]) => {
    dispatch(setHolidays(holidays));
  },
  setActiveMonths: (months: string[]) => {
    dispatch(setActiveMonths(months));
  },
});

const ConnectedProfileScreen = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileScreen);

export default ConnectedProfileScreen;

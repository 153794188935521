import { DefaultTheme } from 'styled-components/native';

import { ThemeType } from '@types';

import { darkColor } from './colors';

export const getDarkTheme = (
  size: DefaultTheme['size'],
  fonts: { [key: string]: string },
): DefaultTheme => {
  return {
    name: ThemeType.DARK,
    color: darkColor,
    size,
    fonts,
  };
};

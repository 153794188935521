import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, SafeAreaView } from 'react-native';
import styled from 'styled-components/native';

import { DescriptionList, Loading } from '@components';
import PageMain from '@components/Page';
import { Card } from '@commons/styles';
import LocaleSwitcher from '@components/LocaleSwitcher';
import { useAppSelector } from '@redux/hook';
import { useLazyGetProfileQuery } from '@redux/profile/api';
import { EmployeeProfile } from '@types';
import { getGenderFromCode, getMaritalStatusFromCode } from '@utils/profile';
import { formatDate, parseDate } from '@utils/dateTime';
import { formatCurrency } from '@utils/NumberFormat';

import {
  AvatarCharacter,
  AvatarContainer,
  Container,
  Email,
  FullName,
  HeadSection,
  ProfileDetails,
  ProfileSection,
  SectionTitle,
} from './index.style';

export const LocaleSwitcherContainer = styled.View`
  position: absolute;
  top: ${Platform.OS === 'android' ? '15px' : '10px'};
  right: 10px;
  padding: 5px;
`;

interface IProps {
  isLoading: boolean;
  profile: EmployeeProfile | null;
  setProfile: (profile: EmployeeProfile) => void;
}

const ProfileScreen: React.FC<IProps> = props => {
  const { isLoading, profile, setProfile } = props;
  const { t } = useTranslation();
  const { setOptions } = useNavigation();

  const [profileTrigger, { data: profileData, isFetching: isFetchingProfile }] =
    useLazyGetProfileQuery();

  const getFullName = () => {
    let fullName = profile?.givenName;

    if (profile?.middleName) fullName += ` ${profile.middleName}`;

    fullName += ` ${profile?.surname}`;

    return fullName;
  };

  useEffect(() => {
    if (profileData) setProfile(profileData);
  }, [profileData]);

  useEffect(() => {
    if (!profile) {
      profileTrigger({});
    }
  }, []);

  useEffect(() => {
    setOptions({
      headerRight: () => (
        <LocaleSwitcherContainer>
          <LocaleSwitcher />
        </LocaleSwitcherContainer>
      ),
    });
  }, []);

  const Profile = () => {
    if (profile) {
      return (
        <Container>
          <HeadSection>
            <AvatarContainer>
              <AvatarCharacter>{profile.givenName.charAt(0)}</AvatarCharacter>
            </AvatarContainer>
            <FullName>{getFullName()}</FullName>
            <Email>{profile.email}</Email>
          </HeadSection>
          <ProfileDetails>
            <ProfileSection>
              <SectionTitle>
                {t('app.profile.personal-details.title')}
              </SectionTitle>
              <Card>
                <DescriptionList
                  items={[
                    {
                      label: t('app.profile.personal-details.given-name'),
                      value: profile.givenName,
                    },
                    {
                      label: t('app.profile.personal-details.middle-name'),
                      value: profile.middleName,
                    },
                    {
                      label: t('app.profile.personal-details.surname'),
                      value: profile.surname,
                    },
                    {
                      label: t('app.profile.personal-details.gender'),
                      value: getGenderFromCode(profile.gender),
                    },
                    {
                      label: t('app.profile.personal-details.dob'),
                      value: profile.dob
                        ? formatDate(parseDate(profile.dob), 'dd MMM yyyy')
                        : undefined,
                    },
                    {
                      label: t('app.profile.personal-details.marital-status'),
                      value: getMaritalStatusFromCode(profile.maritalStatus),
                    },
                  ]}
                />
              </Card>
            </ProfileSection>

            <ProfileSection>
              <SectionTitle>
                {t('app.profile.contact-details.title')}
              </SectionTitle>
              <Card>
                <DescriptionList
                  items={[
                    {
                      label: t(
                        'app.profile.contact-details.registered-address',
                      ),
                      value: profile.registeredAddress,
                    },
                    {
                      label: t('app.profile.contact-details.current-address'),
                      value: profile.currentAddress,
                    },
                    {
                      label: t('app.profile.contact-details.mobile'),
                      value: profile.mobile,
                    },
                    {
                      label: t('app.profile.contact-details.personal-email'),
                      value: profile.personalEmail,
                    },
                  ]}
                />
              </Card>
            </ProfileSection>

            <ProfileSection>
              <SectionTitle>{t('app.profile.ids.title')}</SectionTitle>
              <Card>
                <DescriptionList
                  items={[
                    {
                      label: t('app.profile.ids.citizenship-number'),
                      value: profile.citizenshipNumber,
                    },
                    {
                      label: t('app.profile.ids.pan-number'),
                      value: profile.panNumber,
                    },
                    {
                      label: t('app.profile.ids.cit-number'),
                      value: profile.citNumber,
                    },
                    {
                      label: t('app.profile.ids.ssf-number'),
                      value: profile.ssfNumber,
                    },
                    {
                      label: t('app.profile.ids.ssf-exempt'),
                      value: profile.ssfExempt ? t('app.yes') : t('app.no'),
                    },
                  ]}
                />
              </Card>
            </ProfileSection>

            <ProfileSection>
              <SectionTitle>{t('app.profile.cit.title')}</SectionTitle>
              <Card>
                <DescriptionList
                  items={[
                    {
                      label: t('app.profile.cit.cit-amount'),
                      value: profile.citAmount
                        ? formatCurrency(profile.citAmount, 'NPR')
                        : '',
                    },
                    {
                      label: t('app.profile.cit.use-max-cit'),
                      value: profile.useMaxCitAmount
                        ? t('app.yes')
                        : t('app.no'),
                    },
                  ]}
                />
              </Card>
            </ProfileSection>

            <ProfileSection>
              <SectionTitle>{t('app.profile.bank.title')}</SectionTitle>
              <Card>
                <DescriptionList
                  items={[
                    {
                      label: t('app.profile.bank.bank-name'),
                      value: profile.bankName,
                    },
                    {
                      label: t('app.profile.bank.bank-account'),
                      value: profile.bankAccount,
                    },
                    {
                      label: t('app.profile.bank.bank-branch'),
                      value: profile.bankBranch,
                    },
                  ]}
                />
              </Card>
            </ProfileSection>

            <ProfileSection>
              <SectionTitle>
                {t('app.profile.emergency-contact.title')}
              </SectionTitle>
              <Card>
                <DescriptionList
                  items={[
                    {
                      label: t('app.profile.emergency-contact.contact-name'),
                      value: profile.emergencyContactName,
                    },
                    {
                      label: t('app.profile.emergency-contact.contact-phone'),
                      value: profile.emergencyContactPhone,
                    },
                    {
                      label: t(
                        'app.profile.emergency-contact.relation-with-employee',
                      ),
                      value: profile.emergencyContactRelationship,
                    },
                  ]}
                />
              </Card>
            </ProfileSection>

            <ProfileSection>
              <SectionTitle>{t('app.profile.others.title')}</SectionTitle>
              <Card>
                <DescriptionList
                  items={[
                    {
                      label: t('app.profile.others.assignable'),
                      value: profile.assignable ? t('app.yes') : t('app.no'),
                    },
                    {
                      label: t('app.profile.others.signatory'),
                      value: profile.isSignatory ? t('app.yes') : t('app.no'),
                    },
                    {
                      label: t('app.profile.others.employee-number'),
                      value: profile.employeeNumber,
                    },
                  ]}
                />
              </Card>
            </ProfileSection>
          </ProfileDetails>
        </Container>
      );
    }

    return <></>;
  };

  return Platform.OS === 'web' ? (
    <PageMain loading={isLoading}>
      <Profile />
    </PageMain>
  ) : isLoading ? (
    <Loading />
  ) : (
    <SafeAreaView>
      <Profile />
    </SafeAreaView>
  );
};

export default ProfileScreen;

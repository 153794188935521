import styled, { DefaultTheme } from 'styled-components/native';

interface SelectedDateProps {
  selected?: boolean;
  theme: DefaultTheme;
}

export const MonthPickerWrapper = styled.View`
  background-color: ${props => props.theme.color.white};
  width: 80%;
  max-width: 340px;
  border-radius: 15px;
`;

export const MonthPickerHeader = styled.View`
  background-color: ${props => props.theme.color.primary};
  padding: 20px 25px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderSection = styled.View``;

export const HeaderYear = styled.Text.attrs({
  suppressHighlighting: true,
})`
  font-weight: 300;
  color: ${props => props.theme.color.white};
  font-size: 18px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const HeaderMonth = styled.Text.attrs({
  suppressHighlighting: true,
})`
  font-weight: 400;
  color: ${props => props.theme.color.white};
  font-size: 28px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const TodayButton = styled.TouchableOpacity`
  background-color: ${props => props.theme.color.white};
  padding: 10px 20px;
  border-radius: 5px;
`;

export const TodayLabel = styled.Text`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.primary};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const MonthPickerBody = styled.View`
  height: 300px;
  overflow: hidden;
`;

export const MonthPickerScrollableWrapper = styled.ScrollView`
  height: 100%;
  margin-bottom: 10px;
`;

export const YearList = styled.View`
  padding-top: 5px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 15px;
`;

export const MonthList = styled(YearList)`
  flex-direction: row;
  flex-wrap: wrap;
  height: 290px;
`;

export const YearItem = styled.Text.attrs({
  suppressHighlighting: true,
})`
  color: ${(props: SelectedDateProps) =>
    props.selected ? props.theme.color.primary : props.theme.color.text};
  font-size: 20px;
  margin: 10px 0;
  text-align: center;
  flex: 1 1 30%;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const MonthItem = styled.TouchableOpacity`
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 25%;
`;

export const MonthText = styled.Text`
  color: ${(props: SelectedDateProps) =>
    props.selected ? props.theme.color.primary : props.theme.color.text};
  font-size: 20px;
  text-align: center;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

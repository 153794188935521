import { RootState } from '../store';

export const getAttendance = (store: RootState) =>
  store.myAttendance.attendance;
export const getActiveMonthMarkings = (store: RootState) => {
  if (store.myAttendance.activeMonth) {
    return store.myAttendance.attendanceMarkings[
      store.myAttendance.activeMonth
    ];
  }

  return {};
};
export const getActiveMonth = (store: RootState) =>
  store.myAttendance.activeMonth;
export const getFetchedMonths = (store: RootState) =>
  store.myAttendance.fetchedMonths;

export const getHolidays = (store: RootState) => store.myAttendance.holidays;
export const getHolidayMarkings = (store: RootState) =>
  store.myAttendance.holidaysMarkings;

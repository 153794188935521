import { Dimensions, Platform } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${props => props.theme.color.background};
  flex: ${Platform.OS === 'android' || Platform.OS === 'ios' ? 1 : 'none'};
  justify-content: center;
  align-items: center;
  ${props =>
    Platform.OS === 'web' && `height: ${Dimensions.get('window').height}px;`}
`;

export const Card = styled.View`
  border-radius: 10px;
  max-width: 350px;
  width: 100%;
  padding: 20px;
  background-color: ${props => props.theme.color.white};
  text-align: center;
  align-items: center;
  justify-content: center;

  ${props =>
    Platform.OS === 'web' &&
    `boxShadow: 0 0 10px ${props.theme.color.lightGrey};`}
`;

export const Title = styled.Text`
  font-size: 22px;
  margin-top: 15px;
  font-family: ${props => props.theme.fonts.MontserratBold};
  color: ${props => props.theme.color.text};
  font-weight: 600;
`;

export const Instruction = styled.Text`
  font-size: 15px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.grey};
  margin-top: 4px;
  text-align: center;
`;

export const LoginForm = styled.View`
  margin-top: 20px;
  width: 100%;
`;

export const BottomContainer = styled.View`
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: ${Platform.OS === 'ios' ? '10px' : 0};
  height: 50px;
`;

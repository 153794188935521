import styled from 'styled-components/native';

export const FieldWrapper = styled.View<{
  open?: boolean;
}>`
  margin-bottom: 10px;
  z-index: ${props => (props.open ? 9 : 0)};
`;

export const StyledTextInput = styled.TextInput<{
  error?: boolean;
}>`
  height: 40px;
  border-width: ${props => (props.error ? '2px' : '1px')};
  border-color: ${props =>
    props.error ? props.theme.color.red : props.theme.color.lightGrey5};
  padding: 10px;
  border-radius: 10px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const ErrorMessage = styled.Text`
  color: ${props => props.theme.color.red};
  margin-top: 5px;
  font-size: 12px;
  line-height: 15px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const InputLabel = styled.Text`
  padding: 0 10px 5px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Employee, EmployeeAttendance, Holiday } from '@types';
import {
  getEmployeeDetailsFromGeneralAttendance,
  getGeneralAttendanceMarkings,
} from '@utils/attendance';

import { CustomCalendarMarking } from '../../types/attendance';

interface LeaveState {
  attendances: EmployeeAttendance[];
  attendanceMarkings: { [key: string]: CustomCalendarMarking };
  holidays: Holiday[];
  activeMonths: string[];
  employees: Employee[];
}

const initialState: LeaveState = {
  attendances: [],
  holidays: [],
  attendanceMarkings: {},
  activeMonths: [],
  employees: [],
};

export const generalAttendanceSlice = createSlice({
  name: 'generalAttendance',
  initialState,
  reducers: {
    setAttendance(state, action: PayloadAction<EmployeeAttendance[]>) {
      state.attendances = action.payload;
      state.attendanceMarkings = getGeneralAttendanceMarkings(action.payload);
      state.employees = getEmployeeDetailsFromGeneralAttendance(action.payload);
    },
    setHolidays(state, action: PayloadAction<Holiday[]>) {
      state.holidays = action.payload;
    },
    setActiveMonths(state, action: PayloadAction<string[]>) {
      state.activeMonths = action.payload;
    },
    resetGeneralAttendance(state) {
      state.attendances = [];
      state.holidays = [];
    },
  },
});

export const {
  setAttendance,
  setHolidays,
  resetGeneralAttendance,
  setActiveMonths,
} = generalAttendanceSlice.actions;
export default generalAttendanceSlice.reducer;

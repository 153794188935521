import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

import { RootState } from '@redux/store';
import { Attendance, Holiday } from '@types';
import {
  setActiveMonth,
  setAttendance,
  setHolidays,
  updateFetchedMonths,
} from '@redux/myAttendance/slice';
import {
  getAttendance,
  getActiveMonthMarkings,
  getHolidayMarkings,
  getHolidays,
  getActiveMonth,
  getFetchedMonths,
} from '@redux/myAttendance/selectors';

import MyAttendanceScreen from './index.component';

const mapStateToProps = (state: RootState) => ({
  holidays: getHolidays(state),
  holidayMarkings: getHolidayMarkings(state),
  attendance: getAttendance(state),
  activeMonthMarkings: getActiveMonthMarkings(state),
  activeMonth: getActiveMonth(state),
  fetchedMonths: getFetchedMonths(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setHolidays: (holidays: Holiday[]) => {
    dispatch(setHolidays(holidays));
  },
  setAttendance: (month: string, attendance: Attendance[]) => {
    dispatch(setAttendance({ month, attendance }));
  },
  setActiveMonth: (activeMonth: string) => {
    dispatch(setActiveMonth(activeMonth));
  },
  updateFetchedMonths: (months: string[]) => {
    dispatch(updateFetchedMonths(months));
  },
});

const ConnectedMyAttendanceScreen = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyAttendanceScreen);

export default ConnectedMyAttendanceScreen;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Attendance } from '@types';

interface PendingAttendanceState {
  pendingAttendances: Attendance[];
}

const initialState: PendingAttendanceState = {
  pendingAttendances: [],
};

export const pendingAttendanceSlice = createSlice({
  name: 'pendingAttendance',
  initialState,
  reducers: {
    updatePendingAttendances(state, action: PayloadAction<Attendance[]>) {
      state.pendingAttendances = action.payload;
    },
  },
});

export const { updatePendingAttendances } = pendingAttendanceSlice.actions;
export default pendingAttendanceSlice.reducer;

import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.View`
  padding: ${Platform.OS !== 'web' ? '15px 20px' : '0px 10px'};
  flex: 1;
`;

export const LoanWrapper = styled.Pressable`
  margin: 8px 0;
  background-color: ${props => props.theme.color.white};
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid ${props => props.theme.color.transparentBlack1};
`;

export const Heading = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
`;

export const LoanType = styled.Text`
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratBold};
  font-weight: 500;
  font-size: 18px;
`;

export const Duration = styled.Text`
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
  font-weight: 400;
  font-size: 14px;
`;

export const LoanStatus = styled.Text`
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
  font-weight: 400;
  font-size: 14px;
`;

export const AmountRow = styled.View`
  margin: 15px 0;
  flex-direction: row;
  justify-content: space-between;
`;

export const AmountItem = styled.View``;

export const AmountLabel = styled.Text`
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
`;

export const Amount = styled.Text`
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratBold};
  font-weight: 600;
  font-size: 16px;
`;

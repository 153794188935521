import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import {
  FlatList,
  StyleProp,
  ViewStyle,
  TextStyle,
  ColorValue,
} from 'react-native';

import EventCard from '@components/EventCard';

import {
  DescriptionText,
  IconContainer,
  ItemIcon,
  RowContainer,
  TimeContainer,
  TimeText,
  TimelineContainer,
  TitleText,
  VerticalLine,
} from './index.style';
import { useTheme } from 'styled-components/native';

interface EventTimeProps {
  timeContainerStyle?: StyleProp<ViewStyle>;
  time?: {
    timeText?: string;
    timeTextStyle?: StyleProp<TextStyle>;
  };
}

export interface TimelineData {
  time?: {
    timeText?: string;
    timeTextStyle?: StyleProp<TextStyle>;
  };
  headerContent: {
    text?: string;
    component?: React.ReactNode;
    titleStyle?: StyleProp<TextStyle>;
  };
  mainContent: {
    component?: React.ReactNode;
    text?: string;
    textStyle?: StyleProp<TextStyle>;
  };
  icon?: {
    name: string;
    style?: StyleProp<TextStyle>;
    iconComponent?: React.ReactNode;
  };
  shadowColor?: ColorValue;
}

interface TimelineProps extends EventIconProps {
  isClickable?: boolean;
  onItemPress?: () => void;
  eventStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  data: TimelineData[];
  onEndReachedThreshold?: number | null;
  onEndReached?: ((info: { distanceFromEnd: number }) => void) | null;
  TimelineFooter?: React.ReactElement;
  TimelineHeader?: React.ReactElement;
  timeContainerStyle?: StyleProp<ViewStyle>;
}

interface EventIconProps {
  eventIconStyle?: StyleProp<ViewStyle>;
  lineStyle?: StyleProp<ViewStyle>;
  icon?: {
    name: string;
    style?: StyleProp<TextStyle>;
    iconComponent?: React.ReactNode;
  };
}

const EventTime: React.FC<EventTimeProps> = ({ timeContainerStyle, time }) => {
  return (
    <TimeContainer style={[timeContainerStyle]}>
      <TimeText style={[time?.timeTextStyle]}>{time?.timeText}</TimeText>
    </TimeContainer>
  );
};
interface RowProps extends EventTimeProps, EventIconProps {
  isClickable?: boolean;
  onItemPress?: () => void;
  eventStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  headerContent: {
    text?: string;
    component?: React.ReactNode;
    titleStyle?: StyleProp<TextStyle>;
  };
  mainContent: {
    component?: React.ReactNode;
    text?: string;
    textStyle?: StyleProp<TextStyle>;
  };
  descriptionStyle?: StyleProp<TextStyle>;
  shadowColor?: ColorValue;
}

const EventIcon: React.FC<EventIconProps> = ({
  icon,
  eventIconStyle,
  lineStyle,
}) => {
  const theme = useTheme();
  let iconToBeRendered = icon?.iconComponent ? (
    icon.iconComponent
  ) : (
    <ItemIcon
      name={icon?.name || 'clock-o'}
      style={{ backgroundColor: theme.color.lightGreen2 }}
    />
  );

  return (
    <IconContainer style={[eventIconStyle]}>
      {iconToBeRendered}
      <VerticalLine style={[lineStyle]} />
    </IconContainer>
  );
};

const Row: React.FC<RowProps> = ({
  icon,
  contentContainerStyle,
  eventStyle,
  eventIconStyle,
  lineStyle,
  onItemPress,
  isClickable,
  timeContainerStyle,
  time,
  mainContent,
  headerContent,
  shadowColor,
}) => {
  const titleComponent = headerContent?.component ? (
    headerContent.component
  ) : (
    <TitleText style={[headerContent.titleStyle]}>
      {headerContent.text}
    </TitleText>
  );

  const descriptionComponent = mainContent?.component ? (
    mainContent.component
  ) : (
    <DescriptionText style={[mainContent.textStyle]}>
      {mainContent.text}
    </DescriptionText>
  );

  return (
    <RowContainer
      style={[eventStyle]}
      onPress={onItemPress}
      disabled={!isClickable}>
      <EventTime timeContainerStyle={timeContainerStyle} time={time} />
      <EventIcon
        icon={icon}
        eventIconStyle={eventIconStyle}
        lineStyle={lineStyle}
      />
      <EventCard eventStyle={contentContainerStyle} shadowColor={shadowColor}>
        {titleComponent}
        {descriptionComponent}
      </EventCard>
    </RowContainer>
  );
};

const Timeline: React.FC<TimelineProps> = ({
  data = [], // The actual event's array, array of objects, each object represents a single event
  eventStyle = {}, // Each event's whole row's style
  timeContainerStyle = {}, // The style object of the container that holds the time
  lineStyle = {}, // The vertical line's style object
  contentContainerStyle = {}, // The style object of the container that holds the content i.e. title and description
  eventIconStyle,
  onEndReachedThreshold,
  onEndReached,
  TimelineFooter,
  TimelineHeader,
  isClickable,
  ...rest
}) => {
  const events = (
    <FlatList
      data={data}
      renderItem={({ item }) => (
        <Row
          contentContainerStyle={contentContainerStyle}
          mainContent={item.mainContent}
          eventStyle={eventStyle}
          eventIconStyle={eventIconStyle}
          lineStyle={lineStyle}
          icon={item.icon}
          headerContent={item.headerContent}
          timeContainerStyle={timeContainerStyle}
          time={item.time}
          isClickable={isClickable}
          shadowColor={item.shadowColor}
        />
      )}
      keyExtractor={(_, ndx) => ndx.toString()}
      onEndReached={onEndReached}
      onEndReachedThreshold={onEndReachedThreshold || 0}
      ListFooterComponent={TimelineFooter}
      ListHeaderComponent={TimelineHeader}
      {...rest}
    />
  );

  return <TimelineContainer>{events}</TimelineContainer>;
};

export default Timeline;

import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';

import { Caret } from '@commons/styles';
import { Menu } from '@types';

import {
  DropdownContainer,
  DropdownItem,
  DropdownText,
  MenuLabel,
  MenuWrapper,
} from './index.style';
import OutsidePressHandler from 'react-native-outside-press';

interface IProps {
  menu: Menu;
}

const MenuItem: React.FC<IProps> = props => {
  const { menu } = props;
  const navigation = useNavigation<any>();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const onMenuPressed = (menu: Menu) => {
    setIsDropdownVisible(false);
    if (menu.children && menu.children.length > 0) {
      setIsDropdownVisible(!isDropdownVisible);
    } else {
      navigation.navigate(menu.route);
    }
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setIsDropdownVisible(false);
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <OutsidePressHandler onOutsidePress={() => setIsDropdownVisible(false)}>
      <MenuWrapper onPress={() => onMenuPressed(menu)}>
        <MenuLabel>
          {menu.title}{' '}
          {menu.children && menu.children.length > 0 && (
            <Caret
              rotate={isDropdownVisible}
              style={{ marginTop: 7, marginLeft: 5 }}
            />
          )}
        </MenuLabel>
        {menu.children && menu.children.length > 0 && (
          <DropdownContainer>
            {isDropdownVisible &&
              menu.children.map((childMenu, index) => {
                return (
                  <DropdownItem
                    onPress={() => onMenuPressed(childMenu)}
                    key={childMenu.key}
                    lastItem={
                      menu.children && menu.children.length - 1 === index
                    }>
                    <DropdownText>{childMenu.title}</DropdownText>
                  </DropdownItem>
                );
              })}
          </DropdownContainer>
        )}
      </MenuWrapper>
    </OutsidePressHandler>
  );
};

export default MenuItem;

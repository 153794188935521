import styled from 'styled-components/native';
import { Platform } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

export const Container = styled.View`
  margin: 10px;
  flex: 1;
`;

export const FlatListContainer = styled.View`
  margin-left: 10px;
  margin-right: 10px;
`;

export const HeaderDateText = styled.Text`
  font-size: 13px;
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const HeaderText = styled.Text`
  margin-bottom: 5px;
  font-size: 14px;
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.fonts.MontserratBold};
  font-weight: ${Platform.OS === 'web' ? 'bold' : '500'};
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 0;
`;

export const DescriptionContainer = styled.View`
  flex-direction: column;
  margin-top: 5px;
  width: 100%;
`;

export const DescriptionText = styled.Text`
  color: ${props => props.theme.color.text};
  font-size: 14px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const WebContainer = styled.View`
  margin-top: 10px;
  height: 100%;
`;

export const ModalHeaderContainer = styled.View`
  border-bottom-color: ${props => props.theme.color.lightGrey5};
  border-bottom-width: 1px;
  flex-direction: row-reverse;
  padding-bottom: 12px;
  padding-horizontal: 10px;
  margin-top: 10px;
`;

export const ModalHeader = styled.View`
  flex: 1;
`;

export const ModalHeaderTitle = styled.Text`
  font-size: 18px;
  color: ${props => props.theme.color.lightBlack};
  text-align: center;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const LeaveTypeContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
`;

export const LeaveTypeTitle = styled.Text`
  align-self: center;
  margin-left: 5px;
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const BottomContainer = styled.View`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${props => props.theme.color.white};
  border-top-width: 1px;
  border-top-color: ${props => props.theme.color.lightGrey3};
`;

export const ResetButtonText = styled.Text`
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.primary};
`;

export const ActionButton = styled.TouchableOpacity<{ color: string }>`
  margin-right: 10px;
  border: 1px solid ${props => props.color};
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
`;

export const ConfirmationDialogContainer = styled.View`
  padding: 5px 15px;
`;

export const ConfirmationText = styled.Text`
  font-size: 15px;
  margin: 6px 0;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const CardBottomContainer = styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  z-index: -1;
`;

export const ButtonLabelText = styled.Text`
  color: ${props => props.theme.color.alwaysWhite};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const HeaderDateContainer = styled.View`
  flex-direction: row;
`;

export const DateWarningIcon = styled(Icon)`
  font-size: 16px;
  color: ${props => props.theme.color.lightYellow};
  margin-left: 5px;
`;

export const ToolTipText = styled.Text`
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const TooltipWrapper = styled.View`
  border: 1px solid ${props => props.theme.color.transparentBlack2};
  background-color: ${props => props.theme.color.white};
  padding: 5px 10px;
  border-radius: 5px;
`;

export const PopableContainer = styled.View`
  max-width: 100%;
  padding-right: 20px;
`;

import styled from 'styled-components/native';

export const Container = styled.View``;

export const SelectedLocale = styled.Text`
  text-align: right;
  text-transform: uppercase;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const Switcher = styled.TouchableOpacity``;

export const LocaleOptions = styled.View`
  border: 1px solid ${props => props.theme.color.grey};
  padding: 5px;
  border-radius: 5px;
  margin: 2px -2px 0 0;
`;

export const Locale = styled.TouchableOpacity``;

export const LocaleText = styled.Text`
  margin: 2px 0;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

interface DropdownItemProps {
  lastItem?: boolean;
}

export const MenuWrapper = styled.TouchableOpacity.attrs({
  activeOpacity: 1,
})`
  margin: 0 10px;
  position: relative;
`;

export const MenuLabel = styled.Text`
  font-size: 16px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

export const DropdownContainer = styled.View`
  position: absolute;
  background-color: ${props => props.theme.color.white};
  min-width: 180px;
  top: 28px;
  border-radius: 5px;

  ${props =>
    Platform.OS === 'web' &&
    css`
      box-shadow: 0 0 10px ${props.theme.color.transparentBlack2};
    `};
`;

export const DropdownItem = styled.TouchableOpacity`
  border-bottom-width: ${(props: DropdownItemProps) =>
    props.lastItem ? '0px' : '1px'};
  border-bottom-color: ${props => props.theme.color.transparentBlack2};
  padding: 8px 10px;
`;

export const DropdownText = styled.Text`
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

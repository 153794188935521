import React, { useEffect, useState } from 'react';
import { FlatList, Platform, SafeAreaView } from 'react-native';

import { useLazyGetHolidaysQuery } from '@redux/apis/holidays';
import PageMain from '@components/Page';

import {
  Container,
  CountryText,
  DateContainer,
  FlatListContainer,
  HeaderDateContainer,
  HeaderDateText,
  HeaderText,
  Navigation,
  NavigationButton,
  NavigationButtonText,
  PageHeaderContainer,
  PageHeadingText,
  TitleContainer,
  WebContainer,
} from './index.style';
import { CenteredMessage, Loading } from '@components';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import EventCard from '@components/EventCard';
import { formatDate, formatDateRange } from '@utils/dateTime';
import { DataTable } from 'react-native-paper';
import FeatherIcons from 'react-native-vector-icons/Feather';

const HolidaysScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [holidayTrigger, { data: holidaysData, isFetching }] =
    useLazyGetHolidaysQuery();
  const [currentHolidayYear, setCurrentHolidayYear] = useState(
    new Date().getFullYear(),
  );

  useEffect(() => {
    holidayTrigger({ year: currentHolidayYear.toString() });
  }, [currentHolidayYear]);

  const HolidayComponent = (
    <>
      {holidaysData && holidaysData.length > 0 ? (
        theme.size.isLarge ? (
          <DataTable
            style={{
              backgroundColor: theme.color.white,
              marginLeft: 15,
              width: '98%',
            }}>
            <DataTable.Header>
              <DataTable.Title
                textStyle={{
                  color: theme.color.text,
                  fontFamily: theme.fonts.MontserratRegular,
                }}
                style={{
                  maxWidth: 200,
                }}>
                {t('app.holiday.table-cell-title.start')}
              </DataTable.Title>
              <DataTable.Title
                textStyle={{
                  color: theme.color.text,
                  fontFamily: theme.fonts.MontserratRegular,
                }}
                style={{
                  maxWidth: 200,
                }}>
                {t('app.holiday.table-cell-title.end')}
              </DataTable.Title>
              <DataTable.Title
                textStyle={{
                  color: theme.color.text,
                  fontFamily: theme.fonts.MontserratRegular,
                }}>
                {t('app.holiday.table-cell-title.name')}
              </DataTable.Title>
              <DataTable.Title
                style={{
                  maxWidth: 180,
                }}
                textStyle={{
                  color: theme.color.text,
                  fontFamily: theme.fonts.MontserratRegular,
                }}>
                {t('app.holiday.table-cell-title.country')}
              </DataTable.Title>
            </DataTable.Header>
            {holidaysData?.map(item => (
              <DataTable.Row style={{ borderBottomWidth: 0.5 }} key={item.id}>
                <DataTable.Cell
                  textStyle={{
                    color: theme.color.text,
                    fontFamily: theme.fonts.MontserratRegular,
                  }}
                  style={{
                    maxWidth: 200,
                  }}>
                  {formatDate(new Date(item.start), 'dd MMM yyyy')}
                </DataTable.Cell>
                <DataTable.Cell
                  textStyle={{
                    color: theme.color.text,
                    fontFamily: theme.fonts.MontserratRegular,
                  }}
                  style={{
                    maxWidth: 200,
                  }}>
                  {formatDate(new Date(item.end), 'dd MMM yyyy')}
                </DataTable.Cell>
                <DataTable.Cell
                  textStyle={{
                    color: theme.color.text,
                    fontFamily: theme.fonts.MontserratRegular,
                  }}>
                  {item.name}
                </DataTable.Cell>
                <DataTable.Cell
                  style={{
                    maxWidth: 180,
                  }}
                  textStyle={{
                    color: theme.color.text,
                    fontFamily: theme.fonts.MontserratRegular,
                  }}>
                  {item.countryName}
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>
        ) : (
          <FlatList
            data={holidaysData}
            renderItem={({ item }) => (
              <FlatListContainer key={item.id}>
                <EventCard>
                  <DateContainer>
                    <HeaderDateContainer>
                      <HeaderDateText>
                        {formatDateRange(
                          new Date(item.start),
                          new Date(item.end),
                        )}
                      </HeaderDateText>
                    </HeaderDateContainer>
                    <CountryText>{item.countryName}</CountryText>
                  </DateContainer>
                  <TitleContainer>
                    <HeaderText>{item.name}</HeaderText>
                  </TitleContainer>
                </EventCard>
              </FlatListContainer>
            )}
          />
        )
      ) : (
        <CenteredMessage message={t('app.holiday.empty-holiday-message')} />
      )}
    </>
  );

  const FilterNavigationComponent = (
    <Navigation>
      <NavigationButton
        onPress={() => setCurrentHolidayYear(currentHolidayYear - 1)}>
        <FeatherIcons name={'chevron-left'} size={22} color={'white'} />
      </NavigationButton>
      <NavigationButton
        onPress={() => setCurrentHolidayYear(new Date().getFullYear())}>
        <NavigationButtonText>{t('app.attendance.today')}</NavigationButtonText>
      </NavigationButton>
      <NavigationButton
        onPress={() => setCurrentHolidayYear(currentHolidayYear + 1)}>
        <FeatherIcons name={'chevron-right'} size={22} color={'white'} />
      </NavigationButton>
    </Navigation>
  );

  return Platform.OS === 'web' ? (
    <PageMain
      title={`Holidays - ${currentHolidayYear}`}
      loading={isFetching}
      toolbar={FilterNavigationComponent}>
      <WebContainer>{HolidayComponent}</WebContainer>
    </PageMain>
  ) : (
    <SafeAreaView style={{ flex: 1 }}>
      <Container>
        <PageHeaderContainer>
          <PageHeadingText>Holidays - {currentHolidayYear}</PageHeadingText>
          {FilterNavigationComponent}
        </PageHeaderContainer>
        {isFetching ? <Loading /> : HolidayComponent}
      </Container>
    </SafeAreaView>
  );
};

export default HolidaysScreen;

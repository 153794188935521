import React from 'react';
import { Button, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import ROUTES from '@navigation/routes';
import PageMain from '@components/Page';

import { Container, ScreenName } from './index.style';

const ContractsScreen = () => {
  const { navigate } = useNavigation<any>();

  const contractsComponent = (
    <>
      <ScreenName>Contracts Screen</ScreenName>
      <Button
        title="Goto Dashboard Screen"
        onPress={() => {
          navigate(ROUTES.APP_DASHBOARD);
        }}
      />
    </>
  );

  return Platform.OS === 'web' ? (
    <PageMain>{contractsComponent}</PageMain>
  ) : (
    <Container>{contractsComponent}</Container>
  );
};

export default ContractsScreen;

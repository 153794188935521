import React from 'react';
import { AppRegistry } from 'react-native';
import App from './App';
import SuperToken from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import SessionWeb from 'supertokens-web-js/recipe/session';
import { store } from '@redux/store';

import './service/supertoken/init';

// RNVI integration for web
import './config/icons.web';
import { Provider } from 'react-redux';

const AppWrapper = props => {
  return (
    <Provider store={store}>
      <App
        {...props}
        superTokenSession={SessionWeb}
        sessionRevoke={SuperToken.signOut}
      />
    </Provider>
  );
};

AppRegistry.registerComponent('App', () => AppWrapper);
AppRegistry.runApplication('App', { rootTag: document.getElementById('root') });

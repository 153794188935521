import styled from 'styled-components/native';

export const ModalTitle = styled.Text`
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const ModalOptionsContainer = styled.SafeAreaView`
  background-color: ${props => props.theme.color.white};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const ModalContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 1,
})`
  flex: 1px;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const InputContainer = styled.View`
  margin: 23px;
`;

export const InputField = styled.TextInput`
  padding-vertical: 12px;
  padding-horizontal: 12px;
  background-color: ${props => props.theme.color.white};
  border-radius: 8px;
  border-color: ${props => props.theme.color.lightGrey5};
  border-width: 1px;
  color: ${props => props.theme.color.lightGrey5};
  width: 100%;
`;

import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigation } from '@react-navigation/native';
import type { Loan, LoanRequestConfig, LoanType } from 'core';
import { isSameMonth, add, isAfter, startOfMonth } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components/native';
import Toast from 'react-native-toast-message';

import {
  InputField,
  DropDownPicker,
  SliderInput,
} from '@components/FormElements';
import { BasicButton } from '@components/Button';
import {
  MINIMUM_MONTHLY_TRANSACTION_AMOUNT,
  SUPPORTED_CURRENCIES,
} from '@constants';
import i18n from '@config/i18n';
import ROUTES from '@navigation/routes';
import {
  useRequestLoanMutation,
  useUpdateLoanMutation,
} from '@redux/loans/api';
import { formatDate, parseDate } from '@utils/dateTime';
import { getLoanDurationInMonths } from '@utils/loans';

import {
  ButtonContainer,
  Container,
  InfoIcon,
  MessageBox,
  MessageText,
  WarningIcon,
} from './index.style';
import { getSchema } from './schema';

interface IProps {
  salary: number;
  cutoffDays: number;
  config: LoanRequestConfig;
  mode?: string;
  loanToEdit?: Loan;
}

interface FormState {
  type: Loan['type'];
  currency: string;
  amount: string;
  duration: string;
  repaymentStartMonth: string;
  reason: string;
}

const LoanForm: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigation = useNavigation<any>();
  const [requestLoan, { isLoading: isCreatingLoan }] = useRequestLoanMutation();
  const [updateLoan, { isLoading: isUpdatingLoan }] = useUpdateLoanMutation();
  const { cutoffDays, config, salary, mode, loanToEdit } = props;

  const initialFormState: FormState = {
    type: 'LOAN',
    currency: 'NPR',
    amount: '',
    duration: '3',
    repaymentStartMonth: '',
    reason: '',
  };

  const initialLoanDuration = {
    min: 0,
    max: 0,
  };

  const [openLoanType, setOpenLeaveType] = useState(false);
  const [openCurrency, setOpenCurrency] = useState(false);
  const [openRepaymentStartMonth, setOpenRepaymentStartMonth] = useState(false);
  const [allowedLoanType, setAllowedLoanType] = useState<LoanType[]>([]);
  const [maxLoanAmount, setMaxLoanAmount] = useState(0);
  const [exceedSalaryThreshold, setExceedSalaryThreshold] = useState(false);
  const [underBlackout, setUnderBlackout] = useState(false);
  const [loanDurationRange, setLoanDurationRange] =
    useState(initialLoanDuration);
  const [monthlyInstallment, setMonthlyInstalment] = useState<number>();
  const [selectedLoanDuration, setSelectedLoanDuration] = useState(
    parseInt(initialFormState.duration),
  );
  const [repaymentMonthOptions, setRepaymentMonthOptions] = useState<Date[]>(
    [],
  );

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initialFormState,
    mode: 'onChange' || 'onSubmit',
    resolver: zodResolver(
      getSchema(
        maxLoanAmount,
        loanDurationRange.min,
        loanDurationRange.max,
        selectedLoanDuration,
      ),
    ),
  });

  const selectedLoanType = watch('type');
  const selectedAmount = watch('amount');
  const selectedDuration = watch('duration');

  useEffect(() => {
    const now = new Date();
    const cutoffApplied = !isSameMonth(now, add(now, { days: cutoffDays }));

    if (cutoffApplied) {
      setRepaymentMonthOptions([
        startOfMonth(add(now, { months: 1 })),
        startOfMonth(add(now, { months: 2 })),
      ]);
    } else {
      setRepaymentMonthOptions([
        startOfMonth(now),
        startOfMonth(add(now, { months: 1 })),
      ]);
    }
  }, []);

  useEffect(() => {
    const supportedLoanType = config.loanTypes.filter(type =>
      config.allowedLoanTypes.includes(type.key),
    );

    if (supportedLoanType.length > 0) {
      setValue('type', supportedLoanType[0].key);
    }

    if (mode === 'edit' && loanToEdit && config) {
      const loanType = config.loanTypes.find(
        loanType => loanType.key === loanToEdit.type,
      );

      if (loanType) {
        setAllowedLoanType([...supportedLoanType, loanType]);
      }

      const loanStart = startOfMonth(
        parseDate(loanToEdit.transactions[1].date),
      );
      const loanDuration = getLoanDurationInMonths(loanToEdit);

      setValue('amount', loanToEdit.amount.toString());
      setValue('type', loanToEdit.type);
      setValue('duration', loanDuration.toString());
      setValue('repaymentStartMonth', formatDate(loanStart, 'yyyy/MM/dd'));
      setValue('reason', loanToEdit.reason);
    } else {
      setAllowedLoanType(supportedLoanType);
    }
  }, [mode, loanToEdit, config]);

  useEffect(() => {
    if (selectedLoanType === 'LOAN') {
      const monthlyPayable = getMonthlyInstallmentAmount(
        parseInt(selectedAmount),
        parseInt(selectedDuration),
      );

      if (monthlyPayable > 0) {
        setMonthlyInstalment(monthlyPayable);
      } else {
        setMonthlyInstalment(undefined);
      }

      if (monthlyPayable > salary * 0.3) {
        setExceedSalaryThreshold(true);
      } else {
        setExceedSalaryThreshold(false);
      }
    } else {
      setExceedSalaryThreshold(false);
    }

    setSelectedLoanDuration(parseInt(selectedDuration));
  }, [selectedAmount, selectedLoanType, selectedDuration]);

  const getMonthlyInstallmentAmount = (amount: number, duration: number) => {
    if (duration > 0) {
      return (
        Math.floor(amount / duration / MINIMUM_MONTHLY_TRANSACTION_AMOUNT) *
        MINIMUM_MONTHLY_TRANSACTION_AMOUNT
      );
    }

    return 0;
  };

  useEffect(() => {
    if (selectedLoanType === 'LOAN') {
      const interestFreeLoan = _.find(
        config.loanTypes,
        type => type.key === 'LOAN',
      );

      if (interestFreeLoan) {
        setMaxLoanAmount(interestFreeLoan.maxAmount);
        setLoanDurationRange({
          min: interestFreeLoan.minDuration,
          max: interestFreeLoan.maxDuration,
        });

        if (config.blackoutEnd) {
          const blackoutEnd = parseDate(config.blackoutEnd);

          if (isAfter(blackoutEnd, new Date())) {
            setUnderBlackout(true);
          }
        }
      }
    }

    if (selectedLoanType === 'SALARY_ADVANCE') {
      const salaryAdvance = _.find(
        config.loanTypes,
        type => type.key === 'SALARY_ADVANCE',
      );

      if (salaryAdvance) {
        setMaxLoanAmount(salaryAdvance.maxAmount);
        setLoanDurationRange({
          min: salaryAdvance.minDuration,
          max: salaryAdvance.maxDuration,
        });
        setUnderBlackout(false);
      }
    }

    if (selectedLoanType === 'INVERTER_LOAN') {
      const inverterLoan = _.find(
        config.loanTypes,
        type => type.key === 'INVERTER_LOAN',
      );

      if (inverterLoan) {
        setMaxLoanAmount(inverterLoan.maxAmount);
        setLoanDurationRange({
          min: inverterLoan.minDuration,
          max: inverterLoan.maxDuration,
        });
        setUnderBlackout(false);
        setValue('amount', inverterLoan.maxAmount.toString());
        setValue('duration', inverterLoan.maxDuration.toString());
      }
    }
  }, [selectedLoanType]);

  const onFormSubmit = async (formData: any) => {
    if (mode === 'edit' && loanToEdit) {
      await updateLoan({ ...formData, loanId: loanToEdit.id })
        .unwrap()
        .then(() => {
          Toast.show({
            type: 'success',
            text2: i18n.t('app.loan.update-loan.success-message') || '',
          });
          reset(initialFormState);
          navigation.navigate(ROUTES.APP_LOANS);
        })
        .catch(error => {
          Toast.show({
            type: 'error',
            text1: i18n.t('app.loan.update-loan.failed-message') || '',
          });
        });
    } else {
      await requestLoan(formData)
        .unwrap()
        .then(() => {
          Toast.show({
            type: 'success',
            text2: i18n.t('app.loan.request-loan.success-message') || '',
          });
          reset(initialFormState);
          navigation.navigate(ROUTES.APP_LOANS);
        })
        .catch(error => {
          Toast.show({
            type: 'error',
            text1: i18n.t('app.loan.request-loan.failed-message') || '',
          });
        });
    }
  };

  return (
    <Container>
      <DropDownPicker
        name={'type'}
        control={control}
        label={i18n.t('app.loan.request-loan.label.loan-type')}
        error={errors.type}
        items={allowedLoanType.map(loan => {
          return {
            label: loan.title,
            value: loan.key,
          };
        })}
        setDropDownOpen={setOpenLeaveType}
        open={openLoanType}
      />

      <DropDownPicker
        name={'currency'}
        control={control}
        label={i18n.t('app.loan.request-loan.label.currency')}
        error={errors.currency}
        items={SUPPORTED_CURRENCIES.map(currency => {
          return {
            label: currency.title,
            value: currency.key,
          };
        })}
        setDropDownOpen={setOpenCurrency}
        open={openCurrency}
        disabled
      />

      {selectedLoanType !== 'INVERTER_LOAN' && (
        <InputField
          name={'amount'}
          control={control}
          type={'numeric'}
          label={i18n.t('app.loan.request-loan.label.amount')}
          keyboardType="numeric"
          error={errors.amount}
        />
      )}

      {selectedLoanType === 'LOAN' && (
        <SliderInput
          control={control}
          name={'duration'}
          minimumValue={loanDurationRange.min}
          maximumValue={loanDurationRange.max}
          defaultValue={loanDurationRange.min}
          label={i18n.t('app.loan.request-loan.label.duration')}
          valueUnit={i18n.t('app.months') || ''}
          step={1}
          error={errors.duration}
          showLabel
          showValue
        />
      )}

      {(selectedLoanType === 'LOAN' ||
        selectedLoanType === 'INVERTER_LOAN') && (
        <DropDownPicker
          name={'repaymentStartMonth'}
          control={control}
          label={i18n.t('app.loan.request-loan.label.repayment-start-month')}
          error={errors.repaymentStartMonth}
          items={repaymentMonthOptions.map(month => {
            return {
              label: formatDate(month, 'MMMM yyyy'),
              value: formatDate(month, 'yyyy/MM/dd'),
            };
          })}
          setDropDownOpen={setOpenRepaymentStartMonth}
          open={openRepaymentStartMonth}
          placeholder={
            i18n.t('app.loan.request-loan.placeholder.repayment-start') || ''
          }
        />
      )}

      {selectedLoanType !== 'INVERTER_LOAN' && (
        <InputField
          name={'reason'}
          control={control}
          type={'text'}
          label={i18n.t('app.loan.request-loan.label.reason')}
          multiline
          style={{
            textAlignVertical: 'top',
            height: 80,
          }}
        />
      )}

      {monthlyInstallment && (
        <MessageBox>
          <InfoIcon />
          <MessageText>
            {i18n.t('app.loan.request-loan.validation.installment', {
              monthlyInstallment,
            })}
          </MessageText>
        </MessageBox>
      )}

      {underBlackout && config && config.blackoutEnd && (
        <MessageBox>
          <WarningIcon />
          <MessageText>
            {i18n.t('app.loan.request-loan.blackout-message', {
              date: formatDate(parseDate(config.blackoutEnd), 'dd MMM yyyy'),
            })}
          </MessageText>
        </MessageBox>
      )}

      {config && config.isOnProbation && (
        <MessageBox>
          <WarningIcon />
          <MessageText type={'error'}>
            {i18n.t('app.loan.request-loan.probation-message')}
          </MessageText>
        </MessageBox>
      )}

      {exceedSalaryThreshold && (
        <MessageBox>
          <WarningIcon />
          <MessageText type={'error'}>
            {i18n.t('app.loan.request-loan.limit-by-salary-message')}
          </MessageText>
        </MessageBox>
      )}

      <ButtonContainer>
        {Platform.OS === 'web' && (
          <BasicButton
            label={t('app.loan.request-loan.label.cancel')}
            onPress={() => {
              navigation.navigate(ROUTES.APP_LOANS);
            }}
            buttonStyle={{
              width: 120,
              marginRight: 20,
              backgroundColor: theme.color.lightBlack,
            }}
          />
        )}

        <BasicButton
          onPress={handleSubmit(onFormSubmit)}
          label={t('app.loan.request-loan.label.submit')}
          buttonStyle={{
            width: Platform.OS === 'web' ? 120 : '100%',
          }}
          loading={isCreatingLoan || isUpdatingLoan}
          disabled={
            underBlackout ||
            (config && config.isOnProbation) ||
            exceedSalaryThreshold ||
            allowedLoanType.length === 0
          }
        />
      </ButtonContainer>
    </Container>
  );
};

export default LoanForm;

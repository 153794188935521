import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BottomSheet, Checkbox } from '@components';

import { Container, Title } from './index.style';

interface Option {
  key: string;
  label: string;
  value: string | number;
}

interface IProps {
  isVisible: boolean;
  updateYearSwitcherVisibility: (visibilityStatus: boolean) => void;
  onYearChange: (value: number) => void;
  options: Option[];
  value: number;
}

const YearSwitcherBottomSheet: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const {
    value,
    options,
    isVisible,
    onYearChange,
    updateYearSwitcherVisibility,
  } = props;

  const closeModal = () => {
    updateYearSwitcherVisibility(false);
  };

  const onChange = (value: number) => {
    onYearChange(value);
    closeModal();
  };

  return (
    <BottomSheet
      visible={isVisible}
      handleToggleModal={closeModal}
      onRequestClose={closeModal}
      modalOptionsContainerStyle={{
        maxHeight: '50%',
      }}>
      <Container>
        <Title>Select a year</Title>
        <Checkbox options={options} values={[value]} onChange={onChange} />
      </Container>
    </BottomSheet>
  );
};

export default YearSwitcherBottomSheet;

import React from 'react';
import { StatusBar as RNStatusBar } from 'react-native';
import { useTheme } from 'styled-components/native';

import { ThemeType } from '@types';

const StatusBar = () => {
  const theme = useTheme();

  return (
    <RNStatusBar
      barStyle={
        theme.name === ThemeType.LIGHT ? 'dark-content' : 'light-content'
      }
      backgroundColor={theme.color.white}
    />
  );
};

export default StatusBar;

import styled from 'styled-components/native';

export const Container = styled.View`
  margin-bottom: 20px;
`;

export const LabelText = styled.Text`
  padding: 0 10px 5px;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

import styled from 'styled-components/native';

export const Container = styled.View`
  padding: 20px 20px;
`;

export const LoanDetailCard = styled.View`
  margin-bottom: 10px;
  background-color: ${props => props.theme.color.white};
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid ${props => props.theme.color.transparentBlack1};
`;

export const PaymentScheduleWrapper = styled.View`
  margin-top: 15px;
`;

export const HeadingBar = styled.View`
  flex-direction: row;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeadingAction = styled.View`
  flex-direction: row;
`;

export const Title = styled.Text`
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
  font-weight: 400;
  font-size: 16px;
`;

export const ActionBar = styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

export const CheckboxWrapper = styled.Pressable`
  flex-direction: row;
  align-items: center;
`;

export const CheckboxLabel = styled.Text`
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
`;

export const DeleteConfirmation = styled.View`
  padding: 5px 15px;
`;

export const DeleteConfirmationText = styled.Text`
  font-size: 15px;
  margin: 6px 0;
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
`;

import { createApi } from '@reduxjs/toolkit/query/react';

import config from '@config';
import { Leave } from '@types';

import axiosBaseQuery from './../apis/axiosBaseQuery';

enum LeaveEnum {
  ReducerPath = 'leaveApi',
  TagType = 'leave',
}

const leaveApi = createApi({
  reducerPath: LeaveEnum.ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiBaseUrl,
  }),
  keepUnusedDataFor: 30,
  tagTypes: [LeaveEnum.TagType],
  endpoints: build => ({
    getLeaveTypes: build.query<Leave[], {}>({
      query: () => ({
        url: `/leave-types`,
        method: 'GET',
      }),
      transformResponse: (response: Leave[]) => response,
      providesTags: [LeaveEnum.TagType],
    }),
  }),
});

export const { useLazyGetLeaveTypesQuery } = leaveApi;
export default leaveApi;

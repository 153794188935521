import React from 'react';
import {
  KeyboardTypeOptions,
  StyleProp,
  TextInput,
  TextStyle,
  StyleSheet,
} from 'react-native';
import { useTheme } from 'styled-components/native';

import { FormErrorMessage } from '@commons/styles';

import { InputContainer, InputLabel } from './index.style';

interface IProps {
  inputStyle?: StyleProp<TextStyle>;
  keyboardType?: KeyboardTypeOptions;
  onChange: (text: string) => void;
  multiline?: boolean;
  placeholder?: string;
  value: string;
  onTouchStart?: () => void;
  editable?: boolean;
  showLabel?: boolean;
  labelText?: string | null;
  error?: string;
}

const InputField: React.FC<IProps> = ({
  keyboardType,
  onChange,
  inputStyle,
  multiline,
  placeholder,
  value,
  onTouchStart,
  editable = true,
  labelText,
  showLabel,
  error,
}) => {
  const theme = useTheme();

  const styles = StyleSheet.create({
    input: {
      height: 40,
      borderWidth: error ? 2 : 1,
      padding: 10,
      borderRadius: 10,
      borderColor: error ? theme.color.red : theme.color.lightGrey5,
      fontFamily: theme.fonts.MontserratRegular,
      color: theme.color.text,
    },
  });

  return (
    <InputContainer>
      {showLabel && <InputLabel>{labelText || 'Label title'}</InputLabel>}
      <TextInput
        onChangeText={onChange}
        placeholder={placeholder}
        placeholderTextColor={theme.color.lightGrey2}
        value={value}
        keyboardType={keyboardType || 'default'}
        style={[styles.input, inputStyle]}
        multiline={multiline}
        editable={editable}
        onTouchStart={onTouchStart}
        autoCorrect={false}
        autoCapitalize={'none'}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </InputContainer>
  );
};

export default InputField;

import * as z from 'zod';
import i18n from '@config/i18n';

import { stringToInt } from '@components/FormElements/utils';
import { MINIMUM_MONTHLY_TRANSACTION_AMOUNT } from '@constants';

const getSchema = (
  maxLoan: number,
  minDuration: number,
  maxDuration: number,
  selectedDuration: number,
) => {
  return z.discriminatedUnion('type', [
    z.object({
      type: z.literal('LOAN'),
      currency: z.string({
        required_error:
          i18n.t('app.loan.request-loan.validation.currency.required') || '',
      }),
      amount: z.preprocess(
        stringToInt,
        z
          .number({
            required_error:
              i18n.t('app.loan.request-loan.validation.amount.required') || '',
          })
          .min(selectedDuration * MINIMUM_MONTHLY_TRANSACTION_AMOUNT, {
            message:
              i18n.t('app.loan.request-loan.validation.amount.min', {
                amount: MINIMUM_MONTHLY_TRANSACTION_AMOUNT,
              }) || '',
          })
          .max(maxLoan, {
            message:
              i18n.t('app.loan.request-loan.validation.amount.max', {
                amount: maxLoan,
              }) || '',
          }),
      ),
      duration: z.preprocess(
        stringToInt,
        z
          .number({
            required_error:
              i18n.t('app.loan.request-loan.validation.duration.required') ||
              '',
          })
          .min(minDuration, {
            message:
              i18n.t('app.loan.request-loan.validation.duration.min', {
                duration: minDuration,
              }) || '',
          })
          .max(maxDuration, {
            message:
              i18n.t('app.loan.request-loan.validation.duration.max', {
                duration: maxDuration,
              }) || '',
          }),
      ),
      repaymentStartMonth: z.string().nonempty({
        message:
          i18n.t(
            'app.loan.request-loan.validation.repayment-start-month.required',
          ) || '',
      }),
      reason: z.string().optional(),
    }),
    z.object({
      type: z.literal('SALARY_ADVANCE'),
      currency: z.string({
        required_error:
          i18n.t('app.loan.request-loan.validation.currency.required') || '',
      }),
      amount: z.preprocess(
        stringToInt,
        z
          .number({
            required_error:
              i18n.t('app.loan.request-loan.validation.amount.required') || '',
          })
          .min(1, {
            message:
              i18n.t('app.loan.request-loan.validation.amount.min') || '',
          })
          .max(maxLoan, {
            message:
              i18n.t('app.loan.request-loan.validation.amount.max', {
                amount: maxLoan,
              }) || '',
          }),
      ),
      duration: z.any().transform(value => null),
      repaymentStartMonth: z.any().transform(value => null),
      reason: z.string().optional(),
    }),
    z.object({
      type: z.literal('INVERTER_LOAN'),
      currency: z.string({
        required_error:
          i18n.t('app.loan.request-loan.validation.currency.required') || '',
      }),
      amount: z.preprocess(
        stringToInt,
        z
          .number({
            required_error:
              i18n.t('app.loan.request-loan.validation.amount.required') || '',
          })
          .min(1, {
            message:
              i18n.t('app.loan.request-loan.validation.amount.min') || '',
          })
          .max(maxLoan, {
            message:
              i18n.t('app.loan.request-loan.validation.amount.max', {
                amount: maxLoan,
              }) || '',
          }),
      ),
      duration: z.preprocess(
        stringToInt,
        z
          .number({
            required_error:
              i18n.t('app.loan.request-loan.validation.duration.required') ||
              '',
          })
          .min(minDuration, {
            message:
              i18n.t('app.loan.request-loan.validation.duration.min', {
                duration: minDuration,
              }) || '',
          })
          .max(maxDuration, {
            message:
              i18n.t('app.loan.request-loan.validation.duration.max', {
                duration: maxDuration,
              }) || '',
          }),
      ),
      repaymentStartMonth: z.string().nonempty({
        message:
          i18n.t(
            'app.loan.request-loan.validation.repayment-start-month.required',
          ) || '',
      }),
    }),
  ]);
};

export { getSchema };

import FeatherIcons from 'react-native-vector-icons/Feather';
import styled, { DefaultTheme } from 'styled-components/native';

interface MessageProps {
  type?: 'error';
  theme: DefaultTheme;
}

export const Container = styled.View``;

export const ButtonContainer = styled.View`
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  margin-top: 10px;
`;

export const MessageBox = styled.View`
  margin: 5px 0;
  flex-direction: row;
  align-items: center;
`;

export const WarningIcon = styled(FeatherIcons).attrs({
  name: 'alert-triangle',
})`
  font-size: 16px;
  color: ${props => props.theme.color.red};
`;

export const InfoIcon = styled(FeatherIcons).attrs({
  name: 'info',
})`
  font-size: 16px;
  color: ${props => props.theme.color.text};
`;

export const MessageText = styled.Text`
  color: ${(props: MessageProps) =>
    props.type === 'error' ? props.theme.color.red : props.theme.color.text};
  font-family: ${props => props.theme.fonts.MontserratRegular};
  margin-left: 10px;
  flex: 1;
`;

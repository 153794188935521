import { createApi } from '@reduxjs/toolkit/query/react';

import config from '@config';
import { Holiday } from '@types';

import axiosBaseQuery from './axiosBaseQuery';

enum HolidaysEnum {
  ReducerPath = 'holidaysApi',
  TagType = 'holidays',
}

const holidaysApi = createApi({
  reducerPath: HolidaysEnum.ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiBaseUrl,
  }),
  keepUnusedDataFor: 30,
  tagTypes: [HolidaysEnum.TagType],
  endpoints: build => ({
    getHolidays: build.query<Holiday[], { year?: string }>({
      query: args => ({
        url: `/holidays`,
        method: 'GET',
        params: { year: args.year },
      }),
      transformResponse: (response: Holiday[]) => response,
      providesTags: [HolidaysEnum.TagType],
    }),
  }),
});

export const { useLazyGetHolidaysQuery } = holidaysApi;
export default holidaysApi;

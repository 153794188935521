import React from 'react';
import {
  Pressable,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useTheme } from 'styled-components/native';

import {
  DropdownContainer,
  DropdownIcon,
  DropdownLabel,
  ErrorText,
  ItemPlaceholder,
  SelectedItemsContainer,
} from './index.style';

const InputField = ({
  label,
  placeholder,
  error,
  getSelectedItemsLabel,
  handleToggleModal,
  selectedItem,
  labelStyle,
  dropdownStyle,
  dropdownContainerStyle,
  selectedItemStyle,
  dropdownErrorStyle,
  dropdownErrorTextStyle,
  disabled,
  errorMessage,
}: any) => {
  const theme = useTheme();

  const inputStyles: any = StyleSheet.create({
    input: {
      paddingVertical: 12,
      paddingHorizontal: 12,
      backgroundColor: theme.color.white,
      borderRadius: 8,
      borderColor: theme.color.lightGrey5,
      borderWidth: 1,
      color: theme.color.lightGrey5,
      width: '100%',
    },
  });

  return (
    <DropdownContainer style={[dropdownContainerStyle]}>
      {label && label !== '' && (
        <DropdownLabel style={[labelStyle]}>{label}</DropdownLabel>
      )}
      <Pressable
        onPress={() => handleToggleModal()}
        style={({ pressed }) => [
          pressed && {
            ...{
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: theme.color.primary,
            },
          },
          inputStyles.input,
          dropdownStyle,
          error && //order matters
            error !== '' &&
            !pressed && {
              ...{
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: theme.color.red,
              },
              ...dropdownErrorStyle,
            },
        ]}
        disabled={disabled}>
        <ScrollView
          horizontal
          alwaysBounceHorizontal
          showsHorizontalScrollIndicator={false}>
          <SelectedItemsContainer onStartShouldSetResponder={() => true}>
            <TouchableOpacity
              onPress={() => handleToggleModal()}
              disabled={disabled}>
              <ItemPlaceholder style={[selectedItemStyle]}>
                {selectedItem}
              </ItemPlaceholder>
            </TouchableOpacity>
          </SelectedItemsContainer>

          {!selectedItem && (
            <ItemPlaceholder>
              {placeholder ?? 'Select an option'}
            </ItemPlaceholder>
          )}
        </ScrollView>
        <DropdownIcon>
          <Icon name="chevron-down" size={25} color={theme.color.grey} />
        </DropdownIcon>
      </Pressable>

      {error && error !== '' && (
        <ErrorText style={[dropdownErrorTextStyle]}>{error}</ErrorText>
      )}
    </DropdownContainer>
  );
};

export default InputField;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EmployeeProfile } from '@types';

interface ProfileState {
  isLoadingProfile: boolean;
  isLocaleSwitcherVisible: boolean;
  isAppearanceSwitcherVisible: boolean;
  profile: EmployeeProfile | null;
}

const initialState: ProfileState = {
  isLoadingProfile: true,
  isLocaleSwitcherVisible: false,
  isAppearanceSwitcherVisible: false,
  profile: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<EmployeeProfile>) {
      state.profile = action.payload;
      state.isLoadingProfile = false;
    },
    updateLocaleSwitcherVisibility(state, action: PayloadAction<boolean>) {
      state.isLocaleSwitcherVisible = action.payload;
    },
    updateAppearanceSwitcherVisibility(state, action: PayloadAction<boolean>) {
      state.isAppearanceSwitcherVisible = action.payload;
    },
  },
});

export const {
  setProfile,
  updateLocaleSwitcherVisibility,
  updateAppearanceSwitcherVisibility,
} = profileSlice.actions;
export default profileSlice.reducer;

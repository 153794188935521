import React from 'react';

import { Title, SubTitle } from './index.style';

interface IProps {
  title?: string;
  version?: string;
}

const AppVersion: React.FC<IProps> = ({ title, version }) => {
  return version ? (
    <>
      {title && <Title>{title}</Title>}
      <SubTitle>{`${version}`}</SubTitle>
    </>
  ) : (
    <></>
  );
};

export default AppVersion;

import _ from 'lodash';

import i18n from '@config/i18n';
import { ATTENDANCE_STATUS, ATTENDANCE_TYPES } from '@constants';
import { commonColor } from '@theme/colors';
import {
  Attendance,
  AttendanceEventType,
  Employee,
  EmployeeAttendance,
  Holiday,
  LeaveRule,
} from '@types';

import {
  AttendanceBadge,
  CustomDayMarking,
  CustomCalendarMarking,
} from '../types/attendance';
import { formatDate, getDatesBetweenDays, parseDate } from './dateTime';
import { differenceInBusinessDays } from 'date-fns';

const getAttendanceEventColor = (type: AttendanceEventType) => {
  switch (type) {
    case ATTENDANCE_TYPES.LEAVE.key:
      return commonColor.pinkDark;
    case ATTENDANCE_TYPES.OVERTIME.key:
      return commonColor.deepBlue;
    case ATTENDANCE_TYPES.WORK_FROM_HOME.key:
      return commonColor.green;
    case ATTENDANCE_TYPES.COMPENSATION_LEAVE.key:
      return commonColor.darkYellow;
    default:
      return commonColor.primary;
  }
};

const getAttendanceEventLabel = (attendance: Attendance) => {
  switch (attendance.eventType) {
    case ATTENDANCE_TYPES.LEAVE.key:
      return (
        attendance.leaveType ??
        i18n.t('app.attendance.leave.types.absent.title')
      );
    case ATTENDANCE_TYPES.OVERTIME.key:
      return i18n.t('app.attendance.overtime.title');
    case ATTENDANCE_TYPES.WORK_FROM_HOME.key:
      return i18n.t('app.attendance.work-from-home.title');
    case ATTENDANCE_TYPES.COMPENSATION_LEAVE.key:
      return i18n.t('app.attendance.compensation-leave.title');
    default:
      return i18n.t('app.attendance.title');
  }
};

const getAttendanceEventCode = (attendance: Attendance) => {
  switch (attendance.eventType) {
    case ATTENDANCE_TYPES.LEAVE.key:
      return (
        attendance.type.code ?? i18n.t('app.attendance.leave.types.absent.code')
      );
    case ATTENDANCE_TYPES.OVERTIME.key:
      return i18n.t('app.attendance.overtime.code');
    case ATTENDANCE_TYPES.WORK_FROM_HOME.key:
      return i18n.t('app.attendance.work-from-home.code');
    case ATTENDANCE_TYPES.COMPENSATION_LEAVE.key:
      return i18n.t('app.attendance.compensation-leave.code');
    default:
      return i18n.t('app.attendance.title');
  }
};

const getMonthlyAttendanceMarkings = (attendanceData: Attendance[]) => {
  const markedDates: CustomCalendarMarking = {};

  attendanceData.forEach(attendance => {
    const startDate = parseDate(attendance.start);
    const endDate = parseDate(attendance.end);
    const daysInBetween = getDatesBetweenDays(
      startDate,
      endDate,
      'yyyy-MM-dd',
    ) as string[];

    daysInBetween.forEach(day => {
      const eventMarking: CustomDayMarking = markedDates[day] ?? {};
      let existingMarkings = markedDates[day];
      let badges: AttendanceBadge[] = [];
      let attendances: Attendance[] = [];

      if (existingMarkings) {
        if (existingMarkings.badges) {
          badges = existingMarkings.badges;
        }
        if (existingMarkings.attendances) {
          attendances = existingMarkings.attendances;
        }
      }

      const duplicateBadge = badges.filter(
        badge => badge.attendanceId === attendance.id,
      );

      if (attendance.eventType === ATTENDANCE_TYPES.LEAVE.key) {
        if (attendance.status === ATTENDANCE_STATUS.PROPOSED) {
          eventMarking.solidColor = getAttendanceEventColor(
            attendance.eventType,
          );
        }

        if (attendance.status === ATTENDANCE_STATUS.APPROVED) {
          eventMarking.backgroundColor = commonColor.pinkLight;
        }

        if (duplicateBadge.length === 0) {
          badges.push({
            attendanceId: attendance.id,
            type: attendance.eventType,
            status: attendance.status,
            code: getAttendanceEventCode(attendance),
            color: getAttendanceEventColor(attendance.eventType),
          });
        }
      }

      if (attendance.eventType === ATTENDANCE_TYPES.COMPENSATION_LEAVE.key) {
        if (attendance.status === ATTENDANCE_STATUS.PROPOSED) {
          eventMarking.solidColor = getAttendanceEventColor(
            attendance.eventType,
          );
        }

        if (attendance.status === ATTENDANCE_STATUS.APPROVED) {
          eventMarking.backgroundColor = commonColor.lightYellow2;
        }

        if (duplicateBadge.length === 0) {
          badges.push({
            attendanceId: attendance.id,
            type: attendance.eventType,
            status: attendance.status,
            code: getAttendanceEventCode(attendance),
            color: getAttendanceEventColor(attendance.eventType),
          });
        }
      }

      if (attendance.eventType === ATTENDANCE_TYPES.WORK_FROM_HOME.key) {
        if (attendance.status === ATTENDANCE_STATUS.PROPOSED) {
          eventMarking.solidColor = commonColor.lightGreen;
        }

        if (attendance.status === ATTENDANCE_STATUS.APPROVED) {
          eventMarking.backgroundColor = commonColor.lightGreen;
        }

        if (attendance.status !== ATTENDANCE_STATUS.APPROVED) {
          if (duplicateBadge.length === 0) {
            badges.push({
              attendanceId: attendance.id,
              type: attendance.eventType,
              status: attendance.status,
              code: getAttendanceEventCode(attendance),
              color: getAttendanceEventColor(attendance.eventType),
            });
          }
        }
      }

      if (attendance.eventType === ATTENDANCE_TYPES.OVERTIME.key) {
        if (!eventMarking.backgroundColor) {
          if (attendance.status === ATTENDANCE_STATUS.APPROVED) {
            eventMarking.backgroundColor = commonColor.lightBlue;
          }

          eventMarking.solidColor = getAttendanceEventColor(
            attendance.eventType,
          );
        }

        if (duplicateBadge.length === 0) {
          badges.push({
            attendanceId: attendance.id,
            type: attendance.eventType,
            status: attendance.status,
            code: getAttendanceEventCode(attendance),
            color: getAttendanceEventColor(attendance.eventType),
          });
        }
      }

      attendances.push(attendance);
      eventMarking.badges = badges;
      eventMarking.attendances = attendances;
      eventMarking.status = attendance.status;
      markedDates[day] = eventMarking;
    });
  });

  return markedDates;
};

const getHolidayMarkings = (holidays: Holiday[]) => {
  const markings: { [key: string]: CustomDayMarking } = {};

  holidays.forEach(holiday => {
    const { start, end } = holiday;
    const startDate = parseDate(start);
    const endDate = parseDate(end);
    const daysInBetween = getDatesBetweenDays(startDate, endDate) as Date[];
    daysInBetween.forEach(day => {
      markings[formatDate(day, 'yyyy-MM-dd')] = {
        title: holiday.name,
        holiday: holiday,
      };
    });
  });

  return markings;
};

const getAttendanceStatusLabel = (status: number) => {
  switch (status) {
    case ATTENDANCE_STATUS.APPROVED:
      return i18n.t('app.attendance.status.approved');
    case ATTENDANCE_STATUS.REJECTED:
      return i18n.t('app.attendance.status.rejected');
    case ATTENDANCE_STATUS.PROPOSED:
      return i18n.t('app.attendance.status.pending-approval');
    default:
      return i18n.t('app.attendance.status.pending-approval');
  }
};

const getAttendanceStatusColor = (status?: number) => {
  switch (status) {
    case ATTENDANCE_STATUS.APPROVED:
      return commonColor.green;
    case ATTENDANCE_STATUS.REJECTED:
      return commonColor.darkRed;
    case ATTENDANCE_STATUS.PROPOSED:
      return commonColor.yellow;
    default:
      return commonColor.yellow;
  }
};

const getGeneralAttendanceMarkings = (
  employeesAttendances: EmployeeAttendance[],
) => {
  const attendanceMarkings: { [key: string]: CustomCalendarMarking } = {};

  employeesAttendances.forEach(employeesAttendance => {
    const markings = getMonthlyAttendanceMarkings(
      employeesAttendance.attendances,
    );

    attendanceMarkings[employeesAttendance.employeeId] = markings;
  });

  return attendanceMarkings;
};

const getEmployeeDetailsFromGeneralAttendance = (
  employeesAttendance: EmployeeAttendance[],
) => {
  const attendanceByEmployees = _.groupBy(employeesAttendance, 'employeeId');
  const employees: Employee[] = [];

  for (const [employeeId, employeeAttendances] of Object.entries(
    attendanceByEmployees,
  )) {
    employees.push({
      employeeId: employeeAttendances[0].employeeId,
      fullName: employeeAttendances[0].fullName,
      givenName: employeeAttendances[0].givenName,
      contracts: employeeAttendances[0].contracts,
    });
  }

  return _.orderBy(employees, 'givenName');
};

const getLeaveRule = ({
  start,
  end,
  requestDate,
  leaveTypeId,
  leaveRules,
}: {
  start: Date;
  end: Date;
  requestDate: Date;
  leaveTypeId: number | null;
  leaveRules?: LeaveRule[];
}) => {
  const days = differenceInBusinessDays(end, start) + 1;
  const noticeDays = differenceInBusinessDays(start, requestDate) + 1;

  const matchingRule = leaveRules?.find(
    rule =>
      rule.min <= days &&
      (rule.max === null || days <= rule.max) &&
      rule.leaveType.id === leaveTypeId &&
      rule.noticePeriod > noticeDays,
  );

  return matchingRule;
};

export {
  getAttendanceEventColor,
  getAttendanceEventLabel,
  getAttendanceEventCode,
  getHolidayMarkings,
  getAttendanceStatusLabel,
  getAttendanceStatusColor,
  getMonthlyAttendanceMarkings,
  getGeneralAttendanceMarkings,
  getEmployeeDetailsFromGeneralAttendance,
  getLeaveRule,
};

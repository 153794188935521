import { Dimensions, Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

export const Container = styled.ScrollView`
  padding: ${Platform.OS === 'web' ? '30px' : '20px'};
`;

export const HeadSection = styled.View`
  align-items: center;
`;

export const AvatarContainer = styled.View`
  height: 70px;
  width: 70px;
  background-color: ${props => props.theme.color.primary};
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;

export const AvatarCharacter = styled.Text`
  font-size: 30px;
  font-family: ${props => props.theme.fonts.MontserratBold};
  font-weight: 600;
  color: ${props => props.theme.color.alwaysWhite};
`;

export const FullName = styled.Text`
  font-family: ${props => props.theme.fonts.MontserratRegular};
  color: ${props => props.theme.color.text};
  font-size: 16px;
`;

export const Email = styled.Text`
  font-family: ${props => props.theme.fonts.MontserratRegular};
  font-size: 14px;
  color: ${props => props.theme.color.text};
`;

export const ProfileDetails = styled.View`
  margin: 12px 0 30px 0;

  ${Platform.OS === 'web' &&
  css`
    gap: 10px;
  `}
`;

export const ProfileSection = styled.View`
  width: 100%;
  margin: 8px 0;
`;

export const SectionTitle = styled.Text`
  font-family: ${props => props.theme.fonts.MontserratBold};
  color: ${props => props.theme.color.text};
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
  margin-left: 3px;
`;
